import React, { useEffect, useMemo, useState } from 'react';

import { Modal, Space } from 'antd';

import { Utils } from '../../utils';
import { columns } from './columns';
import { useParamsHistory } from '../../hooks';
import { RouteNames } from '../../router/names';
import { FormDeal } from '../rent/deals/components';
import ViewContacts from '../contacts/viewContacts';
import { ViewDeal } from '../sales/deals/components';
import { Rest_deal } from '../../services/rest_deal';
import { Rest_rent } from '../../services/rest_rent';
import { IContainers } from '../../types/containers';
import { mapperDealsRent } from '../rent/deals/utils';
import { Table } from '../../components/ui/Table/Table';
import { CREATE, EDIT, HISTORY, VIEW } from '../../helpers/string-helpers';
import { Rest_contacts } from '../../services/rest_contacts';
import { Rest_releases } from '../../services/rest_releases';
import { Rest_terminal } from '../../services/rest_terminal';
import { Rest_agreement } from '../../services/rest_agreement';
import { ViewRelease } from '../releases/components/ViewRelease';
import { Rest_containers } from '../../services/rest_containers';
import FormModalContainer from '../containers/formModalContainer';
import { Rest_contractors } from '../../services/rest_contractors';
import FormCardContractors from '../contractors/formCardContractors';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';
import FormCardModalTerminal from '../terminals/formCardModalTerminal';
import { PdfReleaseEN, PdfReleaseRU } from '../releases/components/pdf';
import { ViewAgreements } from '../agreements/components/ViewAgreements';
import {
  AGREEMENT,
  COMPANY,
  CONTACT,
  CONTAINER,
  FINANCE_BILLING,
  FINANCE_PAYMENT,
  PROFILE_USER,
  RELEASE,
  RENT_DEAL,
  RENT_REQUEST,
  SALES_DEAL,
  TERMINAL,
} from '../../constants';

import '../containers/style.scss';
import { mapperHistoryRelease } from '../releases/components/utils';
import CustomHistory from '../../components/CustomHistory';
import { historyConfigList } from '../releases/components/history';
import { defaultValues } from '../../store/releases';
import socket from '../../socket';
import { useStoreDispatch } from '../../store';
import { getActivityContainer } from '../../store/containers';
import useModalMessage from '../../hooks/useModalMessage';
import { useTranslation } from 'react-i18next';
import { Rest_User } from '../../services/rest_users';
import { TooltipPlacement } from 'antd/es/tooltip';
import { ViewProfile } from '../../components/Profile/ViewProfile';
import { mapperDealSales } from '../sales/deals/utils';

const data = Array.from<any>({ length: 15 }).map<any>((_, i) => ({
  key: i,
  id: '-'.repeat(20),
}));

interface IGetValue {
  key?: string;
  findValue: Promise<any>;
  mapper?: (value: any) => any;
  mapperRes?: (value: any) => any;
  setOpen: (bol: boolean) => void;
  setOption?: () => void;
}

export const ViewPage: React.FC = () => {
  const customMessage = useModalMessage(true);
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { history, location, id } = useParamsHistory();
  const { pathname, search } = location;
  const searchParams = new URLSearchParams(search);

  const [isOpen, setIsOpen] = useState(false);

  const [isModal, setIsModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [activityContainer, setActivityContainer] = useState<any>([]);

  const [dataComment, setDataComment] = useState<any>([]);

  const [initialValue, setInitialValue] = useState<any>(null);

  const [widthModal, setWidthModal] = useState<string | number>('80%');

  const [minWidth, setMinWidth] = useState<number | undefined>(600);

  const [isOpenModalPdf, setIsOpenModalPdf] = useState<boolean>(false);

  const [lang, setLang] = useState<'ru' | 'en'>('ru');

  const [typeForm, setTypeForm] = useState(CREATE);

  const [returnPath, setReturnPath] = useState(RouteNames.CRM_COMPANY);

  const [totalTableActivity, setTotalTableActivity] = useState<number>(0);

  const [placement, setPlacement] = useState<'right' | 'left'>('right');

  useEffect(() => {
    socket.on('res-update-new-booking', handleNewBooking);
    socket.on('res-update-repair', resUpdateRepair);
    socket.on('res-update-date-booking', handleDateBooking);

    return () => {
      socket.off('res-update-new-booking', handleNewBooking);
      socket.off('res-update-repair', resUpdateRepair);
      socket.off('res-update-date-booking', handleDateBooking);
    };
  }, []);

  useEffect(() => {
    const currentSales = searchParams.get('id');
    setReturnPath(RouteNames.CRM_COMPANY);
    switch (id) {
      case COMPANY:
        if (currentSales) {
          const id = Utils.decrypt(currentSales);
          getInitialValue({
            findValue: Rest_contractors.findCompanyById(id),
            setOpen: setIsOpen,
            setOption() {
              setMinWidth(700);
            },
          });
          setPlacement('right');
          setReturnPath(RouteNames.CRM_COMPANY);
          return;
        }
        onRedirect();
        return;
      case CONTACT:
        if (currentSales) {
          const id = Utils.decrypt(currentSales);
          getInitialValue({
            findValue: Rest_contacts.findContactById(id),
            setOpen: setIsOpen,
            setOption() {
              setMinWidth(700);
            },
          });
          setPlacement('right');
          setReturnPath(RouteNames.CRM_CONTACTS);
          return;
        }
        onRedirect();
        return;
      case AGREEMENT:
        if (currentSales) {
          const id = Utils.decrypt(currentSales);
          getInitialValue({
            findValue: Rest_agreement.findOneAgreement(id),
            setOpen: setIsOpen,
            setOption() {
              setMinWidth(700);
            },
          });
          setPlacement('right');
          setReturnPath(RouteNames.CRM_AGREEMENT);
          return;
        }
        onRedirect();
        return;
      case RELEASE:
        if (currentSales) {
          const id = Utils.decrypt(currentSales);
          getInitialValue({
            findValue: Rest_releases.findOneRelease(id),
            setOpen: setIsOpen,
            setOption() {
              setMinWidth(700);
            },
          });
          setPlacement('right');
          setReturnPath(RouteNames.RELEASES);
          return;
        }
        onRedirect();
        return;
      case SALES_DEAL:
        if (currentSales) {
          const id = Utils.decrypt(currentSales);
          getInitialValue({
            findValue: Rest_deal.findOneDeal(id),
            setOpen: setIsOpen,
            mapper: mapperDealSales
          });
          setPlacement('right');
          setReturnPath(RouteNames.SALES_DEAL);
          return;
        }
        onRedirect();
        return;
      case RENT_DEAL:
        if (currentSales) {
          const id = Utils.decrypt(currentSales);
          getInitialValue({
            findValue: Rest_rent.findOneDeal(id),
            mapper: mapperDealsRent,
            setOpen: setIsOpen,
            setOption() {
              setMinWidth(1100);
            },
          });
          setPlacement('right');
          setReturnPath(RouteNames.RENT_DEAL);
          return;
        }
        onRedirect();
        return;
      case CONTAINER:
        if (currentSales) {
          const id = Utils.decrypt(currentSales);
          const mapperContainer = (result: IContainers) => {
            const container = result?.container;
            const activity = result?.activity || [];
            const comments = result?.comments || [];
            container.activity = activity;
            container.comments = comments;
            setActivityContainer(activity);
            setDataComment(comments);
            return container;
          };
          getInitialValue({
            findValue: Rest_containers.findContainerOne(id),
            setOpen: setIsModal,
            setOption() {
              setWidthModal('80%');
            },
          });
          setReturnPath(RouteNames.CONTAINERS);
          return;
        }
        onRedirect();
        return;
      case TERMINAL:
        if (currentSales) {
          const id = Utils.decrypt(currentSales);
          getInitialValue({
            findValue: Rest_terminal.findTerminalOne(id),
            setOpen: setIsModal,
            setOption() {
              setWidthModal(548);
            },
          });
          setReturnPath(RouteNames.TERMINALS);
          return;
        }
        onRedirect();
        return;
      case PROFILE_USER:
        if (currentSales) {
          const id = Utils.decrypt(currentSales);
          getInitialValue({
            findValue: Rest_User.findUserById(id),
            setOpen: setIsOpen,
            setOption() {
              setMinWidth(1100);
            },
          });
          setPlacement('left');
          setReturnPath(RouteNames.CRM_COMPANY);
          return;
        }
        onRedirect();
        return;
      case RENT_REQUEST:
      case FINANCE_BILLING:
      case FINANCE_PAYMENT:
    }
    onRedirect();
  }, [pathname]);

  const handleDateBooking = (response: any) => {
    if (!response) return;
    setActivityContainer(response?.rows);
  };

  const resUpdateRepair = (response: any) => {
    if (!response) return;
    setActivityContainer(response.data.rows);
  };

  const handleNewBooking = (response: any) => {
    if (!response) return;
    dispatch(
      getActivityContainer({
        id: response[0].id,
        current: 1,
        page: 10,
      }),
    )
      .then((response) => {
        setTotalTableActivity(response.payload.count);
        setActivityContainer(response.payload.rows);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const getInitialValue = ({
    findValue,
    setOpen,
    key,
    mapper,
    mapperRes = (res) => res?.data,
    setOption,
  }: IGetValue) => {
    setLoading(true);
    findValue
      .then((res) => {
        const data = key ? mapperRes(res)?.[key] : mapperRes(res);
        const row = mapper ? mapper(data) : data;
        if (row) {
          setInitialValue(row);
          setTypeForm(VIEW);
          setOpen(true);
          if (setOption) {
            setOption();
          }
        } else {
          history.push(RouteNames.CRM_COMPANY);
        }
      })
      .catch((err) => {
        history.push(RouteNames.CRM_COMPANY);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onHistory = () => {
    if (initialValue?.id && !initialValue?.history?.length) {
      Rest_releases.getHistoryRelease(initialValue.id)
        .then((res) => {
          setInitialValue((prev: any) => ({
            ...prev,
            history: mapperHistoryRelease(res.data),
          }));
          setTypeForm(HISTORY);
        })
        .catch((err) => {
          customMessage.error([
            t('История недоступна.'),
            t('Попробуйте позже.'),
          ]);
        });
      return;
    }
    if (initialValue?.history?.length) {
      setInitialValue((prev: any) => ({
        ...prev,
        history: mapperHistoryRelease(initialValue.history),
      }));
      setTypeForm(HISTORY);
      return;
    }
    customMessage.error([t('История недоступна.')]);
  };

  const onEdit = (path: string, key: string, value?: any) => {
    history.push(path, { [key]: { [EDIT]: value ? value : initialValue } });
    onClear();
  };

  const onPdf = (lang: 'ru' | 'en') => {
    setLang(lang);
    setIsOpenModalPdf(true);
  };

  const openCreateDeal = ({ path, state }: { path: string; state: any }) => {
    history.push(path, state);
    onClear();
  };

  const onRedirect = () => {
    history.push(RouteNames.CRM_COMPANY);
  };

  const onClear = () => {
    setIsOpen(false);
    setIsModal(false);
    setMinWidth(600);
    setWidthModal('80%');
    setDataComment([]);
    setActivityContainer([]);
    setTypeForm(CREATE);
    setInitialValue(undefined);
  };

  const onClose = () => {
    history.replace(returnPath);
    onClear();
  };

  const getForm = useMemo(() => {
    if (!isOpen && !isModal) return null;
    switch (id) {
      case COMPANY:
        return (
          <FormCardContractors
            initialValue={initialValue}
            onClose={onClose}
            onEdit={() => {
              onEdit(RouteNames.CRM_COMPANY, COMPANY);
            }}
          />
        );
      case CONTACT:
        return (
          <ViewContacts
            dataContacts={initialValue}
            onEdit={() => onEdit(RouteNames.CRM_CONTACTS, CONTACT)}
            onClose={onClose}
          />
        );
      case AGREEMENT:
        return (
          <ViewAgreements
            typeForm={VIEW}
            isOpen={isOpen}
            initialValue={initialValue}
            onClose={onClose}
            onEdit={() => onEdit(RouteNames.CRM_AGREEMENT, AGREEMENT)}
          />
        );
      case RELEASE:
        return typeForm === HISTORY ? (
          <CustomHistory
            initialValue={initialValue?.history}
            defaultData={[defaultValues]}
            configList={historyConfigList}
            onReturn={() => setTypeForm(VIEW)}
          />
        ) : (
          <ViewRelease
            initialValue={initialValue}
            typeForm={typeForm}
            isOpen={isOpen}
            onPdf={onPdf}
            onClose={onClose}
            onDeal={openCreateDeal}
            onEdit={() => onEdit(RouteNames.RELEASES, RELEASE)}
            onHistory={onHistory}
          />
        );

      case TERMINAL:
        return (
          <FormCardModalTerminal
            dataRow={initialValue}
            onClose={onClose}
            onEdit={() => onEdit(RouteNames.TERMINALS, TERMINAL)}
          />
        );
      case SALES_DEAL:
        return (
          <ViewDeal
            typeForm={typeForm}
            isOpen={isOpen}
            initialValue={initialValue}
            onClose={onClose}
            onEdit={() => onEdit(RouteNames.SALES_DEAL, SALES_DEAL)}
          />
        );
      case RENT_DEAL:
        return (
          <FormDeal
            title={'Просмотр сделки'}
            initialValue={initialValue}
            onClose={onClose}
            onEdit={() => onEdit(RouteNames.RENT_DEAL, RENT_DEAL)}
            isOpen={isOpen}
            disabled
          />
        );
      case PROFILE_USER:
        return (
          <ViewProfile
            isOpen={isOpen}
            typeForm={typeForm}
            initialValue={initialValue}
            title={initialValue?.fio}
            onClose={onClose}
          />
        );
      case CONTAINER:
        return (
          <FormModalContainer
            totalTableActivity={totalTableActivity}
            currentActivity={1}
            pageActivity={10}
            activityContainer={activityContainer}
            dataComment={dataComment}
            setActivityContainer={setActivityContainer}
            dataRowContainer={initialValue}
            closeModalContainers={onClose}
            isDisabled={
              !!initialValue?.deal ||
              !!initialValue?.release ||
              !!initialValue?.delete ||
              !!initialValue?.active
            }
            onEdit={() =>
              onEdit(RouteNames.CONTAINERS, CONTAINER, {
                container: initialValue,
                activity: activityContainer,
                comment: dataComment,
              })
            }
          />
        );
      default:
        return null;
    }
  }, [id, isOpen, isModal, typeForm]);

  return (
    <>
      <Space
        direction="vertical"
        size="large"
        style={{ width: '100%', filter: 'blur(3px) opacity(0.8)' }}
      >
        <Table
          dataFilters={[]}
          clearFiltersFetch={console.log}
          filtersFetch={console.log}
          setColumns={console.log}
          selectedRows={new Set()}
          setSelectedRows={console.log}
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={{
            pageSize: 1,
            total: 1,
            current: 1,
            showSizeChanger: true,
          }}
        />
      </Space>
      <CustomDrawer
        open={isOpen}
        onClose={onClose}
        isHeight
        minWidth={minWidth}
        placement={placement}
      >
        {getForm}
      </CustomDrawer>
      <Modal
        className="page-container"
        closable={false}
        footer={null}
        width={widthModal}
        open={isModal}
      >
        {getForm}
      </Modal>
      <Modal
        open={isOpenModalPdf}
        closeIcon
        centered
        width={'60vw'}
        footer={null}
        onCancel={() => setIsOpenModalPdf(false)}
      >
        <div style={{ scale: '0.95' }}>
          {lang === 'en' ? (
            <PdfReleaseEN data={initialValue} />
          ) : (
            <PdfReleaseRU data={initialValue} />
          )}
        </div>
      </Modal>
    </>
  );
};
