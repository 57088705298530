import { FC, useEffect, useRef, useState } from 'react';
import { Flex, Skeleton } from 'antd';
import style from './style.module.scss';
import { IProfile, IUsers } from '../../types/users';
import Profile from '../Profile';
import { useSelector } from 'react-redux';
import { RootState, useStoreDispatch } from '../../store';
import { IGetUsers, Rest_User } from '../../services/rest_users';
import ScrollList from '../ui/ScrollList';
import { setUser, setUserActive, setUsersData } from '../../store/users';
import TooltipButton from '../ui/TooltipButton/TooltipButton';
import { DownOutlined } from '@ant-design/icons';
import socket from '../../socket';
import { CURRENT_USER, Utils } from '../../utils';


const UsersPanel: FC = () => {
  const usersData: IProfile[] = useSelector(
    (state: RootState) => state.users.usersData,
  );
  const profile: IUsers | undefined = useSelector(
    (state: RootState) => state.users.user,
  );

  const dispatch = useStoreDispatch();

  const refUsers = useRef(null);

  const [total, setTotal] = useState(-1);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUsers({ pageSize: 10, current: 0 });
    socket.on('return-update-user', (payload) => {
      if (payload?.id === Utils.getUserInfo()?.id) {
        Utils.setStorageCrypt(CURRENT_USER, payload);
        dispatch(setUser(payload));
      }
      if (payload?.id && usersData?.length) {

        const result = usersData?.map((item) => {
          if (item.id === payload.id) {
            return {...payload, active: payload?.id === Utils.getUserInfo()?.id};
          } return item;
        })
        dispatch(setUsersData(result));
      }
    });
    socket.on('return-users-active', (item) => {
      if (usersData?.length) {
        const result = usersData.map((usr) => {
          if (usr?.id === item?.id) {
            return {...usr, active: item.active}
          } return usr;
        })
        dispatch(setUsersData(result));
      }
    })
    return () => {
      socket.off('return-update-user');
      socket.off('return-users-active');
      socket.off('set-users-active');
    }
  }, []);

  const getUsers = ({ pageSize, current }: IGetUsers) => {
    setLoading(true);
    Rest_User.getUsers({ pageSize, current })
      .then((res) => {
        const count = res.data?.count;
        const rows = res.data?.rows || [];
        const result = usersData?.map((el) => {
          const index = rows?.findIndex((row: any) => row?.id === el?.id);
          if (index >= 0) {
            const result = {...el, ...rows[index]};
            rows[index] = null;
            return result;
          } return el;
        });
        result.push(...rows?.filter((el: any) => !!el));
        dispatch(setUsersData(result));
        setTotal(count);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
        if (profile?.id) {
          dispatch(setUserActive({id: profile.id, active: true}))
        }
      });
  };

  const checkLastItemList = () => {
    if (usersData?.length < total && !loading) {
      getUsers({ pageSize: 10, current: usersData?.length });
    }
  };

  return (
    <Flex vertical gap={10} align="center" className={style['container']}>
      <ScrollList
        className={style['container__scroll']}
        totalTableActivity={total}
        data={loading ? [] : usersData}
        tableRef={refUsers}
        paginationActivity={() => {}}
        isTable={false}
        endMessage={null}
      >
        {loading ? (
          Array(10)
            .fill('')
            .map((_, i) => (
              <Skeleton
                className={style['container__skeleton']}
                key={i}
                avatar
                active
              />
            ))
        ) : usersData?.length ? (
          usersData.map((user, i) => (
            <Profile
              className={style['container__profile']}
              key={i}
              placement="left"
              user={user}
              isDrawer
              isShowActive
              isActive={user?.isActive}
            />
          ))
        ) : (
          <Profile
            className={style['container__profile']}
            user={profile}
            placement="leftBottom"
            isDrawer
            isActive
            isShowActive
          />
        )}
      </ScrollList>
      <TooltipButton
        propsButton={{
          hidden: usersData?.length >= total,
          type: 'default',
          onClick: checkLastItemList,
        }}
        propsTooltip={{ title: 'Показать еще', placement: 'left' }}
      >
        <DownOutlined />
      </TooltipButton>
    </Flex>
  );
};

export default UsersPanel;
