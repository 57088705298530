import * as XLSX from "xlsx";
import React from "react";
import {Utils} from "../../utils";
import { getDateString } from "../../helpers/date-helpers";

export let fileColumns: any[] = [];

interface CrmColumn {
    title: string;
    dataIndex: string;
    key: string;
}

interface ColumnMapping {
    crmColumn: CrmColumn;
    fileColumn: string;
}

interface MappedData {
    num_container?: string;
    type_container?: string;
    date_cvv?: null;
    date_memory?: null;
    terminal_id?: string | number;
    responsible_id?: string | number;
    comment?: string | null;
    [key: string]: string | number | undefined | null;
}

interface ISearchColumns{
    inputValue: string
    searchColumn: any[]
    setActiveButtons: (value: any) => void
    setSelectedOption: (value: null) => void
    setSearchColumn: (value: any) => void
}

export class TerminalService {
    static handleFile({event, limit}: {event: React.ChangeEvent<HTMLInputElement>, limit?: number}): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const fileObj: File | null = event.target.files && event.target.files[0];
            if (!fileObj) {
                return;
            }
            if (event.target.files === null || event.target.files?.length === 0) return;
            const file: File = event.target.files[0];
            const data: ArrayBuffer = await file.arrayBuffer();
            const workbook: XLSX.WorkBook = XLSX.read(data, {
                type: 'binary',
                cellDates: true,
                cellNF: false,
                cellText: false,
            });
            const worksheet: XLSX.WorkSheet = workbook.Sheets[workbook.SheetNames[0]];
            const arFile: any[] = XLSX.utils.sheet_to_json(worksheet, {
                header: 1, defval: "", raw: false
            });
            const result = arFile.map((elem, index) => {
                const trimmedElem = elem.map((item: string) => (typeof item === 'string') ? item.trim() : item);
                if (index === 0) {
                    trimmedElem.unshift('Пустое поле');
                } else {
                    trimmedElem.unshift('')
                }
                return trimmedElem;
            });
            fileColumns = result;
            if (limit) {
                fileColumns = result?.slice(0, limit)
                resolve(fileColumns)
            } else {
                resolve(fileColumns)
            }
        })
    }

    static onSave(columnsMapping: ColumnMapping[], fileNameTerminal:  string | undefined | number): Promise<MappedData[]> {
        return new Promise((resolve, reject) => {
            try {
                const arResult: MappedData[] = [];
                const headerRow = fileColumns[0];
                const dataRows = fileColumns.slice(1);
                dataRows.forEach(row => {
                    const rowData: MappedData = {
                        date_cvv: null,
                        date_memory: null,
                        terminal_id: fileNameTerminal,
                        responsible_id: Utils.getUserInfo().id,
                    };

                    columnsMapping.forEach(column => {
                        const { crmColumn, fileColumn } = column;
                        const columnIndex = headerRow.indexOf(fileColumn);

                        if (columnIndex !== -1) {
                            rowData[crmColumn.dataIndex?.trim()] = row[columnIndex] || null;
                        }
                    });
                    arResult.push(rowData);
                });
                resolve(arResult);
            } catch (error) {
                reject(error);
            }
        });
    }

    static validDataImportTerminal = (mapping: any): Promise<any[]> => {
        return new Promise(async (resolve, reject) => {
            mapping.forEach((item: any) => {
                if (item.crmColumn.title === 'Терминал') {
                    const indexTerminal = fileColumns[0].indexOf(item.fileColumn);
                    const resTerminals = new Set(fileColumns.map((item, index) => {
                        if (index > 0) {
                            if (item[indexTerminal]?.trim()) {
                                return {name: item[indexTerminal], index: index};
                            }
                        } return undefined
                    }).filter(row => row !== undefined));
                    resolve(Array.from(resTerminals));
                }
            });
        })
    }

    static async validDataImportNumContainers(mapping: any): Promise<any[]> {
        try {
            const results = mapping.map((item: any) => {
                if (item.crmColumn.title === 'Номер контейнера') {
                    const indexTerminal = fileColumns[0].indexOf(item.fileColumn);
                    return fileColumns.map((item, index) => {
                        if (index > 0) {
                            const isValid: boolean = Utils.isValidContainerNumber(item[indexTerminal]);
                            if (!isValid) {
                                return {num: item[indexTerminal],index: index};
                            }
                        } return undefined
                    }).filter(row => row !== undefined);
                } return undefined;
            });
            return results.flat().filter((item: any) => item !== undefined);
        } catch (error) {
            console.error('Error validating container numbers:', error);
            throw error;
        }
    }

    static validDataImportCompany = (mapping: any): Promise<any[]> => {
        return new Promise(async (resolve, reject) => {
            mapping.forEach((item: any) => {
                if (item.crmColumn.title === 'Клиент' || item.crmColumn.title === 'Собственник') {
                    const indexCompany = fileColumns[0].indexOf(item.fileColumn);
                    const resCompany = new Set(fileColumns.map((item, index) => {
                        if (index > 0 && item[indexCompany]?.trim()) {
                            return {name: item[indexCompany], index: index};
                        } return undefined;
                    }).filter(row => row !== undefined));
                    resolve(Array.from(resCompany));
                }
            });
        })
    }

    static validDataImportDate = (mapping: any): Promise<any[]> => {
        return new Promise(async (resolve, reject) => {
            let indexNumTerminal = '';
            mapping.forEach((item: any) => {
                if (item.crmColumn.title === 'Номер контейнера') {
                    indexNumTerminal = fileColumns[0].indexOf(item.fileColumn);
                }
                if (item.crmColumn.type === 'date') {
                    const indexDate = fileColumns[0].indexOf(item.fileColumn);
                    const resDate = new Set(fileColumns.map((item, index) => {
                        if (index > 0) {
                            const isValid: boolean = !!getDateString({date: item[indexDate]});
                            if (!isValid && item[indexDate]) {
                                return {date: item[indexDate], index: index,num: item[indexNumTerminal]};
                            }
                        } return undefined;
                    }).filter(row => row !== undefined));
                    resolve(Array.from(resDate));
                }
            });
        })
    }

    static initialMapping = (crmColumns: any[]) => {
        return new Promise(async (resolve, reject) => {
            try {
                const checkList: string[] = fileColumns?.[0]?.map((el: any) => el?.toLowerCase());

                const initialMapping = crmColumns.map((crmColumn: any) => {
                    if (typeof crmColumn.title !== 'undefined') {
                        return {
                            crmColumn,
                            fileColumn: fileColumns[0].find((fileColumn: string) =>
                                fileColumn.toLowerCase() === crmColumn?.title?.toLowerCase()) || null,
                            disabled: checkList.includes(crmColumn?.title?.toLowerCase())
                        };
                    }
                    // Возвращаем null или какое-то значение по умолчанию, если условие не выполнено
                    return null;
                }).filter(item => item !== null); // Удаляем null-значения из массива

                resolve(initialMapping);
            } catch (e) {
                console.log(e);
                reject(e); // Рекомендуется отклонить промис при ошибке
            }
        });
    }

}
