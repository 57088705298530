import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import socket from "../../socket";
import { Divider, Flex, Form, Space, Typography, Button } from "antd";
import { useStoreDispatch } from "../../store";
import {
  deleteIds,
  saveContractors,
  updateContractors,
} from "../../store/contractors";
import { columns } from "./columns";
import { Utils } from "../../utils";
import { typeSite } from "../../store/types";
import {
  getCustomsServices,
  getGeographyPresence,
  getRentKTK,
  getSaleKTK,
} from "../../store/store";
import FormEditContractors from "./formEditContractors";
import { Table } from "../../components/ui/Table/Table";
import FormCardContractors from "./formCardContractors";
import { useParamsHistory } from "../../hooks";
import { RouteNames } from "../../router/names";
import { useTranslation } from "react-i18next";
import CustomDrawer from "../../components/CustomDrawer/CustomDrawer";
import { IContractors } from "../../types/contractors";
import { COMPANY } from "../../constants";
import { Rest_contractors } from "../../services/rest_contractors";
import { defaultValueContractor } from "./constants";
import { useGettingDataForTables } from "../../hooks/useGettingDataForTables/useGettingDataForTables";
import { getUserPermissions } from "../../store/users";
import { setUserPermissions } from "../../store/users";
import { RootState } from "../../store";

const { Title } = Typography;

interface IContractorPage {
  title: string;
  activeKey?: string;
}

const Contractors: React.FC<IContractorPage> = ({ title, activeKey }) => {
  const { t } = useTranslation();

  const dispatch = useStoreDispatch();

  const { location, history } = useParamsHistory();

  const { pathname } = location;

  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);

  const [dataContractor, setDataContractor] = useState<any>({});

  const [currentUser, setCurrentUser] = useState(Utils.getUserInfo().id);
  const [rightsContractorsView, setRightsContractorsView] = useState(false);
  const [rightsContractorsEdit, setRightsContractorsEdit] = useState(false);
  const [rightsContractorsDelete, setRightsContractorsDelete] = useState(false);
  const permissionsData: any = useSelector(
    (state: RootState) => state.users.permissionsData
  );

  const { dataTable, optionsTable, updateEffectDate, setLoadingSubmit } =
    useGettingDataForTables({
      titlePage: "компании",
      keyItemName: "name_company",
      subscribeStringCreateForm: "return-create-contractors",
      subscribeStringUpdateForm: "return-update-contractors",
      subscribeStringDelete: "return-delete-contractors",
      subscribeError: "error",
      table: COMPANY,
      location,
      columns,
      fetchTables: Rest_contractors.getContractors,
      fetchDelete: (arg: any) => dispatch(deleteIds(arg)),
    });

  useEffect(() => {
    const fetchPermissions = () => {
      dispatch(getUserPermissions({ userId: currentUser, entityId: [1, 2, 3] }))
        .unwrap()
        .then((data) => {
          setUserPermissions(data);
          console.log("Дата из getUserPermissions в ContractorsCRM =>", data);
          setRightsContractorsView(
            (prev) =>
              data["contractors.status_view"] === true ||
              data["contractors.group_status_view"] === true
          );
          setRightsContractorsEdit(
            (prev) =>
              data["contractors.status_edit"] === true ||
              data["contractors.group_status_edit"] === true
          );
          setRightsContractorsDelete(
            (prev) =>
              data["contractors.status_delete"] === true ||
              data["contractors.group_status_delete"] === true
          );
        })
        .catch((err) => console.log(err));
    };

    fetchPermissions();

    socket.on("res-update-group-entities", (data) => {
      dispatch(setUserPermissions(data));
    });
    socket.on("res-update-entities", (data) => {
      dispatch(setUserPermissions(data));
    });

    return () => {
      socket.off("res-update-group-entities");
      socket.off("res-update-entities");
    };
  }, [dispatch, currentUser, permissionsData, rightsContractorsView]);

  useEffect(() => {
    console.log("rightsContractorsView", rightsContractorsView);
    console.log("rightsContractorsEdit", rightsContractorsEdit);
    console.log("rightsContractorsDelete", rightsContractorsDelete);
  });

  useEffect(() => {
    if (activeKey !== COMPANY) return;

    if (pathname === RouteNames.CRM_COMPANY) {
      updateEffectDate();
      const row = location?.state?.[COMPANY];
      if (row) {
        window.history.replaceState({ state: null }, document.title);
        if (row?.edit) {
          setDataContractor(row.edit);
          setOpenEdit(true);
          setOpen(true);
          return;
        }
        form.resetFields();
        setDataContractor(defaultValueContractor);
        setOpen(true);
        setOpenEdit(true);
        Utils.removeStorage("vd");
        return;
      }
      return;
    }
    return history.replace(RouteNames.CRM_COMPANY);
  }, [pathname, activeKey]);

  const onClose = () => {
    setOpen(false);
    if (openEdit) {
      setOpenEdit(false);
      form.resetFields();
    }
  };

  useEffect(() => {
    if (activeKey !== COMPANY) return;
    dispatch(typeSite());
    dispatch(getRentKTK());
    dispatch(getSaleKTK());
    dispatch(getCustomsServices());
    dispatch(getGeographyPresence());
  }, []);

  const onCreate = () => {
    form.resetFields();
    setDataContractor(defaultValueContractor);
    setOpen(true);
    setOpenEdit(true);
    Utils.removeStorage("vd");
  };

  const onFinish = (data: IContractors) => {
    data.short_address = data?.requisites?.requisites?.length
      ? data?.requisites?.requisites?.[10]?.meaning || null
      : null;
    data.contractors_id = data?.id;

    setDataContractor(defaultValueContractor);
    setOpen(false);
    setOpenEdit(false);
    if (!data?.id) {
      dispatch(saveContractors(data));
    } else {
      dispatch(updateContractors(data));
    }
    Utils.removeStorage("vd");
    setLoadingSubmit(true);
  };

  const onRow = (row: IContractors) => {
    setDataContractor(row);
    setOpenEdit(false);
    setOpen(true);
  };

  const handleShowEditForm = (row: IContractors) => {
    setDataContractor(row);
    setOpenEdit(true);
    setOpen(true);
  };

  return (
    <>
      <Flex
        justify={"space-between"}
        align={"flex-start"}
        style={{ margin: "20px 28px 0px" }}
      >
        <Title level={2}>{t(title)}</Title>
        <Flex gap={16}>
          <Button onClick={onCreate} type={"primary"}>
            {t("Создать")}
          </Button>
        </Flex>
      </Flex>
      <Divider style={{ margin: 0 }} />
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Table
          {...optionsTable}
          dataSource={dataTable}
          editRow={handleShowEditForm}
          height={"calc(100vh - 309px)"}
          style={{ overflow: "auto hidden" }}
          onRow={onRow}
          onClickLink={onRow}
          canView={rightsContractorsView}
          canEdit={rightsContractorsEdit}
          canDelete={rightsContractorsDelete}
        />
      </Space>

      <CustomDrawer open={open} onClose={onClose}>
        {openEdit ? (
          <FormEditContractors
            isOpen={openEdit}
            isCopy
            initialValue={dataContractor}
            form={form}
            onFinishEdit={onFinish}
            onCloseEdit={onClose}
            title={
              dataContractor?.id
                ? "Редактировать компанию"
                : t("Новая компания")
            }
            onEdit={() => {
              setOpenEdit(false);
            }}
          />
        ) : (
          <FormCardContractors
            isCopy
            onClose={onClose}
            onEdit={() => {
              setOpenEdit(true);
            }}
            initialValue={dataContractor}
          />
        )}
      </CustomDrawer>
    </>
  );
};

export default Contractors;
