import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import socket from "../../socket";

import { useTranslation } from "react-i18next";
import { Button, Flex, Typography } from "antd";

import { pathsCrm } from "./constants";
import style from "./style.module.scss";
import { useStoreDispatch } from "../../store";
import { RootState } from "../../store";
import { CREATE } from "../../helpers/string-helpers";
import { TableAgreements } from "./components/TableAgreements";
import {
  defaultAgreement,
  setIsOpenDrawer,
  setOneAgreement,
  setTypeForm,
} from "../../store/agreement";

import { getUserPermissions } from "../../store/users";
import { setUserPermissions } from "../../store/users";
import { Utils } from "../../utils";

interface IAgreementsCRM {
  title: string;
  activeKey: string;
}

export const AgreementsCRM: React.FC<IAgreementsCRM> = ({
  activeKey,
  title,
}) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const [currentUser, setCurrentUser] = useState(Utils.getUserInfo().id);
  const [rightsAgreementsView, setRightsAgreementsView] = useState(false);
  const [rightsAgreementsEdit, setRightsAgreementsEdit] = useState(false);
  const [rightsAgreementsDelete, setRightsAgreementsDelete] = useState(false);
  const permissionsData: any = useSelector(
    (state: RootState) => state.users.permissionsData
  );

  const onCreate = () => {
    dispatch(setOneAgreement(defaultAgreement));
    dispatch(setTypeForm(CREATE));
    dispatch(setIsOpenDrawer(true));
  };

  useEffect(() => {
    const fetchPermissions = () => {
      dispatch(getUserPermissions({ userId: currentUser, entityId: [1, 2, 3] }))
        .unwrap()
        .then((data) => {
          setUserPermissions(data);
          console.log("Дата из getUserPermissions в AgreementsCRM =>", data);
          setRightsAgreementsView(
            (prev) =>
              data["agreements.status_view"] === true ||
              data["agreements.group_status_view"] === true
          );
          setRightsAgreementsEdit(
            (prev) =>
              data["agreements.status_edit"] === true ||
              data["agreements.group_status_edit"] === true
          );
          setRightsAgreementsDelete(
            (prev) =>
              data["agreements.status_delete"] === true ||
              data["agreements.group_status_delete"] === true
          );
        })
        .catch((err) => console.log(err));
    };

    fetchPermissions();

    socket.on("res-update-group-entities", (data) => {
      dispatch(setUserPermissions(data));
    });
    socket.on("res-update-entities", (data) => {
      dispatch(setUserPermissions(data));
    });

    return () => {
      socket.off("res-update-group-entities");
      socket.off("res-update-entities");
    };
  }, [dispatch, currentUser, permissionsData, rightsAgreementsView]);

  useEffect(() => {
    console.log("rightsAgreementsView", rightsAgreementsView);
    console.log("rightsAgreementsEdit", rightsAgreementsEdit);
    console.log("rightsAgreementsDelete", rightsAgreementsDelete);
  });

  return (
    <div className={style.container}>
      <Flex
        justify={"space-between"}
        align={"flex-start"}
        style={{ margin: "20px 28px 0px" }}
      >
        <Typography.Title style={{ textTransform: "capitalize" }} level={2}>
          {t(title)}
        </Typography.Title>
        <Flex gap={16}>
          <Button onClick={onCreate} type={"primary"}>
            {t("Создать")}
          </Button>
        </Flex>
      </Flex>
      <TableAgreements
        paths={pathsCrm}
        activeKey={activeKey}
        canView={rightsAgreementsView}
        canEdit={rightsAgreementsEdit}
        canDelete={rightsAgreementsDelete}
      />
    </div>
  );
};
