import { Dayjs } from "dayjs";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAgreement } from "./agreement";
import { Rest_releases } from "../services/rest_releases";
import { Rest_rent } from "../services/rest_rent";

export interface ITypeFindAgreements {
  client_id: number;
  executor_id: number;
}
export interface ITabView {
  deal: IDeal;
  isOpen: boolean;
}
export interface IValues {
  [key: string]: any;
}

export interface ILocation {
  location?: string | null;
  options: any[];
  bet?: string;
}
export interface IReleaseList {
  id?: number | string | null;
  release_id?: string | null;
  options: any[];
  ids: number[];
  saveFiles?: any[];
}
export interface IExpenses {
  title?: string | null; // название расхода
  bet?: string | null; // ставка
  payment?: string | null; //оплата поставщику
  agreed?: boolean; // согласование
}

export interface IFormDeal {
  onEdit?: () => void;
  onView?: () => void;
  onRelease?: () => void;
  onClose?: (isClear?: boolean) => void;
  isOpen: boolean;
  isCopy?: boolean;
  isDisabled?: boolean;
  width?: string;
  title?: string;
  initialValue: IDeal;
  disabled?: boolean;
  isFromRequest?: boolean;
}

export interface ITabForm {
  initialValue: IDeal;
  isDisabled?: boolean;
  isOpen?: boolean;
  onOpenFormDeal?: () => void;
  setChangeSaveFiles?: (fuc: any) => void;
  isFromRequest?: boolean;
}

export interface IContainer {
  id?: number;
  num_container?: string;
  container_id?: number;
  status?: string | null;
  status_id?: number | null;
  issue_date?: string | null;
  departure_date?: Dayjs | null;
  delivery_request_date?: Dayjs | null;
  delivery_date?: Dayjs | null;
  count_day_snp?: string | null;
  total_snp?: string | null;
  bet?: number | string | null;
  payment?: string;
  gtd?: boolean;
  price?: number | string | null;
  type_container?: string | number;
  deal?: {
    id: number;
    id_deal: string;
    status: string;
    status_id: number;
    issue_date?: string | null;
    departure_date?: string | null;
    bet?: number | string | null;
    payment?: string;
    gtd?: boolean;
    price?: number | string | null;
  } | null;
  rent?: {
    payment?: string;
    bet?: number | string | null;
    departure_date?: Dayjs | null;
    delivery_request_date?: Dayjs | null;
    delivery_date?: Dayjs | null;
    count_day_snp?: string | null;
    total_snp?: string | null;
  } | null;
  release?: { id: number; release_id: string } | null;
  delete?: boolean;
  edit?: boolean;
  checked?: boolean;
}

interface IResponsible {
  avatar: string;
  name: string;
  id: number;
  isResponsible: boolean;
}

export interface IComments {
  comment: string;
  id_deal: string;
  user_id: number;
  user?: { avatar: string; fio: string; id: number; name: string };
  edit?: boolean;
  createdAt?: string;
}
export interface IDeal {
  id?: number;
  rent_deal_sales?: { id_deal: string };
  id_deal?: string;
  type_deal?: string;
  type_deal_id: number;
  type_deal_char: string;
  company?: string;
  company_id?: number;
  companies?: any;
  containers_ids: number[];
  phone?: string;
  my_company?: string;
  my_company_id?: number;
  my_companies?: any;
  agreement_id?: number;
  agreement_ids?: any;
  agreement?: any;
  contact_id?: number;
  contact?: any;
  releases?: IReleaseList[];
  location_release?: string | null;
  location_return?: ILocation[];
  related_accounts?: any[];
  related_deals?: any[];
  related_deals_ids?: any[];
  containers: IContainer[];
  bet?: string;
  currency_id?: number;
  type_container?: string;
  condition?: string;
  currency?: string;
  count_ktk?: string;
  total_ktk?: string;
  balance_ktk?: string;
  nds?: string;
  fine?: string;
  message_subject?: string;
  additionally?: string;
  count_day?: string;
  snp?: string;
  expenses?: IExpenses[];
  responsible_id?: number | null;
  responsible_name?: string | null;

  delete?: boolean;
  edit?: boolean;
}
interface IDealState {
  loadingOneDeal: boolean;
  loadingOneRequest: boolean;
  locations: any[];
  idDeal: string;
  isEditDeal: boolean;
  containerDealStatuses: { id: number; value: string }[];
  dealType: { id: number; value: string; char: string }[];
  isEditDealContainers: boolean;
  responsible: IResponsible[];
  docs: number[];
  dealData: IDeal[];
  dealOneData: IDeal | {};
  containers: IContainer[];
  comments: IComments[];
  agreements: IAgreement[];
  rentRequestData: IRequest[];
  requestOneData: IRequest | {};
  isEditRequest: boolean;
  saveFiles: any[];
}

export const rentTypeId = 2; // id Аренды
export const rentTypeDefaultDealId = 5; // id сделки Аренды

export const defaultRelease = {
  id: null,
  release_id: null,
  ids: [],
  saveFiles: [],
  options: [],
};
export const defaultLocationReturn = { location: null, options: [] };
export const defaultExpenses = {
  title: "",
  payment: "",
  bet: "",
  agreed: false,
};

export const defaultValuesDeal: IDeal = {
  id: undefined,
  id_deal: "",
  type_deal: "Аренда",
  type_deal_id: rentTypeDefaultDealId,
  type_deal_char: "АА",
  phone: "",
  my_company: "",
  my_company_id: undefined,
  company: "",
  company_id: undefined,
  contact_id: undefined,
  agreement_id: undefined,
  agreement: null,
  releases: [defaultRelease],
  location_release: null,
  location_return: [defaultLocationReturn],
  related_accounts: [], // связанные счета
  related_deals: [], // связанные сделки
  related_deals_ids: [],
  nds: undefined,
  currency: "",
  currency_id: undefined,
  type_container: undefined,
  condition: undefined,
  count_ktk: undefined,
  total_ktk: undefined,
  balance_ktk: undefined,
  bet: undefined,
  containers: [],
  containers_ids: [],
  fine: undefined,
  message_subject: undefined,
  additionally: undefined,
  count_day: undefined,
  snp: undefined,
  expenses: [defaultExpenses],
  responsible_id: null,
  responsible_name: null,
  delete: false,
  edit: true,
};

const initialState: IDealState = {
  containerDealStatuses: [],
  dealType: [],
  loadingOneDeal: false,
  loadingOneRequest: false,
  locations: [],
  idDeal: "",
  isEditDeal: false,
  isEditDealContainers: false,
  responsible: [],
  docs: [],
  dealData: [],
  dealOneData: {},
  containers: [],
  comments: [],
  agreements: [],
  rentRequestData: [],
  requestOneData: {},
  isEditRequest: false,
  saveFiles: [],
};

export interface IPurchase {
  company: string;
  from: string;
  quantity: string;
  type_container: string;
  condition: string;
  price: string;
  payment_option: string;
  is_selected: boolean | null;
}

export interface ISublease {
  company: string;
  from: string;
  to: string;
  type_container: string;
  quantity: string;
  condition: string;
  bet: string;
  count_day: string;
  snp: string;
  type_table: boolean | null;
  is_selected: boolean | null;
}

export interface IKtk {
  number: string;
  from: string;
  quantity: string;
  type_container: string;
  condition: string;
  remainingCost: string;
  terminal: string;
  is_selected: boolean | null;
}

export type IStatus = "completed" | "partCompleted" | "cancelled" | undefined;

export interface IRequest {
  id?: number;
  agreement_date: string;
  type_request_char: string;
  name_request?: string;
  my_company?: string;
  my_company_id?: number;
  company?: string;
  company_id?: number;
  agreement?: string;
  agreement_id?: number;
  location_release?: ILocation[];
  request_location?: Array<{
    location_return?: string;
    options?: any;
  }>;
  request_release?: Array<{
    location_release?: string;
    options?: any;
  }>;
  location_return?: ILocation[];
  type_container?: string;
  condition?: string;
  count_ktk?: string;
  bet?: string;
  currency?: string;
  currency_id?: number;
  count_day?: string;
  snp?: string;
  signer?: string;
  fine?: string;
  contractor_inn?: string;
  customer_inn?: string;
  date_action_start?: Date;
  date_action_end?: Date;
  id_request?: string;
  // date_request?: Date;
  date_request?: string;
  request_author?: string;
  supply_department?: string;
  director?: string;
  operational_department?: string;
  purchase_mail_theme?: string;
  purchase_release_number?: string;
  purchase_fine?: string;
  purchase_add_info?: string;
  purchase_docs?: number[] | null;
  sublease_mail_theme?: string;
  sublease_release_number?: string;
  sublease_fine?: string;
  sublease_add_info?: string;
  sublease_docs?: number[] | null;
  ktk_mail_theme?: string;
  ktk_release_number?: string;
  ktk_fine?: string;
  ktk_add_info?: string;
  ktk_docs?: number[] | null;
  return_rent_reason?: string;
  approved?: boolean;
  purchase?: IPurchase[];
  sublease?: ISublease[];
  ktk?: IKtk[];
  return_to_revision?: string;
  delete?: boolean;
  edit?: boolean;
  readonly responsible_id: number | null;
  readonly responsible?: string;
  status: IStatus;
  purchase_type_id: number;
  ktk_type_id: number;
  file_ids?: number[] | null;
  saveFiles?: any[] | null;
  is_transferred_to_supply: false;
  type_table: boolean | null;
  cancel_reason: any[] | null;
}

export const defaultValuesRequest: IRequest = {
  id: undefined,
  agreement_date: "",
  type_request_char: "JJ",
  name_request: undefined,
  my_company: undefined,
  my_company_id: undefined,
  company: undefined,
  company_id: undefined,
  agreement: undefined,
  agreement_id: undefined,
  location_release: [],
  location_return: [defaultLocationReturn],
  type_container: undefined,
  condition: undefined,
  count_ktk: undefined,
  bet: undefined,
  currency: undefined,
  currency_id: undefined,
  count_day: undefined,
  snp: undefined,
  signer: undefined,
  fine: undefined,
  contractor_inn: undefined,
  customer_inn: undefined,
  date_action_start: undefined,
  date_action_end: undefined,
  id_request: "",
  date_request: undefined,
  request_author: undefined,
  supply_department: undefined,
  director: undefined,
  operational_department: undefined,
  purchase_mail_theme: undefined,
  purchase_release_number: undefined,
  purchase_fine: undefined,
  purchase_add_info: undefined,
  purchase_docs: [],
  sublease_mail_theme: undefined,
  sublease_release_number: undefined,
  sublease_fine: undefined,
  sublease_add_info: undefined,
  sublease_docs: [],
  ktk_mail_theme: undefined,
  ktk_release_number: undefined,
  ktk_fine: undefined,
  ktk_add_info: undefined,
  ktk_docs: [],
  return_rent_reason: undefined,
  approved: false,
  purchase: undefined,
  sublease: undefined,
  ktk: undefined,
  return_to_revision: undefined,
  responsible_id: null,
  status: undefined,
  responsible: "",
  purchase_type_id: 6,
  ktk_type_id: 5,
  file_ids: [],
  saveFiles: [],
  is_transferred_to_supply: false,
  type_table: null,
  cancel_reason: null,
  delete: false,
};

function transformRequestData(request: IRequest): IRequest {
  if (!request) return request;

  if (!request.request_location) {
    return request;
  }

  const transformedLocationReturn = request.request_location.map((loc) => ({
    location: loc.location_return ?? null,
    options: loc.options || [],
  }));

  return {
    ...request,
    location_return: transformedLocationReturn,
  };
}

export interface IFormRequest {
  onEdit?: () => void;
  onRelease?: () => void;
  onClose?: (isClear?: boolean) => void;
  isOpen?: boolean;
  isDisabled?: boolean;
  width?: string;
  title?: string;
  initialValue: IRequest;
  disabled?: boolean;
}

interface IRequestState {
  requestOneData: IRequest | {};
  isEditRequest: boolean;
}

const initialStateRequest: IRequestState = {
  requestOneData: {},
  isEditRequest: false,
};

export const generateIdDeal = createAsyncThunk(
  "generateIdDeal",
  async (payload: string) => {
    return await Rest_rent.generateIdDeal(payload);
  }
);

export const findReleaseId = createAsyncThunk(
  "findReleaseId",
  async (payload: string) => {
    return await Rest_releases.findReleaseId(payload);
  }
);
export const findOneRelease = createAsyncThunk(
  "findOneRelease",
  async (payload: number) => {
    return await Rest_releases.findOneRelease(payload);
  }
);
export const findOneDeal = createAsyncThunk(
  "findOneDeal",
  async (id: number) => {
    return await Rest_rent.findOneDeal(id);
  }
);

export const findOneRequest = createAsyncThunk(
  "findOneRequest",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await Rest_rent.findOneRequest(id);

      const transformed = transformRequestData(response);
      return transformed;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unknown error occurred");
    }
  }
);

export const setDeals = createAsyncThunk("setDeals", async (payload: IDeal) => {
  return await Rest_rent.setDeals(payload);
});
export const updateDeals = createAsyncThunk(
  "updateDeals",
  async (payload: IDeal) => {
    return await Rest_rent.updateDeals(payload);
  }
);
export const setRentRequests = createAsyncThunk(
  "setRentRequests",
  async (payload: IRequest) => {
    return await Rest_rent.setRentRequests(payload);
  }
);

export const deleteIdsRequest = createAsyncThunk(
  "deleteIdsRequest",
  async (payload: any) => {
    return await Rest_rent.deleteIdsRequest(payload);
  }
);

export const deleteIdsDeal = createAsyncThunk(
  "deleteIdsDeal",
  async (payload: any) => {
    return await Rest_rent.deleteIdsDeal(payload);
  }
);

const rentSlice = createSlice({
  name: "rent",
  initialState,
  reducers: {
    resetStoreRent(state) {
      state.containerDealStatuses = initialState.containerDealStatuses;
      state.dealType = initialState.dealType;
      state.loadingOneDeal = initialState.loadingOneDeal;
      state.loadingOneRequest = initialState.loadingOneRequest;
      state.locations = initialState.locations;
      state.idDeal = initialState.idDeal;
      state.isEditDeal = initialState.isEditDeal;
      state.isEditDealContainers = initialState.isEditDealContainers;
      state.responsible = initialState.responsible;
      state.docs = initialState.docs;
      state.dealData = initialState.dealData;
      state.dealOneData = initialState.dealOneData;
      state.containers = initialState.containers;
      state.comments = initialState.comments;
      state.agreements = initialState.agreements;
      state.rentRequestData = initialState.rentRequestData;
      state.requestOneData = initialState.requestOneData;
      state.isEditRequest = initialState.isEditRequest;
      state.saveFiles = initialState.saveFiles;
    },
    setLocations(state, action) {
      state.locations = action.payload;
    },
    setResponsible(state, action) {
      state.responsible = action.payload;
    },
    setEditDeal(state, action) {
      state.isEditDeal = action.payload;
    },
    setEditRequest(state, action) {
      state.isEditRequest = action.payload;
    },
    setOneDeal(state, action) {
      state.dealOneData = action.payload;
    },
    setTableDeals(state, action) {
      state.dealData = action.payload;
    },
    setFiltersDeals(state, action) {
      state.dealData = action.payload || [];
    },
    setDocsDeal(state, action) {
      state.docs = action.payload;
    },
    setIsEditDealContainers(state, action) {
      state.isEditDealContainers = action.payload;
    },
    editDealContainerList(state, action) {
      state.containers = action.payload;
    },
    setRentRequestData(state, action) {
      state.rentRequestData = action.payload;
    },
    resetRentRequestData(state) {
      state.rentRequestData = [];
    },
    setTableRequests(state, action) {
      state.rentRequestData = action.payload;
    },
    setOneRequest(state, action) {
      state.requestOneData = action.payload;
    },
    setSaveFiles(state, action) {
      state.saveFiles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findOneRequest.fulfilled, (state, action) => {
      state.requestOneData = action.payload;
      state.loadingOneRequest = false;
    });
    builder.addCase(findOneRequest.pending, (state) => {
      state.loadingOneRequest = true;
    });
    builder.addCase(findOneRequest.rejected, (state) => {
      state.loadingOneRequest = false;
    });
  },
});
export default rentSlice.reducer;
export const {
  setResponsible,
  setEditDeal,
  setDocsDeal,
  setIsEditDealContainers,
  setOneDeal,
  editDealContainerList,
  setLocations,
  setFiltersDeals,
  setTableDeals,
  setRentRequestData,
  resetRentRequestData,
  setTableRequests,
  setOneRequest,
  setEditRequest,
  setSaveFiles,
  resetStoreRent
} = rentSlice.actions;
