import { apiAxios } from '../api/config';

export interface ISearch {
  pageSize?: number;
  current?: number;
  order?: string;
  text: string;
}

export class Rest_location {
  static searchLocationName = (payload: {name?: string, text?: string}): Promise<any> => {
    return apiAxios.post('location/search-terminals', payload);
  };
}
