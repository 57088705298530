import { Flex, Typography } from 'antd';
import { IColumnType } from '../../components/ui/Table/Table';
import Scroll from '../../components/ui/List/Scroll';
import { isValidHttpUrl } from '../../helpers/link-helper';

export const columns: IColumnType<any>[] = [
  {
    title: '',
    dataIndex: 'id',
    filterIndex: 'id',
    checkbox: true,
    menu: true,
    type: 'string',
    key: '0',
  },
  {
    title: 'Номер контейнера',
    dataIndex: 'num_container',
    filterIndex: 'num_container',
    link: true,
    width: '100%',
    type: 'string',
    key: '1',
  },
  {
    title: 'Тип',
    dataIndex: 'type_container',
    filterIndex: 'type_container',
    width: '20%',
    type: 'dropdown',
    key: '3',
  },
  {
    title: 'Терминал',
    dataIndex: 'terminal',
    filterIndex: 'terminal.name',
    type: 'string',
    width: '100px',
    key: '12',
    render: (columns, item) => {
      const terminal =
        item?.terminal?.name ?? '';
      return terminal ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: { placement: 'left', title: terminal },
          }}
          style={{ maxWidth: '100px', margin: 0 }}
        >
          {terminal}
        </Typography.Paragraph>
      ) : null;
    },
  },
  {
    title: 'Сток',
    dataIndex: 'terminalStock',
    filterIndex: 'terminalStock.label',
    type: 'string',
    width: '30%',
    key: '2',
    render: (columns, item) => {
      const stock =
        item?.terminalStock?.label ?? '';
      return stock ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: { placement: 'left', title: stock },
          }}
          style={{ maxWidth: '100px', margin: 0 }}
        >
          {stock}
        </Typography.Paragraph>
      ) : null;
    },
  },
  {
    title: 'Фото',
    dataIndex: 'photo_bool',
    filterIndex: '',
    width: '20%',
    type: '',
    key: '4',
    render: (columns, item) =>
      item?.photo_bool && isValidHttpUrl(item?.photo_bool) ? (
        <Typography.Link href={item?.photo_bool} target='_blank'>Да</Typography.Link>
      ) : (
        <Typography.Text>Нет</Typography.Text>
      ),
  },
  {
    title: 'В ремонте',
    dataIndex: 'repair',
    filterIndex: 'repair',
    type: 'boolean',
    width: '30%',
    key: '5',
  },
  {
    title: 'Клиент',
    dataIndex: 'contractors',
    filterIndex: 'contractors.name_company',
    type: 'string',
    width: '100px',
    key: '6',
    render: (columns, item) => {
      const company =
        item?.contractors?.name_company ||
        item?.['contractors.name_company'] ||
        '';
      return company ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: { placement: 'left', title: company },
          }}
          style={{ maxWidth: '100px', margin: 0 }}
        >
          {company}
        </Typography.Paragraph>
      ) : null;
    },
  },
  {
    title: 'Состояние',
    dataIndex: 'quality',
    filterIndex: 'quality',
    type: 'string',
    width: '20%',
    key: '7',
  },
  {
    title: 'Входящая ставка',
    dataIndex: 'currency_price',
    filterIndex: 'currency_price',
    type: 'string',
    width: '20%',
    key: '8',
    render: (col, item) => {
      const price = item?.currency_price;
      const currency = item?.currency || '';
      return price ? (
        <Flex gap={5}>
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              tooltip: { placement: 'left', title: price },
            }}
            style={{ maxWidth: '80px', margin: 0 }}
          >
            {price}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ maxWidth: '40px', margin: 0 }}>
            {currency}
          </Typography.Paragraph>
        </Flex>
      ) : null;
    },
  },
  {
    title: 'Текущий статус',
    dataIndex: 'status',
    filterIndex: 'status.name',
    type: 'string',
    width: '20%',
    key: '9',
    render: (columns, item) => {
      const status =
        item?.status?.name ?? '';
      return status ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: { placement: 'left', title: status },
          }}
          style={{ maxWidth: '100px', margin: 0 }}
        >
          {status}
        </Typography.Paragraph>
      ) : null;
    },
  },
  {
    title: 'Бронь До',
    dataIndex: 'booking_before',
    filterIndex: 'booking_before',
    type: 'date',
    width: '20%',
    key: '10',
  },
  {
    title: 'Дата входа',
    dataIndex: 'date_entry',
    filterIndex: 'date_entry',
    type: 'date',
    width: '20%',
    key: '11',
  },
  {
    title: 'Комментарии',
    dataIndex: 'comment',
    filterIndex: 'comment',
    type: 'string',
    width: '20%',
    key: '13',
    render: (text: any, item) =>
      item?.comment ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: {
              placement: 'left',
              title: <Scroll>{item?.comment}</Scroll>,
            },
          }}
          style={{ maxWidth: '130px', margin: 0 }}
        >
          {item?.comment}
        </Typography.Paragraph>
      ) : null,
  },
  {
    title: 'Дата прибытия',
    dataIndex: 'arrival_date',
    filterIndex: 'arrival_date',
    type: 'date',
    width: '20%',
    key: '14',
  },
  {
    title: 'Дата убытия',
    dataIndex: 'departure_date',
    filterIndex: 'departure_date',
    type: 'date',
    width: '30%',
    key: '15',
  },
  {
    title: '',
    dataIndex: 'hidden',
    filterIndex: 'hidden',
    width: '20%',
    key: '16',
  },
];

// Для импорта
export const columnsImport = [
  {
    title: 'Номер контейнера',
    dataIndex: 'num_container',
    type: 'string',
    key: '0',
  },
  {
    title: 'Сток',
    dataIndex: 'stock',
    type: 'string',
    key: '1',
  },
  {
    title: 'Тип',
    dataIndex: 'type_container',
    type: 'dropdown',
    key: '2',
  },
  {
    title: 'Фото',
    dataIndex: 'photo_bool',
    type: 'string',
    key: '3',
  },
  {
    title: 'В ремонте',
    dataIndex: 'repair',
    type: 'boolean',
    key: '4',
  },
  {
    title: 'Клиент',
    dataIndex: 'contractors.name_company',
    type: 'string',
    key: '5',
  },
  {
    title: 'Состояние',
    dataIndex: 'quality',
    type: 'string',
    key: '6',
  },
  {
    title: 'Входящая ставка',
    dataIndex: 'currency_price',
    type: 'string',
    key: '7',
  },
  {
    title: 'Текущий статус',
    dataIndex: 'status.name',
    type: 'string',
    key: '8',
  },
  {
    title: 'Бронь До',
    dataIndex: 'booking_before',
    type: 'date',
    key: '9',
  },
  {
    title: 'Дата входа',
    dataIndex: 'date_entry',
    type: 'date',
    key: '10',
  },
  {
    title: 'Терминал',
    dataIndex: 'child_terminal.name',
    type: 'string',
    key: '11',
  },
  {
    title: 'Комментарии',
    dataIndex: 'comment',
    type: 'string',
    key: '12',
  },
  {
    title: 'Дата прибытия',
    dataIndex: 'arrival_date',
    type: 'date',
    key: '13',
  },
  {
    title: 'Дата убытия',
    dataIndex: 'departure_date',
    type: 'date',
    key: '14',
  },
];
