import { useState } from 'react';
import React, { useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Flex, Form, Select, Typography } from 'antd';

import { Utils } from '../../../../utils';

import { messagesModal, SALES_DEAL } from '../../../../constants';
import { salesTypeId, TAB_DEAL, TAB_DOCUMENT, TAB_REPAIR } from '../constants';

import { RootState, useStoreDispatch } from '../../../../store';
import { ITab, ITabs } from '../../../../components/ui/Tabs/Tabs';
import { clearSearchCompany } from '../../../../store/contractors';
import ModalConfirm from '../../../../components/ui/ModalConfirm/ModalConfirm';
import TooltipButton from '../../../../components/ui/TooltipButton/TooltipButton';

import {
  defaultValuesDeal,
  generateIdDeal,
  IDeal,
  IFormDeal,
  setDeals,
  setDocsDeal,
  setEditDeal,
  setIsEditDealContainers,
  setResponsible,
} from '../../../../store/sales';
import { TabDeal, TabRepair, TabTable } from './tabs/edit';
import style from '../style.module.scss';
import CustomDrawer from '../../../../components/CustomDrawer/CustomDrawer';
import { ITypeDeal } from '../../../../types';
import CopyButton from '../../../../components/ui/CopyButton/CopyButton';
import Icon from '@ant-design/icons';
import { CopyBufferIconDefault } from '../../../../assets/image/svg';
import IconButton from '../../../../components/ui/IconButton';
import { useLink } from '../../../../hooks';
import { CREATE } from '../../../../helpers/string-helpers';
import ResponsibleList from '../../../../components/ResponsibleList';
import { IFile } from '../../../../components/ui/UploaderDocsUnique/UploaderDocsUnique';
import { submitFilesForm } from '../../../../helpers/file-helpers';

const dealNames = ['name_deal', 'my_company', 'company'];
const docNames = ['doc'];

const FormDeal: React.FC<IFormDeal> = ({
  onClose,
  onView,
  isCopy,
  isOpen,
  typeForm,
  title = 'Редактировать сделку',
  initialValue,
}) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const [form] = Form.useForm();
  const link = useLink({ key: SALES_DEAL, id: initialValue?.id as number });

  const [activeKey, setActiveKey] = useState(() => TAB_DEAL);

  const [openDrawerDeal, setOpenDrawerDeal] = React.useState<boolean>(false);

  const [confirmation, setConfirmation] = React.useState<boolean>(false);

  const [isErrorsDeal, setIsErrorsDeal] = React.useState(false);

  const [isErrorsDoc, setIsErrorsDoc] = React.useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [saveFiles, setSaveFiles] = useState<IFile[]>([]);

  const [initialFiles, setInitialFiles] = useState<IFile[]>([]);

  const isDisabled = !!initialValue?.id;

  const idDeal = useSelector<RootState>(
    (state) => state.sales.idDeal,
  ) as string;

  const isEditDeal = useSelector<RootState>((state) => state.sales.isEditDeal);

  const responsible = useSelector(
    (state: RootState) => state.sales.responsible,
  );

  const dealType = useSelector<RootState>(
    (state) => state.types.dataTypeDeal,
  ) as ITypeDeal[];

  const isEditDealContainers = useSelector<RootState>(
    (state) => state.sales.isEditDealContainers,
  ) as boolean;

  const deliveryType = {
    id: undefined,
    edit: true,
    disabled: false,
    type_deal: 'Доставка КТК',
    type_deal_char: 'Д',
    type_deal_id: 1,
    parentId: initialValue?.id,
  };

  useEffect(() => {
    setIsErrorsDeal(false);
    setIsErrorsDeal(false);
    dispatch(setEditDeal(false));
    dispatch(setIsEditDealContainers(false));
    form.resetFields();
  }, [isOpen]);

  useEffect(() => {
    setSaveFiles(initialValue.files || []);
    setInitialFiles(initialValue.files || []);
    if (isDisabled) {
      const { route } = initialValue;
      const currentRoute = Array.isArray(route)
        ? route
        : defaultValuesDeal.route;
      dispatch(setResponsible(initialValue?.responsible));
      form.setFieldsValue({
        ...defaultValuesDeal,
        ...initialValue,
        route: currentRoute,
      });
    } else {
      const initUser = {
        avatar: Utils.getUserInfo().avatar,
        name: Utils.getUserInfo().fio,
        id: Utils.getUserInfo().id,
        isResponsible: true,
      };
      dispatch(generateIdDeal(defaultValuesDeal.type_deal_char)).finally(() => {
        dispatch(setResponsible(Utils.getUserInfo().fio ? [initUser] : []));
        const responsibleInfo = {
          responsible_id: initUser.id,
          responsible_name: initUser.name,
        };
        const id_deal = `${defaultValuesDeal.type_deal_char} ${idDeal}`;
        form.setFieldsValue({
          ...defaultValuesDeal,
          ...initialValue,
          ...responsibleInfo,
          id_deal,
        });
      });
    }
  }, [isDisabled, isOpen, idDeal, form, dispatch, typeForm]);

  const onCloseFormDeal = () => {
    setOpenDrawerDeal(false);
  };
  const onOpenFormDeal = () => {
    setOpenDrawerDeal(true);
  };

  const selectedType = (val: string | null, opt: any) => {
    form.setFieldValue('type_deal', opt?.label ?? '');
    form.setFieldValue('type_deal_char', `${opt?.char || ''}`);
    form.setFieldValue('id_deal', `${opt?.char || ''} ${idDeal}`);
  };



  const handleClose = (bol?: boolean) => {
    const promise = new Promise((resolve, reject) => {
      resolve(dispatch(setEditDeal(false)));
      resolve(dispatch(clearSearchCompany()));
      resolve(dispatch(setResponsible([])));
    });
    promise.then(() => {
      onClose(bol);
      setIsErrorsDeal(false);
      setIsErrorsDoc(false);
      form.resetFields();
    }).catch((e) => {
      console.error(e);
    });
  };

  const onSubmit = async (values: IDeal) => {
    try {
      const { containers, comments, ...rest } = values;
      const route = rest?.route || [];
      rest.comment = '';
      setIsLoading(true);

      const submit = {
        deal: {
          ...initialValue,
          ...rest,
          responsible,
          route,
          routeText: route?.join('\n'),
          status_id: initialValue?.id ? 2 : 1,
        },
        containers,
        comments,
      };

      submitFilesForm({
        id: initialValue?.id,
        submit,
        saveFiles,
        initialFiles,
        fetchSubmitForm: (arg: any) => dispatch(setDeals(arg)),
      });
      // dispatch(setDeals(submit))

      handleClose(true);
      form.resetFields();
    } catch (err) {
      console.error('submit', err);
    } finally {
      setIsLoading(false);
      setSaveFiles([]);
      setInitialFiles([]);
      dispatch(setResponsible([]));
      dispatch(setDocsDeal([]));
    }
  };

  const onUpdate = async (changedValues: any, allValues: any) => {
    setIsErrorsDeal(false);
    setIsErrorsDoc(false);
    return;
  };

  const onFinishError = async ({ values, errorFields, outOfDate }: any) => {
    if (errorFields) {
      const isComplectCheckErrors = [];
      for (const errors of errorFields) {
        const title = errors?.name?.[0];
        if (isComplectCheckErrors.length === 2) {
          break;
        }
        if (dealNames.includes(title)) {
          setIsErrorsDeal(true);
        }
        if (docNames.includes(title)) {
          setIsErrorsDeal(true);
        }
      }
    }

    return;
  };

  const updateResponsible = (item: any) => {
    form.setFieldValue('responsible_id', item?.id);
    form.setFieldValue('responsible_name', item?.name);
  };

  const updateResponsibleList = (items: any[]) => {
    dispatch(setResponsible(items));
  };

  const changeClickTab = (key: string) => {
    setActiveKey(key);
  };

   // функции для обновления файлов
    const handlerSaveFiles = (file: any) => {
      setSaveFiles((prev) => [...prev, file]);
    };

    const handlerDeleteFiles = ({
      file,
      isDelete = false,
    }: {
      file: IFile;
      isDelete?: boolean;
    }) => {
      if (isDelete) {
        setSaveFiles((prev) => prev.filter((fil) => fil.name !== file.name));
      } else {
        setInitialFiles((prev) =>
          prev.map((fil) => {
            if (fil?.name === file.name) {
              return { ...fil, delete: file.delete };
            }
            return fil;
          }),
        );
      }
    };

    const tabs: ITab[] = useMemo(
      () => [
        {
          key: TAB_DEAL,
          label: 'Сделка',
          forceRender: isOpen,
          isError: isErrorsDeal,
          disabledText: isEditDealContainers ? t('Завершите редактирование') : '',
          disabled: isEditDealContainers,
          content: (
            <TabDeal
              isOpen={isOpen}
              onOpenFormDeal={onOpenFormDeal}
              isDisabled={isDisabled}
            />
          ),
        },
        {
          key: TAB_DOCUMENT,
          label: 'Документы',
          forceRender: isOpen,
          isError: isErrorsDoc,
          content: <TabTable initialValue={initialValue} isOpen={isOpen} isDisabled={isDisabled} handlerDeleteFiles={handlerDeleteFiles} handlerSaveFiles={handlerSaveFiles} />,
        },
        {
          key: TAB_REPAIR,
          label: 'В ремонте',
          forceRender: false,
          content: <TabRepair isOpen={isOpen} />,
        },
      ],
      [
        typeForm,
        isOpen,
        isDisabled,
        isErrorsDoc,
        isErrorsDeal,
        form,
        isEditDeal,
        isEditDealContainers,
      ],
    );

  return (
    <>
      <Form
        className={style['tabs-form']}
        autoComplete="off"
        noValidate
        layout="vertical"
        form={form}
        initialValues={defaultValuesDeal}
        scrollToFirstError
        onFinish={onSubmit}
        onFinishFailed={onFinishError}
        onValuesChange={onUpdate}
      >
        <Flex
          justify="space-between"
          align="center"
          style={{ marginBottom: 24 }}
        >
          <Flex gap={10} align="center" style={{ marginTop: 15 }}>
            <Typography.Text className={style.title}>
              {t(title ?? '')}
            </Typography.Text>
          </Flex>
          <Col span={8}>
            <Flex vertical>
              <Flex vertical gap={10}>
                <Flex justify="end">
                  <Flex gap={5} align={'center'}>
                    <IconButton
                      hidden={!onView || !isDisabled}
                      disabled={!isDisabled}
                      iType="view-form"
                      onClick={onView}
                    />
                    {isDisabled && isCopy && (
                      <CopyButton
                        text={link}
                        textSuccess={t('Ссылка скопирована')}
                        icon={<Icon component={CopyBufferIconDefault} />}
                        disabled={!isDisabled}
                      />
                    )}
                  </Flex>
                </Flex>
              </Flex>
              <Typography.Text className={style['text-card-12']}>
                {t('Тип сделки')}
              </Typography.Text>

              <Form.Item name={'type_deal'} noStyle />
              <Form.Item name={'type_deal_char'} noStyle />
              <Form.Item name="saveFiles" noStyle />
              <Form.Item name={'id'} noStyle />
              <Form.Item
                name={'type_deal_id'}
                rules={[{ required: true, message: t('Обязательное поле') }]}
              >
                <Select
                  placeholder={t('Выберите тип сделки')}
                  options={dealType
                    ?.map(({ id, value, char, status_containers_id }) => ({
                      value: id,
                      label: value,
                      char,
                      status_containers_id,
                    }))
                    .filter(
                      (item) => item?.status_containers_id === salesTypeId,
                    )}
                  disabled={isDisabled || !!initialValue?.parentId}
                  onSelect={selectedType}
                />
              </Form.Item>
            </Flex>
          </Col>
        </Flex>

        <ITabs
          className={style['tabs-form__tab']}
          isErrorTabs
          activeKey={activeKey}
          tabs={tabs}
          onChange={changeClickTab}
        />
        <Flex vertical>
          <Flex gap={6} vertical>
            <Typography.Title
              level={4}
              className={style['subtitle']}
              style={{ fontWeight: 700 }}
            >
              {t('Дополнительно')}
            </Typography.Title>
            <Typography.Text style={{ marginTop: 0 }}>
              {t('Ответственный')}
            </Typography.Text>
            <Form.Item name="containers_ids" noStyle />
            <Form.Item name="responsible" noStyle />
            <Form.Item name="responsible_id" noStyle />
            <Form.Item name="responsible_name" noStyle />
            <ResponsibleList
              responsible={responsible}
              handlerSubmit={updateResponsible}
              handlerSubmitList={updateResponsibleList}
            />
          </Flex>
          <Form.Item>
            <Flex
              gap={8}
              justify="space-between"
              style={{ margin: '32px auto 10px auto', maxWidth: '500px' }}
            >
              <TooltipButton
                propsTooltip={{
                  title: isEditDealContainers
                    ? t('Завершите редактирование')
                    : initialValue?.id
                    ? t('Сохранить изменения')
                    : t('Добавить'),
                }}
                propsButton={{
                  type: 'primary',
                  htmlType: 'submit',
                  style: { maxWidth: '290px', minWidth: '290px' },
                  disabled: isLoading || isEditDealContainers,
                }}
              >
                {isLoading
                  ? '...Сохранение'
                  : initialValue?.id
                  ? t('Сохранить изменения')
                  : t('Добавить')}
              </TooltipButton>

              <Button
                onClick={() => setConfirmation(true)}
                type={'text'}
                style={{ maxWidth: '134px', color: '#E14453' }}
                disabled={isLoading}
              >
                {t('Отменить')}
              </Button>
            </Flex>
          </Form.Item>
        </Flex>
      </Form>
      <ModalConfirm
        title={messagesModal.warning.title}
        subtitle={messagesModal.warning.subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={handleClose}
      />
      <CustomDrawer open={openDrawerDeal} onClose={onCloseFormDeal} isHeight>
        <FormDeal
          initialValue={{ ...initialValue, ...deliveryType }}
          title={'Создать сделку'}
          onClose={onCloseFormDeal}
          typeForm={CREATE}
          isOpen={openDrawerDeal}
        />
      </CustomDrawer>
    </>
  );
};

export default FormDeal;
