import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoComplete,
  Button,
  Flex,
  Form,
  Input,
  MenuProps,
  App,
  Modal,
  Space,
  Typography,
} from 'antd';
import Icon, { PlusOutlined } from '@ant-design/icons';
import socket from '../../socket';
import { columns, columnsImport } from './columns';
import FormContainer from './formContainer';
import { TerminalService } from './service';
import FormModalImport from './formModalImport';
import { RouteNames } from '../../router/names';
import FormModalContainer from './formModalContainer';
import { blackCloseIcon } from '../../assets/image/svg';
import { useStoreDispatch } from '../../store';
import { useBreadcrumb, useMenu, useParamsHistory } from '../../hooks';
import { CREATE, EDIT, UPDATE, VIEW } from '../../helpers/string-helpers';
import { Table } from '../../components/ui/Table/Table';
import {
  deleteIds,
  getActivityContainer,
  IAllUpdate,
  searchContainer,
  setContainer,
  setContainerImport,
  updateSelectedKtk,
} from '../../store/containers';

import './style.scss';
import '../../assets/scss/terminal.scss';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';
import { CONTAINER, RENT_DEAL, SALES_DEAL } from '../../constants';
import { Rest_containers } from '../../services/rest_containers';
import { mapperContainers } from './utils';
import { useGettingDataForTables } from '../../hooks/useGettingDataForTables/useGettingDataForTables';
import FormUpdateContainer from './FormUpdateContainer';
import { resetStateData } from '../../store/filters';
import { getDateString } from '../../helpers/date-helpers';

const Containers: React.FC = () => {
  const { message } = App.useApp();
  const { history, location } = useParamsHistory();
  const { pathname, search } = location;
  const dispatch = useStoreDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isModalOpenContainer, setIsModalOpenContainer] =
    useState<boolean>(false);
  const [isModalOpenImport, setIsModalOpenImport] = useState<boolean>(false);
  const [openDrawerContainer, setOpenDrawerContainer] =
    useState<boolean>(false);
  const [currentActivity, setCurrentActivity] = useState<number>(1);
  const [pageActivity, setPageActivity] = useState<number>(10);
  const [totalTableActivity, setTotalTableActivity] = useState<number>(0);
  const [dataRowContainer, setDataRowContainer] = useState<any>();
  const [nameTerminal, setNameTerminal] = useState<string | null>(null);
  const searchParams = new URLSearchParams(location.search);
  const [typeForm, setTypeForm] = useState<string>(CREATE);
  const [loadings, setLoadings] = useState<boolean>(false);
  const [dataComment, setDataComment] = useState<any>([]);
  const [activeButtons, setActiveButtons] = useState<any>({});
  const [searchColumn, setSearchColumn] = useState<any[]>([]);
  const [activityContainer, setActivityContainer] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isUpdateModal, setIsUpdateModal] = useState<boolean>(false);

  const {
    dataTable,
    current,
    pageSize,
    optionsTable,
    checkedData,
    checkedRows,
    setLoadingSubmit,
    setSelected,
    setDataTable,
    updateDate,
    updateEffectDate,
  } = useGettingDataForTables({
    titlePage: 'контейнеры',
    keyItemName: 'num_container',
    subscribeStringAllUpdateForm: 'return-set-massive-change',
    subscribeStringCreateForm: 'return-set-container',
    subscribeStringDelete: 'return-delete-containers',
    subscribeStringUpdateForm: '',
    subscribeError: 'error',
    table: CONTAINER,
    location,
    columns,
    fetchTables: Rest_containers.getContainers,
    fetchDelete: (arg: any) => dispatch(deleteIds(arg)),
    mapperData: (arr) => arr?.map(mapperContainers) || [],
  });

  useBreadcrumb(['Депо', 'Контейнеры']);

  useMenu({
    openKey: RouteNames.DEPOT,
    activeKeys: [RouteNames.DEPOT, RouteNames.CONTAINERS],
  });
  const [updateKtk, setUpdateKtk] = useState<any[]>([]);
  // проверка url
  useEffect(() => {
    if (search) {
      setNameTerminal(searchParams.get('name'));
      updateDate({
        terminal_id: Number(searchParams.get('terminal_id')),
        location_id: searchParams.get('location_id'),
        stock: searchParams.get('stock'),
        stock_id: Number(searchParams.get('stock_id')),
      });
      return;
    }
    if (pathname === RouteNames.CONTAINERS) {
      updateEffectDate({
        terminal_id: null,
        location_id: null,
        stock: null,
        stock_id: null,
      });
      const row = location?.state?.[CONTAINER];
      if (row) {
        window.history.replaceState({ state: null }, document.title);
        if (row?.edit) {
          setIsModalOpenContainer(false);
          setDataRowContainer(row?.edit?.container);
          setActivityContainer(row?.edit?.activity || []);
          setDataComment(row?.edit?.comments_containers || []);
          if (row?.edit?.container?.delete) {
            setIsModalOpenContainer(true);
            return;
          }
          setOpenDrawerContainer(true);
          return;
        }
        form.resetFields();
        setIsModalOpenContainer(false);
        setTypeForm(CREATE);
        setDataRowContainer(row);
        setOpenDrawerContainer(true);
        return;
      }
    }
  }, [pathname]);

  useEffect(() => {
    socket.on('res-update-new-booking', handleNewBooking);
    socket.on('res-set-container-import', resContainerImport);
    socket.on('res-delete-container', resHandleDeleteRow);
    socket.on('res-update-repair', resUpdateRepair);
    socket.on('res-update-date-booking', handleDateBooking);

    return () => {
      socket.off('res-update-new-booking', handleNewBooking);
      socket.off('res-set-container-import', resContainerImport);
      socket.off('res-delete-container', resHandleDeleteRow);
      socket.off('res-update-repair', resUpdateRepair);
      socket.off('res-update-date-booking', handleDateBooking);
    };
  }, [current, pageSize]);

  const subMenu = {
    label: 'Создать сделку',
    getSubMenu: ({
      item,
      rows,
      data,
    }: {
      item: any;
      rows: Set<any>;
      data: any;
      onClose: () => void;
    }): MenuProps['items'] => {
      const containers: any[] =
        rows?.size && data?.length
          ? data.filter((item: any) => rows.has(item?.id))
          : [];
      if (item && rows && !rows.has(item?.id)) {
        containers.push(item);
      }
      return [
        {
          key: 'Продажа',
          label: (
            <Typography.Text
              data-id="link"
              onClick={() =>
                history.push(RouteNames.SALES_DEAL, {
                  [SALES_DEAL]: { containers },
                })
              }
            >
              {t('Продажа')}
            </Typography.Text>
          ),
        },
        {
          key: 'Аренда',
          label: (
            <Typography.Text
              data-id="link"
              onClick={() =>
                history.push(RouteNames.RENT_DEAL, {
                  [RENT_DEAL]: { containers },
                })
              }
            >
              {t('Аренда')}
            </Typography.Text>
          ),
        },
      ];
    },
  };

  const handleSearch = async (inputValue: string) => {
    setSelectedOption(inputValue);
    const commonParams = [{ page: pageSize }, { current }];

    const executeSearch = async (params: any) => {
      const response = await dispatch(searchContainer(params));
    };

    // Если inputValue пусто, выполняем запрос с пустым массивом
    if (inputValue === '') {
      setSelectedOption(null);
      setSearchColumn([]);
      await executeSearch([{ page: pageSize }, { current }]);
      return;
    }

    // Если searchColumn пуст, выполняем поиск по всем колонкам, кроме исключенных
    if (searchColumn.length === 0) {
      const searchParams = columns
        .filter(
          (item: any) =>
            item.dataIndex !== 'date_entry' &&
            item.dataIndex !== 'arrival_date' &&
            item.dataIndex !== 'departure_date' &&
            item.dataIndex !== 'booking_before' &&
            item.dataIndex !== 'hidden',
        )
        .map((item: any) => ({ [item.dataIndex]: inputValue }));

      const finalSearchParams = [...searchParams, ...commonParams];

      await executeSearch(finalSearchParams);
      return;
    }

    // Если есть колонки для поиска и inputValue длиннее 1 символа
    if (searchColumn.length > 0 && inputValue.length > 1) {
      const searchParams = searchColumn.map((item) => ({ [item]: inputValue }));

      const finalSearchParams = [...searchParams, ...commonParams];

      await executeSearch(finalSearchParams);
    }
  };

  const handleButtonClickSearch = (option: any) => {
    setActiveButtons((prevActiveButtons: any) => ({
      ...prevActiveButtons,
      [option.key]: !prevActiveButtons[option.key],
    }));
    setSearchColumn((prevData: any) => [option.dataIndex, ...prevData]);
  };

  const resUpdateRepair = (response: any) => {
    if (!response) return;
    setActivityContainer(response.data.rows);
    setDataTable((prevData: any) => {
      return prevData.map((row: any) => {
        // Найти соответствующий элемент в response.data
        const matchedElement = response.data.rows.find(
          (el: any) => row.id === el.container_id,
        );
        if (matchedElement) {
          // Обновить поле repair, если найдено совпадение
          return {
            ...row, // Скопировать остальные свойства строки
            repair: matchedElement.repair, // Обновить значение repair
          };
        }
        return row; // Если совпадение не найдено, вернуть оригинальную строку
      });
    });
  };

  const resHandleDeleteRow = (response: any) => {
    setDataTable((prevData) => {
      // Проверьте, что response является массивом, если это необходимо
      if (!Array.isArray([response])) {
        console.error('Invalid response data:', [response]);
        return prevData;
      }

      // Предположим, что response это объект с обновленными данными
      const updatedItem = response;
      return prevData.map((item) =>
        item.id === updatedItem.id ? { ...item, ...updatedItem } : item,
      );
    });
  };

  const onFinishImport = (val: any) => {
    dispatch(setContainerImport(val));
  }

  const resContainerImport = (response: any) => {
   setLoadings(true);
    setDataTable((prevData) => {
      const updatedData = [...prevData];

      if (response?.length) {
        response.forEach((newItem: any) => {
          const index = updatedData.findIndex((item) => item.id === newItem.id);
          if (index >= 0) {
            // Обновляем существующий объект
            updatedData[index] = mapperContainers(newItem);
          } else {
            // Добавляем новый объект
            updatedData.unshift(mapperContainers(newItem));
          }
        });

        return updatedData;
      }
      return prevData;
    });
    setLoadings(false);
    setIsModalOpenImport(false);
  };

  const handleNewBooking = (response: any) => {
    if (!response) return;
    const container = response?.[0];
    dispatch(
      getActivityContainer({
        id: container.id,
        current: (currentActivity - 1) * pageActivity,
        page: pageActivity,
      }),
    ).then((response) => {
      setTotalTableActivity(response.payload.count);
      setActivityContainer(response.payload.rows);
    }).catch((e) => {
      console.error(e);
    });

    setDataTable((prevData: any) => {
      return prevData.map((row: any) =>
        row.id === container?.id
          ? {
              ...row,
              'status_terminal.name': container?.['status_terminal.name'] ?? '',
              booking_before: getDateString({date: container?.booking_before})
            }
          : row,
      );
    });
  };

  const handleDateBooking = (response: any) => {
    if (!response) return;

    setActivityContainer(response?.rows);

    const container = response?.rows?.[0];
    setDataTable((prevData: any) => {
      return prevData.map((row: any) => {
        if (container?.id === row.id) {
          return {
            ...row,
            ...container,
          };
        }
        return row; // Если совпадение не найдено, вернуть оригинальную строку
      });
    });
    if (container?.id === dataRowContainer?.id) {
      setDataRowContainer((prev: any) => ({ ...prev, ...container }));
    }
  };

  const onCreate = () => {
    setOpenDrawerContainer(true);
    setDataRowContainer({});
    setTypeForm(CREATE);
    form.resetFields();
  };

  const onCloseFormContainer = () => {
    setOpenDrawerContainer(false);
    setTypeForm(CREATE);
  };

  const closeModalContainers = () => {
    setTypeForm(CREATE);
    setIsModalOpenContainer(false);
    setDataRowContainer({});
  };

  const onCloseFilter = () => {
    history.push(RouteNames.CONTAINERS);
    updateDate({
      child: 0,
      city: 0,
      stock: null,
    });
    setNameTerminal(null);
  };

  const editRow = (payload: any) => {
    if (payload?.id) {
      setDataRowContainer(payload);
      setActivityContainer(payload?.activity || []);
      setDataComment(payload?.comments_containers || []);
      setTypeForm(EDIT);
      if (payload?.delete) {
        setIsModalOpenContainer(true);
        return;
      }
      setOpenDrawerContainer(true);
    }
  };

  const onFinish = (data: any) => {
    dispatch(setContainer(data))
      .then(() => {
        setOpenDrawerContainer(false);
      })
      .catch((err) => message.error('Ошибка сохранения'));
    setTypeForm(CREATE);
    setLoadingSubmit(true);
  };

  const onEdit = () => {
    setTypeForm(EDIT);
    setIsModalOpenContainer(false);
    setOpenDrawerContainer(true);
  };

  const onView = () => {
    setTypeForm(VIEW);
    setOpenDrawerContainer(false);
    setIsModalOpenContainer(true);
  };

  const onRow = (row: any) => {
    console.log(row)
    if (row?.id) {
      setDataRowContainer(row);
      setActivityContainer(row?.activity || []);
      setDataComment(row?.comments_containers || []);
      setIsModalOpenContainer(true);
      setTypeForm(VIEW);
    }
  };

  const handleShowModal = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChangeImport = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    TerminalService.handleFile({event}).then((response) => {
      if (response.length > 0) {
        setIsModalOpenImport(true);
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      }
    }).catch((e) => {
      console.error(e);
    });;
  };

  const closeModalImport = () => {
    setIsModalOpenImport(false);
  };

  const openUpdateModal = () => {
    const result = checkedData?.filter(
      (cont) => !!checkedRows?.includes(cont?.id),
    );
    setUpdateKtk(result);
    setTypeForm(UPDATE);
    setIsUpdateModal(!!result?.length);
  };

  const closeUpdateModal = () => {
    setIsUpdateModal(false);
    setSelected(new Set());
    setUpdateKtk([]);
    dispatch(resetStateData());
    setTypeForm(CREATE);
  };

  const submitUpdateModal = (val: IAllUpdate[]) => {
    dispatch(updateSelectedKtk(val));
    closeUpdateModal();
  };

  return (
    <div className="page-container">
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <Flex align={'center'} gap={10}>
          <div className={'drawer-title'}>{t('ContainerTable')}</div>
          <div className={'drawer-title terminal'}>{nameTerminal}</div>
          {nameTerminal !== null && (
            <Icon component={blackCloseIcon} onClick={onCloseFilter} />
          )}
        </Flex>
        <Flex gap={16}>
          <input
            style={{ display: 'none' }}
            ref={inputRef}
            type="file"
            id="fileSelect"
            accept=".xlsx, .xls, .csv"
            onChange={handleFileChangeImport}
          />
          <Button onClick={handleShowModal}>{t('Импорт')}</Button>

          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff', minWidth: '32px' }}
            onClick={onCreate}
          />
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            options={columns}
            onSearch={(text) => handleSearch(text)}
            popupMatchSelectWidth={false}
            value={selectedOption}
            dropdownRender={() => (
              <>
                <div
                  className={'text-card-12'}
                  style={{ padding: '10px 10px' }}
                >
                  Выбор колонок по которым будет производиться поиск
                </div>
                <Flex
                  gap={5}
                  style={{ width: 300, flexWrap: 'wrap', padding: 10 }}
                >
                  {columns.map(
                    (option) =>
                      option.title !== '' &&
                      option.dataIndex !== 'date_entry' &&
                      option.dataIndex !== 'arrival_date' &&
                      option.dataIndex !== 'departure_date' &&
                      option.dataIndex !== 'booking_before' && (
                        <Button
                          size={'small'}
                          key={option.key}
                          type="primary"
                          className={
                            activeButtons[Number(option.key)]
                              ? 'active-search-btn'
                              : ''
                          }
                          onClick={() => handleButtonClickSearch(option)}
                        >
                          {option.title}
                        </Button>
                      ),
                  )}
                </Flex>
              </>
            )}
          >
            <Input placeholder={t('Введите текст')} />
          </AutoComplete>
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          {...optionsTable}
          handlerAllUpdate={checkedRows?.length > 1 ? openUpdateModal : undefined}
          isUpload // можно выгрузить в файл
          subMenu={subMenu}
          editRow={editRow}
          dataSource={dataTable}
          height={'calc(-162px + 100vh)'}
          style={{ overflow: 'auto hidden' }}
          onRow={onRow}
          onClickLink={onRow}
        />
      </Space>
      <CustomDrawer open={openDrawerContainer} onClose={onCloseFormContainer}>
        <FormContainer
          isOpen={openDrawerContainer}
          isCopy
          onView={onView}
          typeForm={typeForm}
          form={form}
          dataRowContainer={dataRowContainer}
          onClose={onCloseFormContainer}
          onFinishForm={onFinish}
          title={
            typeForm === CREATE
              ? 'Добавить контейнер'
              : 'Редактировать контейнер'
          }
        />
      </CustomDrawer>
      <Modal
        className="page-container"
        closable={false}
        footer={null}
        width={'80%'}
        open={isModalOpenContainer}
      >
        <FormModalContainer
          onEdit={onEdit}
          isCopy
          totalTableActivity={totalTableActivity}
          setActivityContainer={setActivityContainer}
          currentActivity={currentActivity}
          pageActivity={pageActivity}
          activityContainer={activityContainer}
          dataComment={dataComment}
          dataRowContainer={dataRowContainer}
          closeModalContainers={closeModalContainers}
          isDisabled={
            !!dataRowContainer?.deal ||
            !!dataRowContainer?.release ||
            !!dataRowContainer?.delete ||
            !!dataRowContainer?.active
          }
        />
      </Modal>
      <Modal
        className="page-container"
        closable={false}
        footer={null}
        width={'94%'}
        open={isModalOpenImport}
      >
        <FormModalImport
          closeModalImport={closeModalImport}
          loadings={loadings}
          crmColumns={columnsImport}
          setLoadings={setLoadings}
          onFinish={onFinishImport}
        />
      </Modal>
      <CustomDrawer
        open={isUpdateModal}
        onClose={closeUpdateModal}
        isHeight
        minWidth={1200}
      >
        <FormUpdateContainer
          typeForm={typeForm}
          selectedKtk={updateKtk}
          onClose={closeUpdateModal}
          onSubmit={submitUpdateModal}
        />
      </CustomDrawer>
    </div>
  );
};

export default Containers;
