import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card, Flex, Typography } from 'antd';

import style from './style.module.scss';
import { IUsers } from '../../types/users';
import { FormProfile } from './FormProfile';
import CustomDrawer from '../CustomDrawer/CustomDrawer';
import { CREATE, EDIT, VIEW } from '../../helpers/string-helpers';
import { AvatarProfile } from './components/AvatarProfile/AvatarProfile';
import { ViewProfile } from './ViewProfile';
import { TooltipPlacement } from 'antd/es/tooltip';
import classnames from 'classnames';
import socket from '../../socket';

interface IProfile {
  user?: IUsers;
  isDrawer: boolean;
  placement?: TooltipPlacement;
  className?: string;
  isActive?: boolean;
  isShowActive?: boolean;
}

export const Profile: React.FC<IProfile> = ({
  isDrawer,
  user,
  placement,
  className = '',
  isShowActive = false,
}) => {
  const { t } = useTranslation();
  const [typeForm, setTypeForm] = useState<string>(CREATE);
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { fio, description } = user || {
    avatar: null,
    fio: '',
    description: '',
  };

  const onOpen = () => {
    setTypeForm(VIEW);
    setIsOpen(true);
  }
  const onClose = () => {
    setTypeForm(CREATE);
    setIsOpen(false);
  }

  const onEdit = () => setTypeForm(EDIT);
  const onView = () => setTypeForm(VIEW);

  useEffect(() => {
    socket.on('return-users-active', ({id, active}: {id: number, active: boolean}) => {
      if (user?.id === id) {
        setIsActive(active);
      }
    });
    return () => {
      socket.off('return-users-active');
    }
  }, [])

  const tooltipInfo = description ? (
    <Card size="small" title={fio}>
      <Typography.Paragraph
        className={style['profile__description']}
        ellipsis={{
          rows: 1,
          tooltip: { placement: 'bottomLeft', title: description },
        }}
      >
        {description || ''}
      </Typography.Paragraph>
    </Card>
  ) : (
    <div className={style['profile__card']}>
      <Typography.Paragraph style={{ margin: 0 }}>
        {fio || ''}
      </Typography.Paragraph>
    </div>
  );

  return (
    <>
      <div
        className={classnames(style['profile'], {
          [className]: className,
        })}
        onClick={onOpen}
      >
        <AvatarProfile
          placement={placement}
          size={isDrawer ? 42 : 36}
          isHiddenInfo={isDrawer}
          avatar={user?.avatar}
          title={user?.fio}
          tooltipInfo={tooltipInfo}
          isActive={isActive}
          isShowActive={isShowActive}
        />
        <Flex
          hidden={isDrawer}
          vertical
          gap={2}
          style={{
            alignSelf: description ? 'baseline' : 'center',
            marginLeft: '10px',
          }}
        >
          <Typography.Paragraph
            className={style['profile__title']}
            ellipsis={{
              rows: 1,
              tooltip: { placement: 'bottomRight', title: t(fio || 'Гость') },
            }}
          >
            {t(fio || 'Гость')}
          </Typography.Paragraph>
          {!!description && (
            <Typography.Paragraph
              className={style['profile__description']}
              ellipsis={{
                rows: 1,
                tooltip: { placement: 'bottomLeft', title: description },
              }}
            >
              {description}
            </Typography.Paragraph>
          )}
        </Flex>
      </div>
      <CustomDrawer
        isHeight
        minWidth={1000}
        open={isOpen}
        onClose={onClose}
        placement="left"
      >
        {typeForm === VIEW ? (
          <ViewProfile
            isOpen={isOpen}
            typeForm={typeForm}
            initialValue={user}
            title={fio}
            onClose={onClose}
            isCopy
            // isEdit
            onEdit={onEdit}
            isActive={isActive}
            isShowActive
          />
        ) : (
          <FormProfile
            isOpen={isOpen}
            typeForm={typeForm}
            initialValue={user}
            title={fio}
            onClose={onClose}
            isEdit
            onView={onView}
            isActive={isActive}
            isShowActive
          />
        )}
      </CustomDrawer>
    </>
  );
};
