import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Flex, Form, Input, Modal, Space } from 'antd';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';

import { columns } from './columns';
import { RouteNames } from '../../router/names';
import FormModalTerminal from './formModalTerminal';
import { useStoreDispatch } from '../../store';
import FormCardModalTerminal from './formCardModalTerminal';
import { SelectSearch } from '../../components/ui/SelectSearch/SelectSearch';
import { CREATE, EDIT, UPDATE, VIEW } from '../../helpers/string-helpers';
import { Table } from '../../components/ui/Table/Table';
import { useBreadcrumb, useMenu, useParamsHistory } from '../../hooks';
import {
  deleteIds,
  IDataCity,
  setTerminal,
} from '../../store/terminals';

import '../../assets/scss/terminal.scss';
import { TERMINAL } from '../../constants';
import { Rest_terminal } from '../../services/rest_terminal';
import { useGettingDataForTables } from '../../hooks/useGettingDataForTables/useGettingDataForTables';
import { ITerminal } from '../../types/terminal';
import { Rest_location } from '../../services/rest_location';

const Index: React.FC = () => {
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [searchCity, setSearchCity] = useState<string | null>(null);
  const [parent, setParent] = useState<IDataCity | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalViewOpen, setIsModalViewOpen] = useState(false);
  const [isMap, setIsMap] = useState<boolean>(false);

  const [dataRow, setDataRow] = useState<ITerminal | any>();
  const [dataCity, setDataCity] = useState<any>([]);
  const [typeForm, setTypeForm] = useState<string | null>(null);

  useBreadcrumb(['Депо', 'Терминалы']);

  useMenu({
    openKey: RouteNames.DEPOT,
    activeKeys: [RouteNames.DEPOT, RouteNames.TERMINALS],
  });

  const { dataTable, optionsTable, setLoadingSubmit, updateEffectDate } =
    useGettingDataForTables({
      titlePage: 'терминалы',
      keyItemName: 'name',
      subscribeStringCreateForm: `return-set-terminal`,
      subscribeStringDelete: 'return-delete-terminal',
      subscribeError: 'error',
      performsAdditionalActions: () => {
        setIsModalOpen(false);
        form.resetFields();
      },
      table: TERMINAL,
      location,
      columns,
      fetchTables: Rest_terminal.getTerminal,
      fetchDelete: (arg: any) => dispatch(deleteIds(arg)),
    });

  // проверка url
  useEffect(() => {
    const data = location?.state?.[TERMINAL];
    if (pathname === RouteNames.TERMINALS) {
      updateEffectDate();
      if (data) {
        window.history.replaceState({ state: null }, document.title);
        if (data?.edit) {
          setIsMap(true);
          setDataRow(data.edit);
          setTypeForm(UPDATE);
          setIsModalOpen(true);
          return;
        }
        setTypeForm(CREATE);
        setDataRow(data);
        setIsMap(false);
        setIsModalOpen(true);
        return;
      }
    }
    return history.replace(RouteNames.TERMINALS);
  }, [pathname]);

  const onEdit = () => {
    setIsModalViewOpen(false);
    setIsModalOpen(true);
    setIsMap(true);
    setTypeForm(UPDATE);
  };
  const onView = () => {
    setIsModalOpen(false);
    setIsMap(true);
    setTypeForm(VIEW);
    setIsModalViewOpen(true);
  };

  const onChangeSelect = (event: any, option: any, parent: IDataCity) => {
    setSearchCity(parent?.label || '');
    setParent(parent);
  };

  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLocaleLowerCase();
    setSearchCity(value);
    if (!value) {
      setSearchCity(null);
      setDataCity([]);
      setParent(null);
    } else {
      Rest_location.searchLocationName({
        name: value,
      })
        .then((response) => {
          setDataCity(response.data);
        })
        .catch((e) => {
          console.error(e);
          setSearchCity(null);
          setDataCity([]);
          setParent(null);
        });
    }
  };

  const onCreate = () => {
    form.resetFields();
    setDataRow({ id: null, parent_id: parent?.id, parent });
    setTypeForm(CREATE);
    setIsModalOpen(true);
    setSearchCity(null);
    setDataCity([]);
    setParent(null);
  };

  const handleCancelModal = () => {
    console.log('1');
    setTypeForm('');
    setIsModalOpen(false);
    setDataRow(null);
    setSearchCity(null);
    setDataCity([]);
    setParent(null);
    form.resetFields();
  };

  const handleCancelViewModal = () => {
    setTypeForm('');
    setDataRow(null);
    setSearchCity(null);
    setDataCity([]);
    setParent(null);
    setIsModalViewOpen(false);
  };

  const onFinishAddTerminal = (payload: any) => {
    Object.assign(payload, { type_form: typeForm });
    dispatch(setTerminal(payload));
    setTypeForm(EDIT);
    setLoadingSubmit(true);
    setDataRow(null);
  };

  const handleShowEditForm = (row: ITerminal) => {
    setDataRow(row);
    setIsMap(true);
    setTypeForm(UPDATE);
    setIsModalOpen(true);
  };

  const onRow = (row: any) => {
    setDataRow(row);
    setIsModalViewOpen(true);
  };

  return (
    <>
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <div className={'drawer-title'}>{t('TerminalTable')}</div>
        <Flex gap={16}>
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff', minWidth: '32px' }}
            onClick={onCreate}
          />
          <SelectSearch
            dataSource={dataCity}
            onChangeSelect={onChangeSelect}
            dropdownRender={onNameChange}
            placeholder={t('Поиск')}
            style={{ width: 200 }}
            defaults={searchCity}
            hiddenBtn={false}
            onClick={onCreate}
          />
          <Input
            allowClear
            prefix={<SearchOutlined />}
            className={'input header-depo'}
            placeholder={t('Поиск')}
          />
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          {...optionsTable}
          isUpload // можно выгрузить в файл
          dataSource={dataTable}
          height={'calc(-162px + 100vh)'}
          style={{ overflow: 'auto hidden' }}
          onClickLink={onRow}
          editRow={handleShowEditForm}
          onRow={onRow}
        />
      </Space>
      <Modal
        maskClosable={false}
        closable={false}
        open={isModalOpen}
        onCancel={handleCancelModal}
        style={{ top: 30 }}
        width={548}
        footer={null}
      >
        <Flex vertical className={'modal-content'}>
          <FormModalTerminal
            title={
              typeForm === CREATE
                ? 'Добавить терминал'
                : 'Редактировать терминал'
            }
            isCopy
            onView={onView}
            form={form}
            dataCityRedux={dataCity}
            showMapEdit={isMap}
            onFinishAddTerminal={onFinishAddTerminal}
            dataRow={dataRow}
            typeForm={typeForm}
            onClose={handleCancelModal}
          />
        </Flex>
      </Modal>
      <Modal
        open={isModalViewOpen}
        maskClosable={false}
        onCancel={handleCancelViewModal}
        closable={false}
        style={{ top: 30 }}
        footer={null}
      >
        <FormCardModalTerminal
          dataRow={dataRow}
          isCopy
          onClose={handleCancelViewModal}
          onEdit={onEdit}
        />
      </Modal>
    </>
  );
};

export default Index;
