import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Space } from 'antd';
import { Table } from '../../../components/ui/Table/Table';
import { columns } from './columns';
import { useTranslation } from 'react-i18next';
import { useBreadcrumb, useMenu, useParamsHistory } from '../../../hooks';
import { RouteNames } from '../../../router/names';
import Course from '../../../components/Course';
import { FINANCE_BILLING } from '../../../constants';
import CustomDrawer from '../../../components/CustomDrawer/CustomDrawer';
import { FormBilling } from './FormBilling';
import { CREATE, EDIT, VIEW } from '../../../helpers/string-helpers';
import { RootState, useStoreDispatch } from '../../../store';
import { useSelector } from 'react-redux';
import {
  IBilling,
  setOneBillingDate,
  defaultValuesBilling,
  deleteIdsBilling,
  resetStoreBilling,
} from '../../../store/finance';
import { IParamRestDelete } from '../../../types';
import { useGettingDataForTables } from '../../../hooks/useGettingDataForTables/useGettingDataForTables';
import { Rest_finance } from '../../../services/rest_finance';

const FinanceBillingTable = () => {
  useBreadcrumb(['Финансы', 'Выставление счета']);
  useMenu({
    openKey: RouteNames.FINANCE,
    activeKeys: [RouteNames.FINANCE, RouteNames.FINANCE_BILLING],
  });
  const { t } = useTranslation();
  const { location, history } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const [typeForm, setTypeForm] = useState(EDIT);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const initialValue = useSelector<RootState>(
    (state) => state.finance.oneBillingData,
  ) as IBilling;

  // HACK: удалить после доработок
  const fetchDelete = ({ ids, isDelete }: IParamRestDelete) =>
    Promise.resolve({ data: [] });

  const { dataTable, optionsTable, updateDate, updateEffectDate } =
    useGettingDataForTables({
      titlePage: 'заявки на выставление счета',
      keyItemName: 'billing_id',
      subscribeStringCreateForm: 'return-set-finance-invoice',
      subscribeStringUpdateForm: 'return-update-finance-invoice',
      subscribeStringDelete: 'return-delete-finance-invoice',
      subscribeError: 'error',
      table: FINANCE_BILLING,
      location,
      columns,
      fetchTables: Rest_finance.getFinanceBilling,
      fetchDelete: (arg: any) => dispatch(deleteIdsBilling(arg)),
    });

  useEffect(() => {
    if (pathname === RouteNames.FINANCE_BILLING) {
      dispatch(resetStoreBilling());
      updateEffectDate();
      const rent = location?.state?.[FINANCE_BILLING];
      if (rent) {
        window.history.replaceState({ state: null }, document.title);
        if (rent?.edit) {
          dispatch(setOneBillingDate(rent.edit));
          setTypeForm(EDIT);
          setIsOpenDrawer(true);
          return;
        }
        setTypeForm(CREATE);
        dispatch(
          setOneBillingDate({
            ...defaultValuesBilling,
            ...rent,
            delete: false,
            edit: true,
          }),
        );

        setIsOpenDrawer(true);
        return;
      }
    }
    return history.replace(RouteNames.FINANCE_BILLING);
  }, [pathname]);

  const onRow = (row: IBilling) => {
    setTypeForm(VIEW);
    dispatch(setOneBillingDate(row));
    setIsOpenDrawer(true);
  };

  const handleShowEditForm = (row: IBilling) => {
    setTypeForm(EDIT);
    dispatch(setOneBillingDate(row));
    setIsOpenDrawer(true);
  };

  const onCloseDrawer = (res = false) => {
    setIsOpenDrawer(false);
    setTypeForm(CREATE);
    dispatch(setOneBillingDate(defaultValuesBilling));
  };

  const onCreate = () => {
    dispatch(setOneBillingDate(defaultValuesBilling));
    setTypeForm(CREATE);
    setIsOpenDrawer(true);
  };

  return (
    <>
      <Flex
        gap={20}
        justify={'space-between'}
        align={'flex-start'}
        style={{ width: '100%' }}
        className={'container'}
      >
        <Flex align={'center'} gap={10}>
          <div className={'drawer-title'}>
            {t('Заявки на выставление счета')}
          </div>
        </Flex>
        <Flex gap={20} align="center">
          <Course />
          <Button
            icon={<PlusOutlined />}
            style={{ background: '#05F', color: '#fff', minWidth: '32px' }}
            onClick={onCreate}
          />
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          {...optionsTable}
          onClickLink={onRow}
          editRow={handleShowEditForm}
          onRow={onRow}
          dataSource={dataTable}
          height={'calc(-162px + 100vh)'}
        />
        <CustomDrawer
          minWidth={1200}
          isHeight
          onClose={onCloseDrawer}
          open={isOpenDrawer}
        >
          <FormBilling
            isOpen={isOpenDrawer}
            typeForm={typeForm}
            initialValue={initialValue}
            onClose={onCloseDrawer}
          />
        </CustomDrawer>
      </Space>
    </>
  );
};

export default FinanceBillingTable;
