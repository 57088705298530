import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Rest_User } from "../services/rest_users";
import { IProfile, IUsers } from "../types/users";

export interface IForm {
  title?: string;
  initialValue?: IProfile;
  isOpen: boolean;
  isCopy?: boolean;
  isEdit?: boolean;
  typeForm: string;
  onClose: (res?: boolean) => void;
  onEdit?: () => void;
  onView?: () => void;
  width?: number;
  isActive?: boolean;
  isShowActive?: boolean;
}

interface IUsersState {
  user?: IUsers;
  usersData: Array<IUsers>;
  userInfoData: Array<IUsers>;
  auth: boolean;
  dataEntities: [];
  permissionsData?: [];
}

const initialState: IUsersState = {
  user: undefined,
  usersData: [],
  userInfoData: [],
  auth: false,
  dataEntities: [],
  permissionsData: [],
};
interface IGetUserPermPayload {
  userId: number;
  // 1) Добавили поле fetchMode, которое будем передавать дальше.
  entityId?: number | number[];
}
export const UserInfo = createAsyncThunk("UserInfo", async (payload: {}) => {
  return await Rest_User.userInfo(payload);
});
export const getUserPermissions = createAsyncThunk(
  "getUserPermissions",
  async (payload: IGetUserPermPayload) => {
    const { userId, entityId } = payload;
    console.log(payload);
    return await Rest_User.getUserPermissions(userId, entityId);
  }
);
export const refUserInfo = createAsyncThunk(
  "refUserInfo",
  async (payload: {}) => {
    const response: any = await Rest_User.refUserInfo(payload);
    return response;
  }
);
export const entitiesInfo = createAsyncThunk(
  "entitiesInfo",
  async (payload: {}) => {
    const response: any = await Rest_User.entitiesInfo(payload);
    return response;
  }
);
export const setUserActive = createAsyncThunk(
  "setUserActive",
  async (payload: { id: number; active: boolean; getActive?: boolean }) => {
    return await Rest_User.setUserActive(payload);
  }
);
export const updateUser = createAsyncThunk(
  "updateUser",
  async (payload: {
    id: number;
    avatar?: string | null;
    vacation?: boolean;
    travel?: boolean;
  }) => {
    return await Rest_User.updateUser(payload);
  }
);
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetStoreUsers(state) {
      state.auth = initialState.auth;
      state.user = initialState.user;
      state.usersData = initialState.usersData;
      state.dataEntities = initialState.dataEntities;
      state.userInfoData = initialState.userInfoData;
    },
    setAuth(state, action) {
      state.auth = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setUsersData(state, action) {
      state.usersData = action.payload?.filter(
        (item: any) => item?.id !== state.user?.id
      );
    },
    setUserPermissions(state, action) {
      state.permissionsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(UserInfo.fulfilled, (state, action) => {
      state.userInfoData = action.payload;
    });
    builder.addCase(refUserInfo.fulfilled, (state, action) => {
      state.userInfoData = action.payload;
    });
    builder.addCase(entitiesInfo.fulfilled, (state, action) => {
      state.dataEntities = action.payload;
    });
  },
});
export default usersSlice.reducer;
export const {
  setAuth,
  setUser,
  setUsersData,
  setUserPermissions,
  resetStoreUsers,
} = usersSlice.actions;
