import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Flex, Row, Space, Typography } from 'antd';
import Icon from '@ant-design/icons';
import { CopyBufferIconDefault, copyIcon } from '../../assets/image/svg';
import './style.scss';
import { RouteNames } from '../../router/names';
import { Link } from 'react-router-dom';
import { useLink } from '../../hooks';
import { TERMINAL } from '../../constants';
import CopyButton from '../../components/ui/CopyButton/CopyButton';
import IconButton from '../../components/ui/IconButton';
import PhoneText from '../../components/ui/PhoneText';
import { ITerminal } from '../../types/terminal';

interface Props {
  dataRow?: ITerminal;
  isCopy?: boolean;
  onClose: () => void;
  onEdit: () => void;
}

const FormCardModalTerminal: React.FC<Props> = ({
  dataRow,
  isCopy,
  onClose,
  onEdit,
}) => {
  const { t } = useTranslation();
  const link = useLink({ key: TERMINAL, id: dataRow?.id as number });

  return (
    <Flex
      vertical
      className={'modal-content'}
      style={{ padding: '5px 20px 20px 20px' }}
    >
      <Space direction={'vertical'}>
        <Flex justify={'space-between'} align={'center'}>
          <h2>{t('О терминале')}</h2>
          <Flex gap={20} align={'center'}>
            <Flex gap={5} align={'center'}>
              <IconButton iType="edit-form" onClick={onEdit} />
              {isCopy && (
                <CopyButton
                  text={link}
                  textSuccess={t('Ссылка скопирована')}
                  icon={<Icon component={CopyBufferIconDefault} />}
                />
              )}
            </Flex>
            <IconButton
              iType="close"
              onClick={onClose}
              style={{ color: 'currentcolor', outline: 'node', border: 'none' }}
            />
          </Flex>
        </Flex>
        <Divider style={{ marginTop: 0, marginBottom: 16 }} />
        <Row>
          <Col span={12}>
            <div className={'label-card-14'}>{t('NameTerminal')}</div>
            <div className={'text-card-14'}>{dataRow?.name ?? ''}</div>
          </Col>
          <Col span={12}>
            <div className={'label-card-14'}>{t('Parent')}</div>
            <div className={'text-card-14'}>
              {dataRow?.parent?.label ?? ''}
            </div>
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Row>
          <Col span={24}>
            <div className={'label-card-14'}>{t('Address')}</div>
          </Col>
          <Col span={24}>
            <div className={'text-card-14'}>{dataRow?.address_terminal}</div>
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Row>
          <Col span={24}>
            <div className={'label-card-14'}>{t('Contacts')}</div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className={'text-card-14'}>{t('Director')}</div>
          </Col>
          <Col span={12}>
            <Flex vertical gap={5}>
              <Flex gap={5}>
                {dataRow?.phone_director !== null && (
                  <>
                    <PhoneText text={dataRow?.phone_director ?? ''} />
                    <CopyButton
                      disabled={!dataRow?.phone_director}
                      text={dataRow?.phone_director ?? ''}
                      icon={<Icon component={copyIcon} />}
                    />
                  </>
                )}
              </Flex>
              {dataRow?.last_phone_director !== null && (
                <Flex vertical gap={5}>
                  {' '}
                  {dataRow?.last_phone_director?.map(
                    (item: any, index: number) => (
                      <Flex key={index} gap={5}>
                        <PhoneText text={item.add_phone_director} />
                        <CopyButton
                          disabled={!item.add_phone_director}
                          text={item.add_phone_director}
                          icon={<Icon component={copyIcon} />}
                        />
                      </Flex>
                    ),
                  )}
                </Flex>
              )}
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className={'text-card-14'}>{t('Dispatcher')}</div>
          </Col>
          <Col span={12}>
            <Flex vertical gap={5}>
              <Flex gap={5}>
                {dataRow?.phone_dispatcher !== null && (
                  <>
                    <PhoneText text={dataRow?.phone_dispatcher ?? ''} />
                    <CopyButton
                      disabled={!dataRow?.phone_dispatcher}
                      text={dataRow?.phone_dispatcher ?? ''}
                      icon={<Icon component={copyIcon} />}
                    />
                  </>
                )}
              </Flex>
              {dataRow?.last_phone_dispatcher !== null && (
                <Flex vertical gap={5}>
                  {' '}
                  {dataRow?.last_phone_dispatcher?.map(
                    (item: any, index: number) => (
                      <Flex key={index} gap={5}>
                        <PhoneText text={item.add_phone_dispatcher} />
                        <CopyButton
                          disabled={!item.add_phone_dispatcher}
                          text={item.add_phone_dispatcher}
                          icon={<Icon component={copyIcon} />}
                        />
                      </Flex>
                    ),
                  )}
                </Flex>
              )}
            </Flex>
          </Col>
        </Row>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Flex vertical gap={5}>
          <Col span={24}>
            <div className={'label-card-14'}>{t('Capacity')}</div>
          </Col>
          <Col span={24}>
            <div className={'text-card-14'}>{dataRow?.capacity}</div>
          </Col>
        </Flex>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Flex vertical gap={5}>
          <Col span={24}>
            <div className={'label-card-14'}>{t('Stock')}</div>
          </Col>
          <Col span={24}>
            <Flex gap={'10px 20px'} wrap>
              {dataRow?.stocks?.map((item: any, index: number) => (
                <div className={'text-card-14'} key={index}>
                  <Link
                    to={`${RouteNames.CONTAINERS}/?stock=${item?.label}&name=${item?.label}`}
                  >
                    {item?.label}
                  </Link>
                </div>
              ))}
            </Flex>
          </Col>
        </Flex>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Flex vertical gap={5}>
          <Col span={24}>
            <div className={'label-card-14'}>{t('Входящая ставка')}</div>
          </Col>
          <Col span={24}>
            <Flex gap={5}>
              <Typography.Paragraph
                className={'text-card-14'}
                ellipsis={{
                  rows: 1,
                  tooltip: {
                    placement: 'left',
                    title: dataRow?.currency_price ?? '',
                  },
                }}
                style={{ maxWidth: '350px', margin: 0 }}
              >
                {dataRow?.currency_price ?? ''}
              </Typography.Paragraph>
              <Typography.Paragraph
                className={'text-card-14'}
                style={{ margin: 0 }}
              >
                {dataRow?.currency?.name ?? ''}
              </Typography.Paragraph>
            </Flex>
          </Col>
        </Flex>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Flex vertical gap={5}>
          <Col span={24}>
            <div className={'label-card-14'}>{t('StockInstruction')}</div>
          </Col>
          <Col span={24}>
            <div className={'text-card-14'}>{dataRow?.stock_model?.text}</div>
          </Col>
        </Flex>
      </Space>
    </Flex>
  );
};

export default FormCardModalTerminal;
