import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Divider,
  Flex,
  Form,
  Modal,
  Popover,
  Row,
  Tag,
  Typography,
} from 'antd';
import { RootState, useStoreDispatch } from '../../store';
import {
  getActivityContainer,
  getHistoryContainer,
  updateDateBooking,
} from '../../store/containers';
import Icon from '@ant-design/icons';
import { blackCloseIcon, moreIcon } from '../../assets/image/svg';
import ListComponent from '../../components/ui/List/ListComponent';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../utils';
import moment from 'moment/moment';
import { IContainers, IContainersStatus } from '../../types/containers';
import FormModalComments from './formModalComments';
import FormModalHistory from './history/formModalHistory';
import { RangePickerProps } from 'antd/es/date-picker';
import ScrollList from '../../components/ui/ScrollList';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { RouteNames } from '../../router/names';
import { getSearchLink, openInNewTab } from '../../helpers/link-helper';
import { useLink, useParamsHistory } from '../../hooks';
import { CONTAINER, RELEASE, SALES_DEAL } from '../../constants';
import CopyButton from '../../components/ui/CopyButton/CopyButton';
import UploaderPreview from '../../components/ui/UploaderPreview';
import { getDateString } from '../../helpers/date-helpers';
import { Checkbox } from '../../components/ui/Checkbox/Checkbox';

const { Title, Link } = Typography;

interface Props {
  dataRowContainer?: IContainers;
  closeModalContainers?: any;
  dataComment?: any;
  activityContainer?: any;
  currentActivity?: number;
  pageActivity?: number;
  setActivityContainer?: (value: any) => void;
  totalTableActivity: number;
  isDisabled?: boolean;
  isCopy?: boolean;
  onEdit: () => void;
}

const FormModalContainer: React.FC<Props> = ({
  dataRowContainer,
  closeModalContainers,
  currentActivity,
  pageActivity,
  totalTableActivity,
  isDisabled,
  isCopy,
  onEdit,
  dataComment,
  activityContainer,
  setActivityContainer,
}) => {
  const link = useLink({ key: CONTAINER, id: dataRowContainer?.id as number });
  const dataStatusContainer = useSelector(
    (state: RootState) => state.containers.dataStatusContainer,
  );
  const { history } = useParamsHistory();
  const dispatch = useStoreDispatch();
  const { t, i18n } = useTranslation();
  const [status, setStatus] = useState<string>('Не оплачен');
  const [booking, setBooking] = useState(false);
  const [dateBooking, setDateBooking] = useState<string | string[]>('');
  const [extend, setExtend] = useState(false);
  const [newStatus, setNewStatus] = useState<boolean>(false);
  const [responsible, setResponsible] = useState<string>('');
  const [statusTerminal, setStatusTerminal] = useState<string>('');
  const [isModalOpenComment, setIsModalOpenComment] = useState<boolean>(false);
  const [parentDataComment, setParentDataComment] = useState<any[]>([]);
  const [checkedRepair, setCheckedRepair] = useState<boolean>(false);
  const [typeModalOpen, setTypeModalOpen] = useState<string>('');
  const tableRef = useRef<HTMLDivElement>(null);
  const [current, setCurrent] = useState<number>(1);
  const [lastItemReached, setLastItemReached] = useState<boolean>(false);
  let [page, setPage] = useState<number>(10);
  const [isMenu, setIsMenu] = useState(false);
  const [statusId, setStatusId] = useState<number | null>(null);

  useEffect(() => {
    const status =
      dataRowContainer?.status_id || dataRowContainer?.['status.id'];
    const name =
      dataКТКStatus.find(({ value }) => value === status)?.label || '';

    const fioResponsible = dataRowContainer?.responsible?.fio
      ? dataRowContainer.responsible.fio
      : dataRowContainer?.['responsible.fio']
      ? dataRowContainer['responsible.fio']
      : '';

    setStatusTerminal(name);
    setDateBooking(getDateString({ date: dataRowContainer?.booking_before }));
    setParentDataComment(dataComment);
    setResponsible(fioResponsible);
  }, [JSON.stringify(dataComment), dataRowContainer?.num_container]);

  const updateDate = useMemo(() => {
    return moment(dateBooking, 'DD.MM.YYYY').isValid()
      ? moment(dateBooking, 'DD.MM.YYYY').add(7, 'days').format('YYYY-MM-DD')
      : null;
  }, [dateBooking]);

  const dataКТКStatus = [
    {
      label: 'Хранение',
      value: 1,
      action: () => {
        setNewStatus(false);
        setStatusTerminal('Хранение');
        dispatch(
          updateDateBooking({
            status_id: 1,
            repair: false,
            id: dataRowContainer?.id,
            booking_before: null,
            responsible_id: Utils.getUserInfo().id,
            current: 0,
            page: pageActivity,
          }),
        );
        setDateBooking(moment().format('DD.MM.YYYY'));
      },
    },
    {
      label: 'Бронь',
      value: 35,
      action: () => {
        setNewStatus(false);
        setStatusTerminal('Бронь');
        dispatch(
          updateDateBooking({
            status_id: 35,
            repair: false,
            id: dataRowContainer?.id,
            booking_before: null,
            responsible_id: Utils.getUserInfo().id,
            current: 0,
            page: pageActivity,
          }),
        );
        setDateBooking(moment().format('DD.MM.YYYY'));
      },
    },
    {
      label: 'Аренда',
      value: 2,
      action: () => {
        setDateBooking(dateBooking);
        setNewStatus(true);
        setStatusTerminal('Аренда');
        dispatch(
          updateDateBooking({
            status_id: 2,
            repair: false,
            id: dataRowContainer?.id,
            booking_before: updateDate || null,
            responsible_id: Utils.getUserInfo().id,
            current: 0,
            page: pageActivity,
          }),
        );
      },
    },
    {
      label: 'Продажа',
      value: 3,
      action: () => {
        setNewStatus(true);
        setStatusTerminal('Продажа');
        dispatch(
          updateDateBooking({
            status_id: 3,
            repair: false,
            id: dataRowContainer?.id,
            booking_before: updateDate || null,
            responsible_id: Utils.getUserInfo().id,
            current: 0,
            page: pageActivity,
          }),
        );
      },
    },
    {
      label: 'Передислокация',
      value: 31,
      action: () => {
        setStatusTerminal('Передислокация');
        setNewStatus(true);
        dispatch(
          updateDateBooking({
            status_id: 31,
            repair: true,
            id: dataRowContainer?.id,
            booking_before: null,
            responsible_id: Utils.getUserInfo().id,
            current: 0,
            page: pageActivity,
          }),
        );
      },
    },
    {
      label: 'Субаренда',
      value: 32,
      action: () => {
        setStatusTerminal('Субаренда');
        setDateBooking(moment().format('DD.MM.YYYY'));
        setNewStatus(true);
        dispatch(
          updateDateBooking({
            status_id: 32,
            repair: false,
            id: dataRowContainer?.id,
            booking_before: updateDate || null,
            responsible_id: Utils.getUserInfo().id,
            current: 0,
            page: pageActivity,
          }),
        );
      },
    },
    {
      label: 'Эвакуация',
      value: 33,
      action: () => {
        setStatusTerminal('Эвакуация');
        setDateBooking(moment().format('DD.MM.YYYY'));
        setNewStatus(true);
        dispatch(
          updateDateBooking({
            status_id: 33,
            repair: false,
            id: dataRowContainer?.id,
            booking_before: updateDate || null,
            responsible_id: Utils.getUserInfo().id,
            current: 0,
            page: pageActivity,
          }),
        );
      },
    },
    {
      label: 'Внутрянка',
      value: 34,
      action: () => {
        setStatusTerminal('Внутрянка');
        setDateBooking(moment().format('DD.MM.YYYY'));
        setNewStatus(true);
        dispatch(
          updateDateBooking({
            status_id: 34,
            repair: false,
            id: dataRowContainer?.id,
            booking_before: updateDate || null,
            responsible_id: Utils.getUserInfo().id,
            current: 0,
            page: pageActivity,
          }),
        );
      },
    },
  ];

  const onSelectStatus = (id: any, opt: any) => {
    setStatusTerminal(opt?.label || '');
    setStatusId(id);
    if (opt?.action) {
      opt.action();
    }
    if (closeModalContainers) {
      closeModalContainers();
    }
  };

  const handlePlaceOrder = (payload: IContainersStatus) => {
    setStatusTerminal(payload.name);
    if (payload.name === 'Продажа') {
      setNewStatus(false);
      if (closeModalContainers) {
        closeModalContainers();
      }
      history.replace(RouteNames.SALES_DEAL, {
        [SALES_DEAL]: { containers: [dataRowContainer] },
      });
      return;
    }
    if (payload.name === 'Хранение') {
      setNewStatus(false);
      dispatch(
        updateDateBooking({
          status_id: payload?.id,
          id: dataRowContainer?.id,
          booking_before: null,
          responsible_id: Utils.getUserInfo().id,
          current: 0,
          page: pageActivity,
        }),
      );
      setDateBooking(moment().format('DD.MM.YYYY'));
    } else {
      setDateBooking(
        moment(dateBooking, 'DD.MM.YYYY').add(7, 'days').format('DD.MM.YYYY'),
      );
      setNewStatus(true);
      dispatch(
        updateDateBooking({
          status_id: payload.id,
          id: dataRowContainer?.id,
          booking_before: updateDate,
          responsible_id: Utils.getUserInfo().id,
          current: 0,
          page: pageActivity,
        }),
      );
    }
  };

  const handleStatus = (value: any) => {
    if (value === 'Новое бронирование') {
      setIsMenu(false);
      if (statusTerminal === 'Хранение') {
        setStatusTerminal('');
      }
      setResponsible(Utils.getUserInfo().fio);
      setNewStatus(true);
    }
    if (value === 'История изменений') {
      setIsMenu(false);
      setIsModalOpenComment(true);
      setTypeModalOpen('history');
      if (dataRowContainer?.id) {
        dispatch(getHistoryContainer(dataRowContainer.id));
      }
    }
  };

  const contentOrder = (
    <Flex vertical>
      {dataКТКStatus.map(({ value, label, action }) => (
        <Typography.Paragraph
          key={value}
          onClick={action}
          style={{ cursor: 'pointer' }}
        >
          {label}
        </Typography.Paragraph>
      ))}
    </Flex>
  );

  const contentStatus: JSX.Element = (
    <ListComponent
      arrayList={['Paid', 'NotPaid']}
      type={'array'}
      onClickArray={handleStatus}
    />
  );

  const containerStatusOrder: JSX.Element = (
    <>
      {!isDisabled && (
        <ListComponent
          arrayList={[
            'NewBooking',
            'PlaceOrder',
            'CancelReservation',
            'HistoryChanges',
            'ListContainers',
          ]}
          type={'array'}
          onClickArray={(val) => {
            handleStatus(val);
            setIsMenu(false);
          }}
        />
      )}
      {!isDisabled && (
        <div className={'list-ul'}>
          <div
            className="list-li"
            onClick={() => {
              setIsMenu(false);
              onEdit();
            }}
          >
            {t('Редактировать')}
          </div>
        </div>
      )}
      {isCopy && (
        <div className={'list-ul'}>
          <div className="list-li">
            <CopyButton
              textButton="Копировать ссылку"
              text={link}
              textSuccess={t('Ссылка скопирована')}
              onClick={() => {
                setIsMenu(false);
              }}
            />
          </div>
        </div>
      )}
    </>
  );

  const handleDateExtend: DatePickerProps['onChange'] = (
    date: any,
    dateString,
  ) => {
    dispatch(
      updateDateBooking({
        status_id:
          dataStatusContainer
            ?.filter((item) => item.name === 'Продление')
            .map((item) => item.id)[0] || 1,
        booking_before: dateString,
        id: dataRowContainer?.id,
        responsible_id: Utils.getUserInfo().id,
        current: 0,
        pageSize: pageActivity,
      }),
    );
    setDateBooking(getDateString({ date: dateString }));
    setExtend(false);
  };

  const handleCloseExtend = () => {
    setExtend(false);
  };

  const closeContainers = () => {
    closeModalContainers();
    setNewStatus(false);
    setCurrent(1);
    setPage(10);
    if (tableRef.current) {
      tableRef.current.scrollTo(0, 0);
    }
  };

  const handleComment = () => {
    setIsModalOpenComment(true);
    setTypeModalOpen('comment');
  };

  const handleCloseComment = () => {
    setIsModalOpenComment(false);
  };

  const formatFIO = (fullName: string) => {
    if (!fullName) return '';
    const [lastName, firstName, middleName] = fullName.split(' ');
    if (!lastName || !firstName || !middleName) return fullName; // обработка случая, когда имя неполное
    return `${lastName} ${firstName[0]}. ${middleName[0]}.`;
  };

  const updateParentDataComment = (newData: any, type: string) => {
    if (type === 'add') {
      setParentDataComment((prevData: any) => [newData, ...prevData]);
    } else {
      setParentDataComment(newData);
    }
  };

  const content = parentDataComment.length > 0 && (
    <>
      <div>
        {formatFIO(parentDataComment[0]?.['user.fio'])}{' '}
        {moment(parentDataComment[0]?.createdAt).format('DD.MM.YYYY H:mm')}
      </div>
      <div className={'box-popover'}>{parentDataComment[0]?.text}</div>
    </>
  );

  const checkRepair = (e: boolean) => {
    let statusId = 1;

    if (!e) {
      statusId =
        dataStatusContainer
          ?.filter((item) => item.name === 'В ремонте')
          .map((item) => item.id)[0] || 1;
    } else {
      statusId =
        dataStatusContainer
          ?.filter((item) => item.name === 'Вышел из ремонта')
          .map((item) => item.id)[0] || 1;
    }

    setCheckedRepair(e);
    dispatch(
      updateDateBooking({
        status_id: statusId,
        repair: e,
        id: dataRowContainer?.id,
        booking_before: updateDate || null,
        responsible_id: Utils.getUserInfo().id,
        current: 0,
        page: pageActivity,
      }),
    );
  };

  const closeModalHistory = () => {
    setIsModalOpenComment(false);
    setTypeModalOpen('');
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const today = moment().startOf('day');
    const limitDate = moment().add(7, 'days');
    return current && (current < today || current > limitDate);
  };

  const renderTag = (
    label: string,
    value: any,
    dateFormat = false,
    isStatus = false,
  ) => {
    const hasValue = Boolean(value);
    const displayValue = hasValue
      ? dateFormat
        ? moment(value).format('DD.MM.YYYY')
        : value
      : 'Отсутствует';

    const style = isStatus
      ? hasValue
        ? { color: '#13AA64', background: '#CEFBE7' }
        : { color: '#445371', background: '#EDEEF1' }
      : hasValue
      ? { color: '#0055FF', background: '#E6EEFF' }
      : { color: '#445371', background: '#EDEEF1' };

    return (
      <Flex vertical gap={4}>
        <Typography.Text className={'text-card-12'}>{label}</Typography.Text>
        <Tag bordered={false} style={style}>
          {displayValue}
        </Tag>
      </Flex>
    );
  };

  const paginationActivity = () => {
    dispatch(
      getActivityContainer({
        id: dataRowContainer?.id,
        current: (Number(currentActivity) - 1) * Number(pageActivity) + page,
        page: Number(pageActivity),
      }),
    )
      .then((response) => {
        if (response.payload.rows.length > 0) {
          setPage(+Number(pageActivity) + page);
          setCurrent(
            (Number(currentActivity) - 1) * Number(pageActivity) + page,
          );
          if (setActivityContainer) {
            setActivityContainer((prevContainer: any) => [
              ...prevContainer,
              ...response.payload.rows,
            ]);
          }
        }
        setLastItemReached(false);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div
      onClick={() => {
        if (isMenu) {
          setIsMenu(false);
        }
      }}
    >
      <Row className={classnames('modal-container', 'page-container')}>
        <Flex align={'center'} vertical={false} style={{ width: '100%' }}>
          <Col span={7}>
            <Title level={4} style={{ marginBottom: 0 }}>
              {dataRowContainer?.num_container}
            </Title>
          </Col>
          <Col span={15} className={'text-base'}>
            <Flex gap={12}>
              <div>{dataRowContainer?.type_container}</div>
              <div>|</div>
              <div>
                Собственник:{' '}
                {dataRowContainer?.contractors?.name_company ||
                  dataRowContainer?.['contractors.name_company'] ||
                  ''}
              </div>
              <div className={'line'}>|</div>
              <div>
                Терминал:{' '}
                {dataRowContainer?.terminal?.name ?? ''}
              </div>
            </Flex>
          </Col>
          <Col span={2}>
            <Flex gap={10} justify={'flex-end'} style={{ cursor: 'pointer' }}>
              <Popover
                placement="bottomRight"
                content={isCopy || !isDisabled ? containerStatusOrder : null}
                open={isMenu}
              >
                <Icon component={moreIcon} onClick={() => setIsMenu(true)} />
              </Popover>
              <Icon component={blackCloseIcon} onClick={closeContainers} />
            </Flex>
          </Col>
        </Flex>
      </Row>
      <Divider style={{ marginBottom: 0, marginTop: 8 }} />
      <Form autoComplete="off">
        <Flex className={'container-modal'} vertical gap={20}>
          <Row gutter={[16, 16]}>
            <Col>
              {renderTag('Состояние', dataRowContainer?.quality, false, true)}
            </Col>
            <Col>
              {renderTag('Дата прибытия', dataRowContainer?.arrival_date, true)}
            </Col>
            <Col>
              {renderTag('Дата убытия', dataRowContainer?.departure_date, true)}
            </Col>
            <Col>
              {renderTag('Входящая ставка', dataRowContainer?.currency_price)}
            </Col>
            <Col>
              {dataRowContainer?.deal
                ? renderTag(
                    'Продажа',
                    <Typography.Link
                      onClick={() =>
                        openInNewTab(
                          getSearchLink({
                            key: SALES_DEAL,
                            id: dataRowContainer.deal.id,
                          }),
                        )
                      }
                    >
                      {dataRowContainer.deal?.id_deal || ''}
                    </Typography.Link>,
                  )
                : null}
            </Col>
            <Col>
              {dataRowContainer?.release
                ? renderTag(
                    'Релиз',
                    <Typography.Link
                      onClick={() =>
                        openInNewTab(
                          getSearchLink({
                            key: RELEASE,
                            id: dataRowContainer.release?.id,
                          }),
                        )
                      }
                    >
                      {dataRowContainer.release?.release_id || ''}
                    </Typography.Link>,
                  )
                : null}
            </Col>
            <Col>{renderTag('Сток', dataRowContainer?.terminalStock?.label, false)}</Col>
          </Row>
          <Flex
            style={{ marginBottom: 24, width: '100%' }}
            className={
              dataRowContainer?.delete ? 'disabled-div' : 'enabled-div'
            }
          >
            <Col span={8}>
              <Form.Item name={'status'}>
                <Flex vertical gap={9}>
                  <Title level={5} className={'text-base sm'}>
                    <Flex gap={14}>
                      <div>{t('current_status')}</div>
                      <Popover placement="bottom" content={contentOrder}>
                        <div className={'text-base sm blue'}>
                          {statusTerminal || t('Booking')}
                        </div>
                      </Popover>
                    </Flex>
                  </Title>
                  <Title
                    level={5}
                    className={'text-base sm'}
                    style={{ margin: '0 0 10px' }}
                  >
                    <Flex gap={14}>
                      <div>Статус оплаты:</div>
                      {isDisabled ? (
                        <div className={'text-base sm blue'}>{status}</div>
                      ) : (
                        <Popover
                          placement="bottom"
                          content={contentStatus}
                          trigger="click"
                        >
                          <div className={'text-base sm blue'}>{status}</div>
                        </Popover>
                      )}
                    </Flex>
                  </Title>
                </Flex>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Flex vertical gap={9}>
                <Row gutter={[10, 12]}>
                  <Col span={8}>
                    <Flex gap={9} align="baseline">
                      <Title level={5} className={'text-base sm'}>
                        <div style={{ whiteSpace: 'nowrap', minWidth: '60px' }}>
                          Бронь до:
                        </div>
                      </Title>
                      {statusTerminal !== 'Хранение' ? (
                        <Link
                          onClick={() => setBooking(!booking)}
                          disabled={isDisabled}
                        >
                          <div className={'text-base sm red'}>
                            {dateBooking}
                          </div>
                        </Link>
                      ) : (
                        <Link
                          onClick={() => setBooking(!booking)}
                          disabled={!newStatus || isDisabled}
                        >
                          <div className={'text-base sm'}>{dateBooking}</div>
                        </Link>
                      )}
                    </Flex>
                  </Col>
                  <Flex gap={9} align="baseline">
                    {statusTerminal !== 'Хранение' && !newStatus ? (
                      <Link
                        onClick={() => setExtend(!extend)}
                        disabled={isDisabled}
                      >
                        <div className={'text-base sm blue'}>Продлить</div>
                      </Link>
                    ) : (
                      <Link
                        onClick={() => setExtend(!extend)}
                        disabled={!newStatus || isDisabled}
                      >
                        <div className={'text-base sm'}>Продлить</div>
                      </Link>
                    )}

                    <div style={{ position: 'relative' }}>
                      <DatePicker
                        disabled={isDisabled}
                        disabledDate={disabledDate}
                        open={extend}
                        onOpenChange={handleCloseExtend}
                        style={{
                          visibility: 'hidden',
                          bottom: 35,
                          position: 'absolute',
                        }}
                        onChange={handleDateExtend}
                      />
                    </div>
                    <Checkbox
                      className={'text-base sm'}
                      isChoice={!!dataRowContainer?.repair}
                      onChange={checkRepair}
                    >
                      В ремонте
                    </Checkbox>
                  </Flex>
                </Row>
                <Row gutter={[10, 12]}>
                  <Col span={8}>
                    <Title level={5} className={'text-base sm'}>
                      <div style={{ whiteSpace: 'nowrap' }}>Ответственный:</div>
                    </Title>
                  </Col>
                  <Title level={5} className={'text-base sm'}>
                    <div
                      style={{ whiteSpace: 'nowrap' }}
                      className={'text-base sm blue'}
                    >
                      {responsible}
                    </div>
                  </Title>
                </Row>
              </Flex>
            </Col>
          </Flex>
          <Flex gap={22} vertical>
            <Flex
              className={'container-photo all-custom-v-scroll'}
              style={{ padding: '0 12px' }}
            >
              <UploaderPreview
                isOpen
                isView
                ids={dataRowContainer?.ids_photo}
              />
              {!dataRowContainer?.ids_photo?.length && (
                <Typography.Paragraph
                  style={{ fontSize: '20px', margin: 'auto' }}
                >
                  {t('Нет изображений')}
                </Typography.Paragraph>
              )}
            </Flex>
            <Flex gap={12} vertical>
              <ScrollList
                setLastItemReached={setLastItemReached}
                lastItemReached={lastItemReached}
                totalTableActivity={totalTableActivity}
                paginationActivity={paginationActivity}
                tableRef={tableRef}
                height={101}
                data={activityContainer}
              >
                {activityContainer?.map((item: any, i: number) => (
                  <div key={i} className="table-row">
                    <div className="table-cell">
                      {moment(item?.created_at).format('DD.MM.YY H:mm')}
                    </div>
                    <div className="table-cell">{item?.status?.name}</div>
                    <div className="table-cell">{item?.user?.fio}</div>
                  </div>
                ))}
              </ScrollList>
              <div className={'box-container'}>
                <div className={'text-base xs'}>Перемещения</div>
              </div>
            </Flex>
          </Flex>
        </Flex>
        <Flex justify={'flex-start'} style={{ width: '100%' }}>
          <Popover placement="topLeft" content={content} arrow={false}>
            <Button
              type={'text'}
              style={{ width: 151, color: '#05F' }}
              onClick={handleComment}
              disabled={isDisabled}
            >
              Комментарий
            </Button>
          </Popover>
        </Flex>
      </Form>
      <Modal
        className="page-container"
        open={isModalOpenComment}
        footer={null}
        closable={typeModalOpen === 'comment'}
        width={typeModalOpen === 'comment' ? 828 : '80%'}
        centered
        onCancel={handleCloseComment}
        title={typeModalOpen === 'comment' && 'Комментарий'}
      >
        {typeModalOpen === 'comment' ? (
          <FormModalComments
            rowId={dataRowContainer?.id}
            dataComment={dataComment}
            updateParentDataComment={updateParentDataComment}
          />
        ) : (
          <FormModalHistory
            dataRowContainer={dataRowContainer}
            activityContainer={activityContainer}
            closeModalHistory={closeModalHistory}
          />
        )}
      </Modal>
    </div>
  );
};

export default FormModalContainer;
