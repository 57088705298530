import { useEffect, useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Flex, Layout, Typography } from 'antd';

import { delay } from 'lodash';
import Icon, { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import Headers from '../Header';
import Menus from '../Menu/Menu';
import socket from '../../socket';
import { TOK_EN, Utils } from '../../utils';
import style from './style.module.scss';
import { RouteNames } from '../../router/names';
import { resetStoreContact, setSourceContact } from '../../store/contact';
import UsersPanel from '../UsersPanel/UsersPanel';
import { resetStoreContractor, setFindMyCompany } from '../../store/contractors';
import { RootState, useStoreDispatch } from '../../store';
import { resetStoreUsers, setUser, setUserActive } from '../../store/users';
import { resetStoreTerminal } from '../../store/terminals';
import TooltipButton from '../ui/TooltipButton/TooltipButton';
import { ExitIconDefault } from '../../assets/image/svg';
import { resetStoreContainer, setStatusContainer } from '../../store/containers';
import { setDrawer } from '../../store/store';
import { resetStoreTypes, setTypes } from '../../store/types';
import { useParamsHistory } from '../../hooks';
import { Rest_types } from '../../services/rest_types';
import useModalMessage from '../../hooks/useModalMessage';
import Profile from '../Profile';
import { IUsers } from '../../types/users';
import Notification from '../Notification';
import { resetStoreAgreement } from '../../store/agreement';
import { resetStoreChat } from '../../store/chat';
import { resetStoreFilters } from '../../store/filters';
import { resetStoreFinance } from '../../store/finance';
import { resetStoreMenu } from '../../store/menu';
import { resetStorePark } from '../../store/park';
import { resetStoreRelease } from '../../store/releases';
import { resetStoreRent } from '../../store/rent';
import { resetStoreSale } from '../../store/sales';

const { Sider, Content } = Layout;

export const Layouts = (props: any) => {
  const message = useModalMessage();
  const { t } = useTranslation();
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const isAuth: boolean = useSelector((state: RootState) => state.users.auth);
  const isDrawer: boolean = useSelector(
    (state: RootState) => state.stores.isDrawer,
  );
  const dataMenu: any = useSelector(
    (state: RootState) => state.stores.dataMenu,
  );

  const profile: IUsers | undefined = useSelector(
    (state: RootState) => state.users.user,
  );

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (profile) {
      if (profile?.id) {
        dispatch(setUserActive({ active: true, id: profile.id }));
      }

      if (sessionStorage.getItem('message')) return;

      const userName = !profile?.name
        ? 'Гость'
        : `${profile.name} ${profile?.last_name || ''}`;

      if (profile?.name) {
        sessionStorage.setItem('message', 'Hello!');
        message.success(`Добро пожаловать! ${userName}`, 2);
      }
    }
    const getErrorMessage = () => {
      message.error(
        <Typography.Text type="danger">Ошибка соединения!</Typography.Text>,
        0,
      );
    };
    socket.on('disconnect', (err) => {
      setIsDisabled(true);
      getErrorMessage();
    });
    socket.on('connect', () => {
      setIsDisabled(false);
      message.destroy();
      if (profile) {
        dispatch(setUserActive({ active: true, id: profile.id }));
        if (sessionStorage.getItem('message')) return;
        const userName = !profile?.name
          ? 'Гость'
          : `${profile.name} ${profile?.last_name || ''}`;
        if (profile?.name) {
          sessionStorage.setItem('message', 'Hello!');
          message.success(`Добро пожаловать! ${userName}`, 2);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (Utils.getUserInfo()) {
      if (!profile) {
        dispatch(setUser(Utils.getUserInfo()));
        return;
      }
      if (profile?.id !== Utils.getUserInfo()?.id || Utils.getStorageCrypt(TOK_EN)?.email !== profile?.email) {
        message.error('Ошибка проверки пользователя', 2);
        exit();
        return;
      }
      dispatch(setUserActive({ active: true, id: Utils.getUserInfo()?.id }));
    } else {
      message.error('Ошибка проверки пользователя', 2);
      exit();
    }
  }, [isAuth, pathname]);

  useEffect(() => {
    Rest_types.getTypes()
      .then((res) => {
        const response = res?.data;
        // HACK: пока все типы записываются по одному до удаления лишних socket, потом можно в один метод перенести
        dispatch(setSourceContact(response?.arSourceContact));
        dispatch(setStatusContainer(response?.arStatusContainer));
        dispatch(setFindMyCompany(response?.arFindMyCompany));
        dispatch(setTypes(response));
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      if (profile?.id) {
        dispatch(setUserActive({ active: false, id: profile.id }));
        resetStore();
      }
    };
  }, []);

  const clearCacheData = () => {
    caches?.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
  };

  const resetStore = () => {
    dispatch(resetStoreUsers());
    dispatch(resetStoreAgreement());
    dispatch(resetStoreChat());
    dispatch(resetStoreContact());
    dispatch(resetStoreContainer());
    dispatch(resetStoreContractor());
    dispatch(resetStoreFilters());
    dispatch(resetStoreFinance());
    dispatch(resetStoreMenu());
    dispatch(resetStorePark());
    dispatch(resetStoreRelease());
    dispatch(resetStoreSale());
    dispatch(resetStoreRent());
    dispatch(resetStoreTerminal());
    dispatch(resetStoreTypes());
  }


  const exit = () => {
    if (profile?.id || Utils.getUserInfo()?.id) {
      const id = profile?.id || Utils.getUserInfo()?.id;
      dispatch(setUserActive({ active: false, id }));
    }
    resetStore();
    delay(() => {
      Utils.clearFullStorage();
      history.push(RouteNames.LOGIN);
      clearCacheData();
    }, 0);
  };

  return (
    <Layout
      className={classnames(style.layout, {
        [style.layout_disabled]: isDisabled,
      })}
    >
      <Sider
        trigger={null}
        collapsible
        collapsed={isDrawer}
        collapsedWidth={60}
        className={'sider'}
        width={252}
      >
        <Flex justify="space-between" align="center" className={'sider avatar'}>
          <Flex
            justify="space-between"
            align="center"
            gap={10}
            style={
              !isDrawer
                ? { marginLeft: 8, marginTop: 8, marginBottom: 13, flex: 1 }
                : {
                    marginTop: 8,
                    marginBottom: 7,
                    width: '100%',
                    justifyContent: 'center',
                  }
            }
          >
            <Profile user={profile} isDrawer={isDrawer} />
            {!isDrawer && (
              <Button
                type="text"
                style={{ marginRight: 10 }}
                icon={isDrawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => dispatch(setDrawer(true))}
              />
            )}
          </Flex>
        </Flex>
        <Notification isShow={isDrawer} />
        <Menus
          data={dataMenu}
          isContact
          isTerm
          isCompanys
          isStaff
          isTerminalTable
          isContainers
          isListTerminals
        />
        <Divider style={{ marginTop: 6, marginBottom: 6 }} />
        <Flex
          className={classnames(style.layout__menu, {
            [style.layout__menu_icon]: isDrawer,
          })}
          justify={'flex-start'}
          align={'center'}
          style={{ cursor: 'pointer' }}
          gap={8}
        >
          <TooltipButton
            propsTooltip={{
              title: !isDrawer ? null : t('Выход'),
              placement: 'right',
            }}
            propsButton={{
              type: 'text',
              icon: (
                <Icon
                  className={style.layout__icon}
                  component={ExitIconDefault}
                />
              ),
              iconPosition: 'start',
              className: style.layout__link,
              onClick: exit,
            }}
          >
            {!isDrawer ? t('Выход') : null}
          </TooltipButton>
        </Flex>
      </Sider>
      <Layout>
        <Flex
          justify={'space-between'}
          align={'center'}
          style={{ width: '100%' }}
          gap={8}
        >
          <Headers setCollapsed={setCollapsed} collapsed={collapsed} />
        </Flex>
        <Content className={'content'}>{props.children}</Content>
      </Layout>
      <UsersPanel />
    </Layout>
  );
};
