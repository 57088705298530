import { useEffect, useMemo, useState } from "react";

import { Flex, Tabs, TabsProps, Typography } from "antd";

import Contacts from "../contacts/Contacts";
import { RouteNames } from "../../router/names";
import Contractors from "../contractors/Contractors";
import { useBreadcrumb, useMenu, useParamsHistory } from "../../hooks";
import {
  COMPANY,
  CONTACT,
  crmNames,
  SALES_DEAL,
  REQUESTS,
  INTEGRATION,
  AGREEMENT,
} from "../../constants";

import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import AgreementsCRM from "../agreements";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { IUsers } from "../../types/users";
import { setUserPermissions } from "../../store/users";
import { getUserPermissions } from "../../store/users";
import { useStoreDispatch } from "../../store";
import { Utils } from "../../utils";
import socket from "../../socket";

const { Title } = Typography;

export const CrmContainers = () => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { history, location, id } = useParamsHistory();
  const [activeKey, setActiveKey] = useState("");
  const [activeTitle, setActiveTitle] = useState("");
  const [currentUser, setCurrentUser] = useState(Utils.getUserInfo().id);
  const [rightsAgreementsView, setRightsAgreementsView] = useState(false);
  const [rightsContractorsView, setRightsContractorsView] = useState(false);
  const [rightsContactsView, setRightsContactsView] = useState(false);

  const permissionsData: any = useSelector(
    (state: RootState) => state.users.permissionsData
  );

  const profile: IUsers | undefined = useSelector(
    (state: RootState) => state.users.user
  );

  useBreadcrumb(["CRM", activeTitle]);
  useMenu({ activeKeys: [RouteNames.CRM] });

  useEffect(() => {
    const fetchPermissions = () => {
      dispatch(getUserPermissions({ userId: currentUser, entityId: [1, 2, 3] }))
        .unwrap()
        .then((data) => {
          setUserPermissions(data);
          console.log(
            "Дата из getUserPermissions в rightsAgreementsView =>",
            data
          );
          setRightsAgreementsView(
            data["agreements.status_view"] === true ||
              data["agreements.group_status_view"] === true
          );
          setRightsContractorsView(
            data["contractors.status_view"] === true ||
              data["contractors.group_status_view"] === true
          );
          setRightsContactsView(
            data["contacts.status_view"] === true ||
              data["contacts.group_status_view"] === true
          );
        })
        .catch((err) => console.log(err));
    };

    fetchPermissions();

    socket.on("res-update-group-entities", (data) => {
      dispatch(setUserPermissions(data));
    });
    socket.on("res-update-entities", (data) => {
      dispatch(setUserPermissions(data));
    });

    return () => {
      socket.off("res-update-group-entities");
      socket.off("res-update-entities");
    };
  }, [dispatch, currentUser, permissionsData]);

  const items: TabsProps["items"] = useMemo(
    () => [
      // {
      //   key: AGREEMENT,
      //   label: t(crmNames[AGREEMENT]),
      //   children: (
      //     <AgreementsCRM title={t(crmNames[AGREEMENT])} activeKey={activeKey} />
      //   ),
      // },
      ...(rightsAgreementsView
        ? [
            {
              key: AGREEMENT,
              label: t(crmNames[AGREEMENT]),
              children: (
                <AgreementsCRM
                  title={t(crmNames[AGREEMENT])}
                  activeKey={activeKey}
                />
              ),
            },
          ]
        : []),
      { key: SALES_DEAL, label: t(crmNames[SALES_DEAL]), children: "Сделки" },
      ...(rightsContractorsView
        ? [
            {
              key: COMPANY,
              label: t(crmNames[COMPANY]),
              children: (
                <Contractors
                  title={t(crmNames[COMPANY])}
                  activeKey={activeKey}
                />
              ),
            },
          ]
        : []),
      ...(rightsContactsView
        ? [
            {
              key: CONTACT,
              label: t(crmNames[CONTACT]),
              children: (
                <Contacts title={t(crmNames[CONTACT])} activeKey={activeKey} />
              ),
            },
          ]
        : []),
      {
        key: INTEGRATION,
        label: t(crmNames[INTEGRATION]),
        children: "Интеграции",
      },
    ],
    [
      activeKey,
      t,
      rightsAgreementsView,
      rightsContractorsView,
      rightsContactsView,
    ]
  );

  const handleClickTab = (key: string) => {
    setActiveKey(key);
    setActiveTitle(crmNames[key]);
    history.replace(`${RouteNames.CRM}/${key}`);
  };

  useEffect(() => {
    switch (id) {
      case AGREEMENT:
        setActiveKey(AGREEMENT);
        setActiveTitle(crmNames[AGREEMENT]);
        return;
      case SALES_DEAL:
        setActiveKey(SALES_DEAL);
        setActiveTitle(crmNames[SALES_DEAL]);
        return;
      case REQUESTS:
        setActiveKey(REQUESTS);
        setActiveTitle(crmNames[REQUESTS]);
        return;
      case CONTACT:
        setActiveKey(CONTACT);
        setActiveTitle(crmNames[CONTACT]);
        return;
      case COMPANY:
        setActiveKey(COMPANY);
        setActiveTitle(crmNames[COMPANY]);
        return;
      case INTEGRATION:
        setActiveKey(INTEGRATION);
        setActiveTitle(crmNames[INTEGRATION]);
        return;
      default:
        setActiveKey(COMPANY);
        setActiveTitle(crmNames[COMPANY]);
    }
  }, [id]);

  return (
    <Flex style={{ width: "100%" }} vertical className={style["crm"]}>
      {/* <Title level={2} style={{ padding: '20px 18px' }}>
      {profile?.name ? `${t('Доброе утро')} ${profile.name}!` : ''}
      </Title> */}
      <Tabs
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        onChange={handleClickTab}
        items={items}
        style={{ width: "100%", padding: "20px 0" }}
        indicator={{ size: (origin: any) => origin - 100, align: "center" }}
      />
    </Flex>
  );
};
