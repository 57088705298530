import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Flex, Typography } from 'antd';

import Icon from '@ant-design/icons';

import ITabs from '../ui/Tabs';
import { useLink } from '../../hooks';
import style from './style.module.scss';
import CustomDate from '../CustomDate';
import { ITab } from '../ui/Tabs/Tabs';
import { IForm, updateUser } from '../../store/users';
import IconButton from '../ui/IconButton';
import {
  AvatarProfile,
  ProfileCalendar,
  ProfileStatus,
  ProfileTape,
} from './components';
import { Checkbox } from '../ui/Checkbox/Checkbox';
import CopyButton from '../ui/CopyButton/CopyButton';
import { PROFILE_USER } from '../../constants';
import { EDIT } from '../../helpers/string-helpers';
import { CopyBufferIconDefault } from '../../assets/image/svg';
import {
  BUSINESS_TRIP,
  statusIcons,
  TAB_CALENDAR,
  TAB_TAPE,
  TAB_TASKS,
  VACATION,
} from './constants';
import ViewRow from '../ui/List/ViewRow/ViewRow';
import { useSelector } from 'react-redux';
import { RootState, useStoreDispatch } from '../../store';
import { IUsers } from '../../types/users';
import { ProfileTasks } from './components/ProfileTasks/ProfileTasks';
import UploaderPreview from '../ui/UploaderPreview';

export const ViewProfile: React.FC<IForm> = ({
  initialValue,
  isCopy,
  isEdit,
  title,
  width,
  isOpen,
  isActive,
  isShowActive,
  typeForm,
  onClose,
  onEdit,
}) => {
  const user: IUsers | undefined = useSelector(
    (state: RootState) => state.users.user,
  );
  const link = useLink({ key: PROFILE_USER, id: initialValue?.id as number });
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  const [icons, setIcons] = useState<any[]>([]);

  const [activeKey, setActiveKey] = useState(() => TAB_TASKS);

   const [isModalFiles, setIsModalFiles] = useState<boolean>(false);

   const [isFiles, setIsFiles] = useState<boolean>(false);

   const [avatar, setAvatar] = useState<any>(null);

  useEffect(() => {
    setAvatar(initialValue?.avatar ?? '');
    setIcons(
      initialValue?.travel || initialValue?.vacation
        ? [statusIcons[initialValue?.vacation ? VACATION : BUSINESS_TRIP]]
        : [],
    );
  }, [isOpen, typeForm, initialValue?.travel, initialValue?.vacation]);

  const changeClickTab = (key: string) => {
    setActiveKey(key);
  };

  const tabs: ITab[] = useMemo(
    () => [
      {
        key: TAB_CALENDAR,
        label: 'Календарь',
        disabled: true,
        content: <ProfileCalendar activeKey={activeKey} />,
      },
      {
        key: TAB_TASKS,
        label: 'Мои задачи',
        forceRender: false,
        content: <ProfileTasks activeKey={activeKey} initialValue={initialValue} width={width} />,
      },
      {
        key: TAB_TAPE,
        label: 'Лента',
        disabled: true,
        content: <ProfileTape activeKey={activeKey} />,
      },
    ],
    [],
  );

  const openModalFile = () => {
    setIsFiles(true);
    setIsModalFiles(true);
  };

  const closeModalFile = () => {
    setIsModalFiles(false);
    setIsFiles(false);
    setAvatar(initialValue?.avatar ?? '');
  };

  const closeSubmitAvatar = () => {
    setIsModalFiles(false);
    setIsFiles(false);
    if (initialValue?.id) {
      const result = {id: initialValue.id, vacation: initialValue?.vacation, travel: initialValue?.travel, avatar: avatar ?? ''};
      dispatch(updateUser(result));
    }
  };
  const toggleStatus = ({name}: {name: 'vacation' | 'travel'}) => (bol: boolean) => {
    if (initialValue?.id) {
      const result = {id: initialValue.id, vacation: initialValue?.vacation, travel: initialValue?.travel, avatar: initialValue.avatar};
      switch(name) {
        case 'vacation':
          if (bol) {
            result.vacation = true;
            result.travel = false;
            dispatch(updateUser(result));
            return;
          }
          result.vacation = bol;
          dispatch(updateUser(result));
          return;
        case 'travel':
          if (bol) {
            result.travel = true;
            result.vacation = false;
            dispatch(updateUser(result));
            return;
          }
          result.travel = bol;
          dispatch(updateUser(result));
          return;
      }
    }
  };
  const handlerSaveFileString = (url: string) => {
    setAvatar(url);
  }

  return (
    <div className={style['form']}>
      <Flex vertical gap={24}>
        <Flex vertical gap={40}>
          <Flex justify="space-between" align="center">
            <Flex gap={20} align="center" style={{ marginTop: 15 }}>
              <Typography.Title level={2} className={style.title}>
                {title}
              </Typography.Title>
              <ProfileStatus isShowActive={isShowActive} isActive={isActive || user?.id === initialValue?.id} icons={icons} />
            </Flex>
            <Flex gap={5} align={'center'}>
              {isEdit && (
                <IconButton
                  disabled={false}
                  hidden={!isEdit}
                  iType={`${EDIT}-form`}
                  onClick={onEdit}
                />
              )}
              {initialValue?.id && isCopy && (
                <CopyButton
                  text={link}
                  textSuccess={t('Ссылка скопирована')}
                  icon={<Icon component={CopyBufferIconDefault} />}
                />
              )}
            </Flex>
          </Flex>
          <Flex gap={5} vertical>
            <Typography.Title level={3} className={style['subtitle']}>
              {t('Контактная информация')}
            </Typography.Title>
            <Divider style={{ margin: 0 }} />
          </Flex>
        </Flex>
        <Flex gap={30}>
          <div className={style['avatar-block']}>
                {isModalFiles && initialValue?.id === user?.id ? (
                          <>
                            <div className={style['avatar-block__buttons']}>
                              <IconButton
                                className={style['avatar-block__button']}
                                onClick={closeSubmitAvatar}
                                iType='check'
                              />
                              <IconButton
                                className={style['avatar-avatar-block__button']}
                                onClick={closeModalFile}
                                iType="close"
                              />
                            </div>
                            <UploaderPreview
                              isOpen={isFiles}
                              fileString={isFiles ? initialValue?.avatar : ''}
                              size={2}
                              limit={1}
                              handlerSaveFileString={handlerSaveFileString}
                            />
                          </>
                        ) : (
                          <>
                            <AvatarProfile avatar={initialValue?.avatar} size={90} title={initialValue?.fio} />
                            <IconButton
                              hidden={initialValue?.id !== user?.id}
                              className={style['avatar-block__edit']}
                              onClick={openModalFile}
                              iType="edit"
                            />
                          </>
                        )}
          </div>
          <Flex gap={20} flex={1}>
            <Col flex={1} style={{ maxWidth: '33%' }}>
              <Flex vertical gap={15}>
                <ViewRow
                  className={style['view-row']}
                  title={t('Должность')}
                  subtitle={initialValue?.position}
                />
                <ViewRow
                  className={style['view-row']}
                  title={t('Руководитель')}
                  subtitle={initialValue?.director}
                />
              </Flex>
            </Col>
            <Col flex={1} style={{ maxWidth: '33%' }}>
              <Flex vertical gap={15}>
                <ViewRow
                  className={style['view-row']}
                  title={t('Email')}
                  subtitle={initialValue?.email}
                />
                <ViewRow
                  className={style['view-row']}
                  title={t('Отдел')}
                  subtitle={initialValue?.department}
                />
              </Flex>
            </Col>
            <Col flex={1}>
              <Flex vertical gap={15}>
                <ViewRow
                  className={style['view-row']}
                  title={t('Локальное время')}
                >
                  <CustomDate format="HH:mm" duration={60000} />
                </ViewRow>
                <ViewRow className={style['view-row']} title={t('Статус')}>
                  <Flex
                    justify="flex-start"
                    align="center"
                    gap={30}
                    style={{ minHeight: '32px' }}
                  >
                    <Checkbox isChoice={initialValue?.vacation} customChange={toggleStatus({name: 'vacation'})} disabled={initialValue?.id !== user?.id}>
                      <Typography.Text className={style.checkboxes__label}>
                        {t('Отпуск')}
                      </Typography.Text>
                    </Checkbox>
                    <Checkbox isChoice={initialValue?.travel} customChange={toggleStatus({name: 'travel'})} disabled={initialValue?.id !== user?.id}>
                      <Typography.Text className={style.checkboxes__label}>
                        {t('Командировка')}
                      </Typography.Text>
                    </Checkbox>
                  </Flex>
                </ViewRow>
              </Flex>
            </Col>
          </Flex>
        </Flex>

        <ITabs
          className={style['form__tab']}
          isErrorTabs
          activeKey={activeKey}
          tabs={tabs}
          onChange={changeClickTab}
        />
      </Flex>

      <Flex
        justify={'end'}
        style={{
          width: '100%',
          margin: 'auto 30px 10px auto',
          padding: '0 10px',
        }}
      >
        <Button
          onClick={() => onClose()}
          type={'primary'}
          style={{ maxWidth: '290px', minWidth: '290px' }}
          disabled={false}
        >
          {t('Закрыть')}
        </Button>
      </Flex>
    </div>
  );
};
