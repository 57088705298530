import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { privateRoutes, publicRoutes } from '../router';
import { RootState } from '../store';
import Container from './Layouts/Container';

const AppRouter = () => {
  const isAuth = useSelector((state: RootState) => state.users.auth);
  const routes = isAuth ? privateRoutes : publicRoutes;
  return isAuth ? (
    <Container>
      <Switch>
        {privateRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </Switch>
    </Container>
  ) : (
    <Switch>
      {publicRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
};

export default AppRouter;
