import React, { useCallback, useEffect, useState } from 'react';

import { delay } from 'lodash';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AutoComplete,
  Button,
  Col,
  Flex,
  Form,
  Input,
  Select,
  Typography,
} from 'antd';

import { Utils } from '../../../utils';
import style from '../style.module.scss';
import { AGREEMENT, messagesModal } from '../../../constants';
import { fileTypes } from '../../../helpers/input-helpers';
import { RootState, useStoreDispatch } from '../../../store';
import { FormDatePicker, FormInputNumber } from '../../../components/ui/Form';
import UploaderDocsUnique from '../../../components/ui/UploaderDocsUnique';
import {
  filterListId,
  mapperListCompany,
  mapperSelect,
} from '../../../helpers/mappers-helpers';
import {
  disableDateAfter,
  getDateSubmitString,
} from '../../../helpers/date-helpers';
import {
  defaultAgreement,
  IAgreement,
  IForm,
  setAgreement,
} from '../../../store/agreement';
import ModalConfirm from '../../../components/ui/ModalConfirm/ModalConfirm';
import { findCompany } from '../../../store/contractors';
import TooltipButton from '../../../components/ui/TooltipButton/TooltipButton';
import { useLink } from '../../../hooks';
import IconButton from '../../../components/ui/IconButton';
import CopyButton from '../../../components/ui/CopyButton/CopyButton';
import Icon from '@ant-design/icons';
import { CopyBufferIconDefault } from '../../../assets/image/svg';
import { submitFilesForm } from '../../../helpers/file-helpers';
import { IFile } from '../../../components/ui/UploaderDocsUnique/UploaderDocsUnique';

export const FormAgreements: React.FC<IForm> = ({
  isCopy,
  isOpen,
  typeForm,
  initialValue,
  onClose,
  onEdit,
  title = 'Создать договор',
}) => {
  const link = useLink({ key: AGREEMENT, id: initialValue?.id as number });
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useStoreDispatch();

  const [confirmation, setConfirmation] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);

  const [dataCompany, setDataCompany] = useState<any[]>([]);

  const [dataMyCompany, setDataMyCompany] = useState<any[]>([]);

  const [initialFiles, setInitialFiles] = useState<IFile[]>([]);

  const [saveFiles, setSaveFiles] = useState<IFile[]>([]);

  const types = useSelector<RootState>((state) => state.agreement.types) as {
    id: number;
    value: string;
  }[];

  const isDisabled = !!initialValue?.id;

  const executor_id = Form.useWatch('executor_id', {
    form,
    preserve: true,
  });

  const client_id = Form.useWatch('client_id', {
    form,
    preserve: true,
  });

  useEffect(() => {
    if (!isOpen) return;
    form.resetFields();
    setIsLoading(false);
    setInitialFiles(initialValue.files || []);
    setSaveFiles(initialValue.files || []);
    setDataCompany([]);
    setDataMyCompany([]);
    if (isDisabled) {
      form.setFieldsValue({ ...defaultAgreement, ...initialValue });
    } else {
      const responsible = {
        avatar: Utils.getUserInfo().avatar,
        name: Utils.getUserInfo().fio,
        id: Utils.getUserInfo().id,
        isResponsible: true,
      };
      const responsible_id = responsible.id;

      form.setFieldsValue({
        ...defaultAgreement,
        responsible_id,
      });
    }
  }, [isDisabled, isOpen, typeForm]);

  const handleClose = (res?: boolean) => {
    onClose(res);
    form.resetFields();
  };

  const onSubmit = async (values: IAgreement) => {
    try {
      setIsLoading(true);
      const { type_id } = values;
      values.type = types.find(({ id }) => id === type_id)?.value;
      values.date_signing = getDateSubmitString({ date: values.date_signing });

      const submit = {
        ...initialValue,
        ...values,
      };
      // сохранение формы с файлами
      submitFilesForm({
        id: initialValue?.id,
        submit,
        saveFiles,
        initialFiles,
        fetchSubmitForm: (arg: any) => dispatch(setAgreement(arg)),
      });

      setIsLoading(false);
      handleClose(true);
      form.resetFields();
    } catch (err) {
      console.error('submit', err);
    } finally {
      setIsLoading(false);
      setInitialFiles([]);
      setSaveFiles([]);
      setDataCompany([]);
      setDataMyCompany([]);
    }
  };

  const onUpdate = async (changedValues: any, allValues: any) => {
    console.log(saveFiles);
  };

  const onFinishError = async ({ values, errorFields, outOfDate }: any) => {
    if (errorFields) {
      const isComplectCheckErrors = [];
      for (const errors of errorFields) {
        const title = errors?.[0];
        if (isComplectCheckErrors.length === 2) {
          break;
        }
      }
    }

    return;
  };

  const handleSelectCompany = useCallback((id: string, record: any) => {
    const fields = form.getFieldsValue();
    form.setFieldsValue({
      ...fields,
      client: record?.value,
      client_id: record?.id,
    });
  }, []);

  const handleSelectMyCompany = useCallback((id: string, record: any) => {
    form.setFieldValue('executor', record?.value);
    form.setFieldValue('executor_id', record?.id);
  }, []);

  const handleSearchBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    if (client_id || !dataCompany?.length) return;
    const item = dataCompany.find(
      (comp: any) => comp?.value === evt.target.value,
    );
    if (!item) {
      form.setFieldValue('client', '');
      form.setFieldValue('client_id', null);
    }
  };

  const handleSearchBlurMyCompany = (
    evt: React.FocusEvent<HTMLInputElement>,
  ) => {
    if (executor_id || !dataMyCompany?.length) return;
    const item = dataMyCompany.find(
      (comp: any) => comp?.value === evt.target.value,
    );
    if (!item) {
      form.setFieldValue('executor', '');
      form.setFieldValue('executor_id', null);
    }
  };

  const handleSearchMyCompany = (text: string) => {
    if (text?.length >= 2) {
      delay(() => {
        dispatch(findCompany(text.toLocaleLowerCase()))
          .then((res) => {
            form.setFieldValue('executor_id', null);
            setDataMyCompany(
              filterListId(client_id)(mapperListCompany(res.payload)),
            );
          })
          .catch(console.error);
      }, 1000);
    }
  };

  const handleSearchCompany = (text: string) => {
    if (text?.length >= 2) {
      delay(() => {
        dispatch(findCompany(text.toLocaleLowerCase()))
          .then((res) => {
            form.setFieldValue('client_id', null);
            setDataCompany(
              filterListId(executor_id)(mapperListCompany(res.payload)),
            );
          })
          .catch(console.error);
      }, 1000);
    }
  };

  // функции для обновления файлов
  const handlerSaveFiles = (file: any) => {
    setSaveFiles((prev) => [...prev, file]);
  };

  const handlerDeleteFiles = ({
    file,
    isDelete = false,
  }: {
    file: IFile;
    isDelete?: boolean;
  }) => {
    if (isDelete) {
      setSaveFiles((prev) => prev.filter((fil) => fil.name !== file.name));
    } else {
      setInitialFiles((prev) =>
        prev.map((fil) => {
          if (fil?.name === file.name) {
            return { ...fil, delete: file.delete };
          }
          return fil;
        }),
      );
    }
  };

  return (
    <Form
      className={style['tabs-form']}
      autoComplete="off"
      noValidate
      layout="vertical"
      form={form}
      initialValues={defaultAgreement}
      scrollToFirstError
      onFinish={onSubmit}
      onFinishFailed={onFinishError}
      onValuesChange={onUpdate}
    >
      <Flex vertical gap={24}>
        <Flex justify="space-between" align="center">
          <Flex gap={10} align="center" style={{ marginTop: 15 }}>
            <Typography.Text className={style.title}>
              {initialValue?.id ? `Договор №${initialValue.id}` : t(title)}
            </Typography.Text>
          </Flex>
          <Flex gap={5} align={'center'}>
            {isDisabled && <IconButton iType="view-form" onClick={onEdit} />}
            {isDisabled && isCopy && (
              <CopyButton
                text={link}
                textSuccess={t('Ссылка скопирована')}
                icon={<Icon component={CopyBufferIconDefault} />}
              />
            )}
          </Flex>
        </Flex>
        <Flex justify="space-between" align="start" gap={10}>
          <Col span={11}>
            <Form.Item name="id" noStyle />
            <Form.Item name="responsible_id" noStyle />
            <Form.Item
              label="Номер договора"
              name={'agreement_id'}
              rules={[
                {
                  required: true,
                  message: t('Обязательное поле'),
                  whitespace: true,
                },
              ]}
              required
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item name="date_string" noStyle />
            <Form.Item
              label="Дата подписания"
              name="date_signing"
              rules={[{ required: true, message: t('Обязательное поле') }]}
              required
            >
              <FormDatePicker
                isString={false}
                disabledDate={disableDateAfter}
                handlerChange={(str, data) => {
                  form.setFieldsValue({
                    date_string: str,
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Flex>

        <Flex justify="space-between" align="start">
          <Col span={11}>
            <Flex vertical gap={25}>
              <Form.Item name="type" noStyle />
              <Form.Item
                label="Тип договора"
                name="type_id"
                rules={[{ required: true, message: t('Обязательное поле') }]}
                required
              >
                <Select options={mapperSelect({ items: types })} />
              </Form.Item>
              <Form.Item name="executor_id" noStyle />
              <Form.Item
                label="Исполнитель"
                name={'executor'}
                rules={[{ required: true, message: t('Обязательное поле') }]}
                required
              >
                <AutoComplete
                  popupClassName="certain-category-search-dropdown"
                  allowClear
                  options={dataMyCompany}
                  style={{ width: '100%' }}
                  onSearch={handleSearchMyCompany}
                  onSelect={handleSelectMyCompany}
                  onBlur={handleSearchBlurMyCompany}
                  placeholder="Исполнитель"
                />
              </Form.Item>
              <Form.Item name="client_id" noStyle />
              <Form.Item
                label="Заказчик"
                name="client"
                rules={[{ required: true, message: t('Обязательное поле') }]}
                required
              >
                <AutoComplete
                  popupClassName="certain-category-search-dropdown"
                  allowClear
                  options={dataCompany}
                  style={{ width: '100%' }}
                  onSearch={handleSearchCompany}
                  onSelect={handleSelectCompany}
                  onBlur={handleSearchBlur}
                  placeholder="Заказчик"
                />
              </Form.Item>
              <Form.Item name="refused" required noStyle />
            </Flex>
          </Col>
          <Col span={11}>
            <Form.Item name="file_ids" noStyle />
            <Typography.Text className={style.label}>
              {t('Файлы')}
            </Typography.Text>
            <div
              className={classnames(style['file-box'], 'all-custom-v-scroll')}
            >
              <UploaderDocsUnique
                isView={!isDisabled}
                files={isDisabled ? initialValue.files : []}
                isOpen={isOpen}
                buttonDescription={t('Загрузить файл')}
                multiple
                handlerSaveFiles={handlerSaveFiles}
                handlerDeleteFiles={handlerDeleteFiles}
                accept={[
                  fileTypes.doc,
                  fileTypes.xls,
                  fileTypes.pdf,
                  fileTypes.jpeg,
                ].join(',')}
              />
            </div>
          </Col>
        </Flex>
        <Flex justify="space-between" align="start" gap={10}>
          <Col span={11}>
            <Form.Item
              label="Дата выставления счета"
              name="date_issue"
              rules={[
                {
                  required: true,
                  message: t('Обязательное поле'),
                  whitespace: true,
                },
              ]}
              required
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Курс на"
              name="date_course"
              rules={[
                {
                  required: true,
                  message: t('Обязательное поле'),
                  whitespace: true,
                },
              ]}
              required
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Flex>
        <Flex justify="space-between" align="start" gap={10}>
          <Col span={11}>
            <Form.Item name="id" noStyle />
            <Form.Item name="responsible_id" noStyle />
            <Form.Item
              label="% конвертации"
              name={'conversion'}
              rules={[{ required: true, message: t('Обязательное поле') }]}
              required
            >
              <FormInputNumber min={0} allowClear />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Срок оплаты"
              name="payment_term"
              rules={[
                {
                  required: true,
                  message: t('Обязательное поле'),
                  whitespace: true,
                },
              ]}
              required
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Flex>
      </Flex>
      <Form.Item>
        <Flex
          gap={8}
          justify={'space-between'}
          style={{ margin: '32px auto 10px auto', maxWidth: '500px' }}
        >
          <TooltipButton
            propsTooltip={{
              title: initialValue?.id
                ? t('Сохранить изменения')
                : t('Добавить'),
            }}
            propsButton={{
              type: 'primary',
              htmlType: 'submit',
              style: { maxWidth: '290px', minWidth: '290px' },
              disabled: isLoading,
            }}
          >
            {isLoading
              ? '...Сохранение'
              : initialValue?.id
              ? t('Сохранить изменения')
              : t('Добавить')}
          </TooltipButton>
          <Button
            onClick={() => setConfirmation(true)}
            type={'text'}
            style={{ maxWidth: '134px', color: '#E14453' }}
            disabled={isLoading}
          >
            {t('Отменить')}
          </Button>
        </Flex>
      </Form.Item>

      <ModalConfirm
        title={messagesModal.warning.title}
        subtitle={messagesModal.warning.subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={handleClose}
      />
    </Form>
  );
};
