import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { Button, Col, Flex, Typography } from "antd";

import style from "../style.module.scss";
import { IForm } from "../../../store/agreement";
import { useLink } from "../../../hooks";
import { fileTypes } from "../../../helpers/input-helpers";
// import UploaderDocs from '../../../components/ui/UploaderDocs';
import ViewRow from "../../../components/ui/List/ViewRow/ViewRow";
import CopyButton from "../../../components/ui/CopyButton/CopyButton";
import { CopyBufferIconDefault } from "../../../assets/image/svg";
import { AGREEMENT, COMPANY } from "../../../constants";
import Icon from "@ant-design/icons";
import IconButton from "../../../components/ui/IconButton";
import { getSearchLink } from "../../../helpers/link-helper";
import UploaderDocsUnique from "../../../components/ui/UploaderDocsUnique";
import { Utils } from "../../../utils";
import { useStoreDispatch } from "../../../store";
import { getUserPermissions } from "../../../store/users";
import socket from "../../../socket";
import { setUserPermissions } from "../../../store/users";
import { RootState } from "../../../store";

export const ViewAgreements: React.FC<IForm> = ({
  isOpen,
  isCopy,
  initialValue,
  onClose,
  onEdit,
  title = "Договор",
}) => {
  const link = useLink({ key: AGREEMENT, id: initialValue?.id as number });
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const [currentUser, setCurrentUser] = useState(Utils.getUserInfo().id);
  const [rightsAgreementsEdit, setRightsAgreementsEdit] = useState(false);
  const permissionsData: any = useSelector(
    (state: RootState) => state.users.permissionsData
  );

  const handleClose = () => {
    if (onClose) {
      onClose(false);
    }
  };

  useEffect(() => {
    const fetchPermissions = () => {
      dispatch(getUserPermissions({ userId: currentUser, entityId: [1] }))
        .unwrap()
        .then((data) => {
          setUserPermissions(data);
          console.log("Дата из getUserPermissions =>", data);
          setRightsAgreementsEdit(
            (prev) =>
              data["agreements.status_edit"] === true ||
              data["agreements.group_status_edit"] === true
          );
        })
        .catch((err) => console.log(err));
    };

    fetchPermissions();

    socket.on("res-update-group-entities", (data) => {
      dispatch(setUserPermissions(data));
    });
    socket.on("res-update-entities", (data) => {
      dispatch(setUserPermissions(data));
    });

    return () => {
      socket.off("res-update-group-entities");
      socket.off("res-update-entities");
    };
  }, [dispatch, currentUser, permissionsData]);

  return (
    <div className={style["tabs-form"]}>
      <Flex vertical>
        <Flex
          justify="space-between"
          align="center"
          style={{ marginBottom: "30px" }}
        >
          <Flex gap={10} align="center" style={{ marginTop: 15 }}>
            <Typography.Text className={style.title}>
              {initialValue?.id
                ? `Договор №${initialValue.agreement_id}`
                : t(title)}
            </Typography.Text>
          </Flex>
          <Flex gap={5} align={"center"}>
            <IconButton
              iType="edit-form"
              onClick={onEdit}
              disabled={!!initialValue?.delete}
              style={{ display: rightsAgreementsEdit ? "block" : "none" }}
            />
            {isCopy && (
              <CopyButton
                text={link}
                textSuccess={t("Ссылка скопирована")}
                icon={<Icon component={CopyBufferIconDefault} />}
              />
            )}
          </Flex>
        </Flex>
        <Flex justify="space-between" align="start">
          <Col span={10}>
            <Flex vertical>
              <ViewRow
                className={style.row}
                title="Дата подписания"
                subtitle={initialValue?.date_string}
                isDivider
              />
              <ViewRow
                className={style.row}
                title="Тип договора"
                subtitle={initialValue?.type}
                isDivider
              />
              <ViewRow
                className={style.row}
                title="Исполнитель"
                subtitle={initialValue?.executor}
                isDivider
                link={getSearchLink({
                  key: COMPANY,
                  id: initialValue?.executor_id,
                  noUrl: true,
                })}
              />
              <ViewRow
                className={style.row}
                title="Заказчик"
                subtitle={initialValue?.client}
                isDivider
                link={getSearchLink({
                  key: COMPANY,
                  id: initialValue?.client_id,
                  noUrl: true,
                })}
              />
            </Flex>
          </Col>
          <Col span={13}>
            <Typography.Text
              className={style.label}
              style={{ paddingLeft: "10px", fontSize: "14px" }}
            >
              {t("Файлы")}
            </Typography.Text>
            <div
              className={classnames(style["file-box"], "all-custom-v-scroll")}
              style={{
                marginBottom: 0,
                minHeight: "220px",
                maxHeight: "220px",
              }}
            >
              <UploaderDocsUnique
                isView
                isOpen={isOpen}
                files={initialValue?.files}
                accept={[
                  fileTypes.doc,
                  fileTypes.xls,
                  fileTypes.pdf,
                  fileTypes.jpeg,
                ].join(",")}
              />
            </div>
          </Col>
        </Flex>
        <Flex justify="space-between" align="start">
          <Col span={10}>
            <ViewRow
              className={style.row}
              title="Дата выставления счета"
              subtitle={initialValue?.date_issue}
              isDivider
            />
            <ViewRow
              className={style.row}
              title="% конвертации"
              subtitle={initialValue?.conversion}
              isDivider
            />
          </Col>
          <Col span={13}>
            <ViewRow
              className={style.row}
              title="Курс на"
              subtitle={initialValue?.date_course}
              isDivider
            />
            <ViewRow
              className={style.row}
              title="Срок оплаты"
              subtitle={initialValue?.payment_term}
              isDivider
            />
          </Col>
        </Flex>
      </Flex>
      <Flex
        gap={8}
        justify="end"
        style={{ margin: "32px 0 10px auto", maxWidth: "500px" }}
      >
        <Button
          onClick={handleClose}
          type="primary"
          style={{ maxWidth: "174px", minWidth: "174px" }}
        >
          {t("Закрыть")}
        </Button>
      </Flex>
    </div>
  );
};
