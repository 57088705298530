import { apiAxios } from '../api/config';
import socket from '../socket';
import { IRelease } from '../store/releases';
import { IParamRestDelete } from '../types';

// генерация номера релиза
export const GENERATE_ID_RELEASES = 'generate-id-releases';
// получение фильтров для таблицы
export const GET_FILTERS_RELEASES = 'get-filters-releases';
// получение релизов для таблицы
export const SET_CREATE_RELEASE = 'set-create-release';
export const SET_UPDATE_RELEASE = 'set-update-release';
// удаление релиза
export const DELETE_RELEASE = 'delete-release';
// получение релизов по release_id
export const FIND_RELEASES_ID = 'find-releases_id';

export class Rest_releases {
  // временно, массовые обновления объектов
  static setAllUpdateChange = (
    payload: { id: number; values: any; fields: any[] }[],
  ) => {
    return new Promise((resolve, reject) => {
      socket.emit('set-all-update-release-change', payload);
    });
  };
  static findReleaseId(param?: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit(FIND_RELEASES_ID, param, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }
  static generateIdReleaseId(param?: {
    char?: string;
    check?: string;
    isCustom?: boolean;
  }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit(GENERATE_ID_RELEASES, param, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }
  static getFiltersReleases(param?: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit(GET_FILTERS_RELEASES, param, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }
  static getReleases(payload: {}): Promise<any> {
    return apiAxios.post('releases/get-releases', payload);
  }
  static setRelease(payload: IRelease): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit(SET_CREATE_RELEASE, payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }
  static updateRelease(payload: IRelease): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit(SET_UPDATE_RELEASE, payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }
  static findOneRelease(id: string | number): Promise<any> {
    return apiAxios.get(`releases/${id}`);
  }

  static getHistoryRelease(id: string | number): Promise<any> {
    return apiAxios.get(`releases/history/${id}`);
  }
  static findContainers(
    payload: string | { key: string; data: any[] },
  ): Promise<any> {
    return apiAxios.post('releases/find-containers-releases/', payload);
  }
  static deleteIds(payload: IParamRestDelete): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('delete-releases', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }
}
