import { IContainers } from '../../types/containers';

export const mapperContainers = (containers: IContainers | IContainers[]) => {
  if (Array.isArray(containers)) {
    return containers?.map((cont) => {
      cont.status = cont?.activity ? cont.activity?.name : 'Бронь';
      cont.activity = cont?.activity ? [cont.activity] : [];
      cont.comments = cont?.comments ? cont.comments : [];
      return cont;
    });
  }
  if (containers) {
    containers.status = containers?.activity ? containers.activity?.name : 'Бронь';
    containers.activity = containers?.activity ? [containers.activity] : [];
    containers.comments = containers?.comments ? containers.comments : [];
    return containers;
  } return null
};
