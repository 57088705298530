import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Flex,
  Form,
  Space,
  Typography,
} from 'antd';

import socket from '../../socket';
import { Utils } from '../../utils';
import { columns } from './columns';
import FormContacts from './formContact';
import ViewContacts from './viewContacts';
import { ITypeQuality } from '../../types';
import { IUsers } from '../../types/users';
import { RouteNames } from '../../router/names';
import { IContacts } from '../../types/contacts';
import { RootState, useStoreDispatch } from '../../store';
import { entitiesInfo, UserInfo } from '../../store/users';
import {
  useParamsHistory,
} from '../../hooks';
import { CREATE, EDIT, VIEW } from '../../helpers/string-helpers';
import {
  Table,
} from '../../components/ui/Table/Table';
import {
  deleteIds,
  saveContact,
  updateContact,
} from '../../store/contact';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';
import { Rest_contacts } from '../../services/rest_contacts';
import { CONTACT } from '../../constants';
import { defaultValueContact } from './constants';
import { useGettingDataForTables } from '../../hooks/useGettingDataForTables/useGettingDataForTables';

const { Title } = Typography;
interface IContactPage {
  title: string;
  activeKey?: string;
}

const Contacts: React.FC<IContactPage> = ({ title, activeKey }) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { location, history } = useParamsHistory();
  const { pathname } = location;
  const [form] = Form.useForm();
  const [open, setOpen] = useState<boolean>(false);
  const [typeForm, setTypeForm] = useState<string>(CREATE);
  const [isAddContact, setIsAddContact] = useState<boolean>(false);

  const [dataContact, setDataContact] = useState<IContacts>(defaultValueContact);

  const userInfoData: IUsers[] = useSelector(
    (state: RootState) => state.users.userInfoData,
  );
  const dataTypeQuality: ITypeQuality[] = useSelector(
    (state: RootState) => state.types.dataTypeQuality,
  );

  const {
    dataTable,
    current,
    pageSize,
    optionsTable,
    updateDate,
    setLoadingSubmit,
    updateEffectDate,
  } = useGettingDataForTables({
    titlePage: 'контакты',
    keyItemName: 'fio_contact',
    subscribeStringCreateForm: 'return-new-contact',
    subscribeStringUpdateForm: 'return-update-contact',
    subscribeStringDelete: 'return-delete-contact',
    subscribeError: 'error',
    performsAdditionalActions: () => {
      setOpen(false);
      form.resetFields();
    },
    table: CONTACT,
    location,
    columns,
    fetchTables: Rest_contacts.getContact,
    fetchDelete: (arg: any) => dispatch(deleteIds(arg)),
  });

  useEffect(() => {
    if (activeKey !== CONTACT) return;
    if (pathname === RouteNames.CRM_CONTACTS) {
      updateEffectDate();
      const row = location?.state?.[CONTACT];
      if (row) {
        window.history.replaceState({state: null}, document.title);
        if (row?.edit) {
          setDataContact(row?.edit);
          setTypeForm(EDIT);
          setOpen(true);
          return;
        }
        setDataContact(defaultValueContact);
        form.resetFields();
        setTypeForm(CREATE);
        setOpen(true);
        return;
      }
      return;
    }
    return history.replace(RouteNames.CRM_CONTACTS);
  }, [pathname, activeKey]);

  useEffect(() => {
    if (activeKey !== CONTACT) return;
    dispatch(UserInfo({ id: Utils.getUserInfo().id }));
    dispatch(entitiesInfo(Utils.role())).then((response: any) => {
      findEntitiesStatus(response.payload);
    }).catch((e) => {
      console.error(e);
    });;
    socket.on('res-update-entities', resUpdateEntities);
    socket.on('res-update-group-entities', resUpdateGroupEntities);
    socket.on('return-delete-contact', returnDeleteContact);

    return () => {
      socket.off('return-delete-contact', returnDeleteContact);
      socket.off('res-update-entities', resUpdateEntities);
      socket.off('res-update-group-entities', resUpdateGroupEntities);
    };
  }, [current, dispatch, pageSize, activeKey]);

  const findEntitiesStatus = (response: any) => {
    response.res_client.forEach((item: any) => {
      item.entities === 'addContact' && setIsAddContact(item.status);
    });
  };

  const resUpdateEntities = (response: any) => {
    if (Utils.role().id === response.role_id) {
      response.res_client.map((item: any) => {
        item.entities === 'addContact' && setIsAddContact(item.status);
      });
    }
  };

  const resUpdateGroupEntities = (response: any) => {
    if (Utils.role().id === response.role_id) {
      response.res_client.map((item: any) => {
        item.entities === 'addContact' && setIsAddContact(item.status);
      });
    }
  };

  const returnDeleteContact = (response: any) => {
    updateDate();
  };

  const onCreate = () => {
    setDataContact(defaultValueContact);
    form.resetFields();
    setTypeForm(CREATE);
    setOpen(true);
  };

  const onRow = async (row: IContacts) => {
    setDataContact(row);
    setTypeForm(VIEW);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setTypeForm(CREATE);
    form.resetFields();
  };

  const handleShowEditForm = (row: IContacts) => {
    setDataContact(row);
    setTypeForm(EDIT);
    setOpen(true);
  };

  const onFinish = (data: IContacts) => {
    dispatch(data?.id ? updateContact(data) : saveContact(data)).finally(() => {
      setOpen(false);
      socket.off(data?.id ? 'update-contact' : 'save-contact');
    });
    setLoadingSubmit(true);
  };

  return (
    <>
      <Flex
        justify={'space-between'}
        align={'flex-start'}
        style={{ margin: '20px 28px 0px' }}
      >
        <Title level={2}>{t(title)}</Title>
        <Flex gap={16}>
          <Button onClick={onCreate} type={'primary'}>
            {t('Создать')}
          </Button>
        </Flex>
      </Flex>
      <Divider style={{ margin: 0 }} />
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Table
          {...optionsTable}
          onClickLink={onRow}
          dataSource={dataTable}
          style={{ overflow: 'auto hidden' }}
          editRow={handleShowEditForm}
          height={'calc(100vh - 309px)'}
          onRow={onRow}
        />
      </Space>
      <CustomDrawer open={open} onClose={onClose}>
        {typeForm !== VIEW ? (
          <FormContacts
            isCopy
            isOpen={open}
            form={form}
            onFinish={onFinish}
            onClose={onClose}
            pagination={{ pageSize, current }}
            title={'Контакты'}
            dataContacts={dataContact}
            dataTypeQuality={dataTypeQuality}
            userInfoData={userInfoData}
            onEdit={() => setTypeForm(VIEW)}
          />
        ) : (
          <ViewContacts
            isCopy
            dataContacts={dataContact}
            onEdit={() => setTypeForm(EDIT)}
            onClose={onClose}
          />
        )}
      </CustomDrawer>
    </>
  );
};

export default Contacts;
