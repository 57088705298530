import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  Flex,
  Form,
  MenuProps,
  Modal,
  Tag,
  Typography,
} from 'antd';
import classnames from 'classnames';
import { Utils } from '../../../utils';
import style from '../style.module.scss';
import {
  defaultValues,
  generateIdReleaseId,
  IContainer,
  IForm,
  IRelease,
  IStatus,
  updateRelease,
} from '../../../store/releases';
import {
  messagesModal,
  RELEASE,
  RENT_DEAL,
  SALES_DEAL,
} from '../../../constants';
import { useStoreDispatch } from '../../../store';
import { ITab, ITabs } from '../../../components/ui/Tabs/Tabs';
import { setRelease } from '../../../store/releases';
import ModalConfirm from '../../../components/ui/ModalConfirm/ModalConfirm';
import TooltipButton from '../../../components/ui/TooltipButton/TooltipButton';
import { BaseTab, TableTab } from './edit';
import { BASE_TAB, NEW, releaseStatus, TABLE_TAB } from '../constants';
import Icon from '@ant-design/icons';
import { moreIcon } from '../../../assets/image/svg';
import { RouteNames } from '../../../router/names';
import { useLink } from '../../../hooks';
import socket from '../../../socket';
import { GENERATE_ID_RELEASES } from '../../../services/rest_releases';
import { CREATE } from '../../../helpers/string-helpers';
import CopyButton from '../../../components/ui/CopyButton/CopyButton';
import { getDateSubmitString } from '../../../helpers/date-helpers';
import {
  IFile,
  UploaderDocsUnique,
} from '../../../components/ui/UploaderDocsUnique/UploaderDocsUnique';
import { submitFilesForm } from '../../../helpers/file-helpers';
import { fileTypes } from '../../../helpers/input-helpers';

export const FormRelease: React.FC<IForm> = ({
  isCopy,
  typeForm,
  onHistory,
  onClose,
  onDeal,
  onEdit,
  onPdf,
  isOpen,
  initialValue,
  title = 'Создание релиза',
}) => {
  const dispatch = useStoreDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const link = useLink({ key: RELEASE, id: initialValue?.id as number });

  const [activeKey, setActiveKey] = useState(() => BASE_TAB);

  const [isSave, setIsSave] = useState<boolean>(false);

  const [isModalFiles, setIsModalFiles] = useState<boolean>(false);

  const [isErrorsBase, setIsErrorsBase] = useState<boolean | undefined>(false);

  const [confirmation, setConfirmation] = React.useState<boolean>(false);

  const [saveFiles, setSaveFiles] = useState<IFile[]>([]);

  const [initialFiles, setInitialFiles] = useState<IFile[]>([]);

  const containers = Form.useWatch('containers', {
    form,
    preserve: true,
  }) as IContainer[];

  const is_transporter = Form.useWatch('is_transporter', {
    form,
    preserve: true,
  });

  const status = Form.useWatch('status', {
    form,
    preserve: true,
  }) as IStatus;

  const isDisabled = !!initialValue?.id;

  useEffect(() => {
    setIsErrorsBase(false);
  }, [isOpen]);

  useEffect(() => {
    setSaveFiles(initialValue.files || []);
    setInitialFiles(initialValue.files || []);
    if (initialValue?.id) {
      form.setFieldsValue({ ...initialValue });
    }
    if (!initialValue?.id) {
      const {
        email,
        id: responsible_id,
        fio: responsible,
      } = Utils.getUserInfo();
      const [start] = typeof email === 'string' ? email.split('@') : ['SM'];
      const user_release_id = start.toUpperCase();
      form.setFieldsValue({
        ...defaultValues,
        responsible_id,
        responsible,
        user_release_id,
        status: NEW,
      });
    }
  }, [isDisabled, isOpen, form, dispatch, typeForm]);

  const openModalFile = () => {
    setConfirmation(false);
    setIsModalFiles(true);
  };
  const closeModalFile = () => {
    setIsModalFiles(false);
  };
  const openModalHistory = () => {
    setIsModalFiles(false);
    if (onHistory) {
      onHistory();
    }
  };

  const tabs: ITab[] = useMemo(
    () => [
      {
        key: BASE_TAB,
        label: 'Основное',
        forceRender: true,
        isError: isErrorsBase,
        disabledText: '',
        disabled: false,
        content: <BaseTab initialValue={initialValue} />,
      },
      {
        key: TABLE_TAB,
        label: 'Таблица',
        forceRender: true,
        isError: false,
        content: <TableTab initialValue={initialValue} />,
      },
    ],
    [isErrorsBase, typeForm, isOpen],
  );

  const onSubmit = async (values: IRelease) => {
    try {
      if (typeForm === CREATE) {
        const isCustom = values?.release_generate_id !== values?.release_id;
        await dispatch(
          generateIdReleaseId({
            char: values.user_release_id,
            check: values.release_id,
            isCustom,
          }),
        )
          .then((res) => {
            if (!res?.payload) {
              throw new Error(
                'Номер релиза уже присутствует, замените и попробуйте снова.',
              );
            }
          })
          .catch((err) => {
            form.setFields([
              {
                name: 'release_id',
                errors: [
                  'Номер релиза уже присутствует, замените и попробуйте снова.',
                ],
              },
            ]);
            throw err;
          })
          .finally(() => {
            socket.off(GENERATE_ID_RELEASES);
          });
      }
      setIsSave(true);
      const release_id = values.release_id.trim();
      const release_generate_id =
        typeof values?.release_generate_id === 'string'
          ? values.release_generate_id.trim()
          : '';

      values.date_action_start = getDateSubmitString({
        date: values.date_action_start,
      });
      values.date_action_end = getDateSubmitString({
        date: values.date_action_end,
      });

      const submit = {
        ...initialValue,
        ...values,
        id: initialValue?.id,
        release_id,
        release_generate_id,
      };

      // сохранение формы с файлами
      submitFilesForm({
        id: initialValue?.id,
        submit,
        saveFiles,
        initialFiles,
        fetchSubmitForm: (arg: any) => initialValue?.id ? dispatch(updateRelease(arg)) : dispatch(setRelease(arg)),
      });

      onClose(true);
      form.resetFields();
      setIsSave(false);
    } catch (err) {
      console.error('submit', err);
    } finally {
      setIsSave(false);
      setSaveFiles([]);
      setInitialFiles([]);
    }
  };

  const onUpdate = (changedValues: IRelease, allValues: any) => {
    // console.log(allValues);

    setIsErrorsBase(false);
    return;
  };

  const onFinishError = async ({ values, errorFields, outOfDate }: any) => {
    if (errorFields) {
      const checkValues = [
        'release_id',
        'date_action_start',
        'date_action_end',
        'containers_count',
        ...(!is_transporter ? ['transporter'] : ['inn', 'company']),
      ];
      for (const errors of errorFields) {
        const title = errors?.name?.[0];
        if (checkValues.includes(title)) {
          setIsErrorsBase(true);
          break;
        }
      }
    }

    return;
  };

  const handleClose = () => {
    onClose(false);
    form.resetFields();
  };

  const changeClickTab = (key: string) => {
    setActiveKey(key);
  };

  const items: MenuProps['items'] = [
    {
      key: 1,
      label: t('Связанные документы'),
      disabled: true || !isDisabled,
    },
    {
      key: 2,
      label: t('История изменений'),
      disabled: !onHistory || !isDisabled,
      onClick: openModalHistory,
    },
    {
      key: 3,
      label: t('Создать сделку на основание релиза'),
      disabled: !isDisabled,
      children: [
        {
          key: 3.1,
          label: (
            <Typography.Text
              data-id="link"
              onClick={() =>
                onDeal({
                  path: RouteNames.SALES_DEAL,
                  state: {
                    [SALES_DEAL]: {
                      containers: containers
                        ?.filter((item) => item?.num_container && !item?.delete)
                        ?.map((item) => ({
                          ...item,
                          id: item?.container_id,
                          release: {
                            release_id: initialValue?.release_id,
                            id: initialValue?.id,
                          },
                          edit: false,
                        })),
                    },
                  },
                })
              }
            >
              {t('Продажа')}
            </Typography.Text>
          ),
        },
        {
          disabled: !isDisabled,
          key: 3.2,
          label: (
            <Typography.Text
              disabled
              data-id="link"
              onClick={() =>
                onDeal({
                  path: RouteNames.RENT_DEAL,
                  state: {
                    [RENT_DEAL]: {
                      releases: [
                        {
                          release_id: initialValue?.release_id,
                          options: [
                            {
                              label: initialValue?.release_id,
                              value: initialValue?.id,
                            },
                          ],
                          ids: [],
                          saveFiles: [],
                        },
                      ],
                      containers: containers
                        ?.filter((item) => item?.num_container && !item?.delete)
                        ?.map((item) => ({
                          ...item,
                          id: item?.container_id,
                          release: {
                            release_id: initialValue?.release_id,
                            id: initialValue?.id,
                          },
                          edit: false,
                        })),
                    },
                  },
                })
              }
            >
              {t('Аренда')}
            </Typography.Text>
          ),
        },
      ],
    },
    {
      key: 4,
      label: t('Выгрузить шаблон релиза PDF (РФ)'),
      onClick: () => onPdf('ru'),
      disabled: !isDisabled,
    },
    {
      key: 5,
      label: t('Выгрузить шаблон релиза PDF (EN)'),
      onClick: () => onPdf('en'),
      disabled: !isDisabled,
    },
    {
      key: 6,
      label: t('Просмотр'),
      onClick: onEdit,
      disabled: !isDisabled,
    },
    {
      key: 7,
      label: saveFiles?.length ? t('Просмотр вложений') : t('Загрузить вложение'),
      onClick: openModalFile,
      disabled: !isDisabled,
    },
    {
      key: 8,
      label: (
        <CopyButton
          text={link}
          textButton={t('Скопировать ссылку')}
          textSuccess={t('Ссылка скопирована')}
        />
      ),
      disabled: !isCopy || !isDisabled,
    },
  ];

  // функции для обновления файлов
  const handlerSaveFiles = (file: any) => {
    setSaveFiles((prev) => [...prev, file]);
  };

  const handlerDeleteFiles = ({
    file,
    isDelete = false,
  }: {
    file: IFile;
    isDelete?: boolean;
  }) => {
    if (isDelete) {
      setSaveFiles((prev) => prev.filter((fil) => fil.name !== file.name));
    } else {
      setInitialFiles((prev) =>
        prev.map((fil) => {
          if (fil?.name === file.name) {
            return { ...fil, delete: file.delete };
          }
          return fil;
        }),
      );
    }
  };

  return (
    <Form
      className={style['form']}
      noValidate
      autoComplete="off"
      layout="vertical"
      form={form}
      initialValues={defaultValues}
      scrollToFirstError
      onFinish={onSubmit}
      onFinishFailed={onFinishError}
      onValuesChange={onUpdate}
    >
      <Form.Item name="saveFiles" noStyle />
      <Flex vertical gap={24}>
        <Flex justify="space-between" align="center">
          <Flex gap={10} align="center" style={{ marginTop: 15 }}>
            <Typography.Text className={style['title']}>
              {isDisabled ? 'Редактирование' : t(title)}
            </Typography.Text>
            {isDisabled && (
              <Typography.Text
                className={classnames(style['title'], style['title_label'])}
              >
                {initialValue.release_id}
              </Typography.Text>
            )}
          </Flex>
          <Dropdown
            overlayClassName={style['menu-drop']}
            placement="bottomRight"
            menu={{
              items,
            }}
            trigger={['click']}
          >
            <Typography.Link className="" onClick={(e) => e.preventDefault()}>
              <Icon component={moreIcon} />
            </Typography.Link>
          </Dropdown>
        </Flex>
        <div className={style['relative']}>
          <ITabs
            className={style['tabs-form__tab']}
            isErrorTabs
            activeKey={activeKey}
            tabs={tabs}
            onChange={changeClickTab}
          />
          <div className={style['relative__block']}>
            <Typography.Text>{t('Статус')}</Typography.Text>
            {!!status && (
              <Tag style={releaseStatus[status].style}>
                {t(releaseStatus?.[status].title)}
              </Tag>
            )}
          </div>
        </div>
      </Flex>

      <Form.Item>
        <Flex
          gap={8}
          justify={'space-between'}
          style={{ margin: '32px auto 10px auto', maxWidth: '500px' }}
        >
          <TooltipButton
            propsTooltip={{
              title: isDisabled ? t('Сохранить изменения') : t('Сохранить'),
            }}
            propsButton={{
              type: 'primary',
              htmlType: 'submit',
              style: { maxWidth: '290px', minWidth: '290px' },
              disabled: isSave,
            }}
          >
            {isDisabled ? t('Сохранить изменения') : t('Добавить')}
          </TooltipButton>
          <Button
            onClick={() => setConfirmation(true)}
            type={'text'}
            style={{ maxWidth: '134px', color: '#E14453' }}
            disabled={isSave}
          >
            {t('Отменить')}
          </Button>
        </Flex>
      </Form.Item>

      <Modal
        open={isModalFiles}
        title={
          <Typography.Text className={style.label}>
            {t('Файлы')}
          </Typography.Text>
        }
        closeIcon
        centered
        footer={null}
        onCancel={closeModalFile}
      >
        <div className={style.modal}>
          <div className={classnames(style['modal__content'])}>
            <div
              className={classnames(style['file-box'], 'all-custom-v-scroll')}
            >
              <UploaderDocsUnique
                isView={!isDisabled}
                files={isDisabled ? initialValue.files : []}
                isOpen={isOpen}
                buttonDescription={t('Загрузить файл')}
                multiple
                handlerSaveFiles={handlerSaveFiles}
                handlerDeleteFiles={handlerDeleteFiles}
                accept={[
                  fileTypes.doc,
                  fileTypes.xls,
                  fileTypes.pdf,
                  fileTypes.jpeg,
                ].join(',')}
              />
            </div>
          </div>
        </div>
      </Modal>

      <ModalConfirm
        title={messagesModal.warning.title}
        subtitle={messagesModal.warning.subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={handleClose}
      />
    </Form>
  );
};
