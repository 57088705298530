import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Avatar, Col, Divider, Flex, Row, Typography } from 'antd';

import Icon, { UserOutlined } from '@ant-design/icons';

import { Icons } from './Icons';
import { useLink } from '../../hooks';
import { COMPANY, CONTACT } from '../../constants';
import Button from '../../components/ui/Button/Button';
import IconButton from '../../components/ui/IconButton';
import CopyButton from '../../components/ui/CopyButton/CopyButton';
import {
  CopyBufferIconDefault,
  copyIcon,
  EmailIcon,
} from '../../assets/image/svg';

import './style.scss';
import { IContractors } from '../../types/contractors';
import PhoneText from '../../components/ui/PhoneText';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';
import FormRequisites from './formRequisites';
import ViewRow from '../../components/ui/List/ViewRow/ViewRow';
import { getSearchLink } from '../../helpers/link-helper';
import { Link } from 'react-router-dom';
import { defaultRequisites, defaultValueBank } from './constants';

interface IViewContractor {
  initialValue: IContractors;
  isCopy?: boolean;
  onEdit: () => void;
  onClose: () => void;
}

const FormCardContractors: React.FC<IViewContractor> = ({
  initialValue,
  isCopy,
  onClose,
  onEdit,
}) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const { t } = useTranslation();
  const link = useLink({ key: COMPANY, id: initialValue?.id as number });
  const address: any = initialValue.address ?? '';
  const inn: any = initialValue.inn ?? '';

  const closeDrawer = () => setIsOpenDrawer(false);
  const openDrawer = () => setIsOpenDrawer(true);
  return (
    <Flex vertical>
      <Flex justify={'space-between'} className={'drawer-header'}>
        <Flex vertical style={{ width: '100%' }}>
          <Flex
            gap={4}
            justify={'space-between'}
            align={'center'}
            style={{ marginBottom: 12 }}
          >
            <div className={'drawer-title'}>
              {initialValue?.name_company ?? ''}
            </div>
            <Flex gap={5} align={'center'}>
              <IconButton
                iType="edit-form"
                onClick={onEdit}
                disabled={!!initialValue?.delete}
              />
              {isCopy && (
                <CopyButton
                  text={link}
                  textSuccess={t('Ссылка скопирована')}
                  icon={<Icon component={CopyBufferIconDefault} />}
                />
              )}
            </Flex>
          </Flex>

          <ViewRow title={t('ИНН')}>
              <Typography.Text>{inn}</Typography.Text>
            </ViewRow>

          <ViewRow title={t('Внутреннее название компании')} isDivider>
            <Typography.Text>
              {initialValue?.internal_company ?? ''}
            </Typography.Text>
          </ViewRow>

          <ViewRow title={t('Тип компании')} isDivider>
            <Typography.Text>
              {initialValue?.type_company ?? ''}
            </Typography.Text>
          </ViewRow>
          <ViewRow title={t('Источник')} isDivider>
            <Typography.Text>{initialValue?.type_source ?? ''}</Typography.Text>
          </ViewRow>
          <ViewRow title={t('Контакты')} isDivider>
            <Flex gap={4} vertical>
              <Flex gap={4} vertical>
                <Row>
                  <Col className="gutter-row" span={10}>
                    <Typography.Text>
                      {initialValue?.type_phone_first ?? ''}
                    </Typography.Text>
                  </Col>
                  {initialValue?.phone_first && (
                    <>
                      <Col className="gutter-row" span={12}>
                        <Flex gap={4}>
                          <PhoneText text={initialValue?.phone_first} />
                        </Flex>
                      </Col>
                      <Col className="gutter-row" span={2}>
                        <CopyButton
                          text={initialValue?.phone_first ?? ''}
                          icon={<Icon component={copyIcon} />}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                {initialValue?.last_phone?.map((item: any, index: number) => {
                  return (
                    <Row key={index}>
                      <Col className="gutter-row" span={10}>
                        <Typography.Text>{item.add_type_phone}</Typography.Text>
                      </Col>
                      {item?.add_phone && (
                        <>
                          <Col className="gutter-row" span={12}>
                            <Flex gap={4}>
                              <PhoneText text={item?.add_phone} />
                            </Flex>
                          </Col>
                          <Col className="gutter-row" span={2}>
                            <CopyButton
                              text={item?.add_phone}
                              icon={<Icon component={copyIcon} />}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  );
                })}
              </Flex>
              <Flex gap={4} vertical>
                {initialValue?.email_first !== null && (
                  <Row>
                    <Col className="gutter-row" span={10}>
                      <Typography.Text>
                        {initialValue?.type_email_first ?? ''}
                      </Typography.Text>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Flex gap={10} align={'center'}>
                        <Icon
                          component={EmailIcon}
                          style={{ width: '18px', color: 'gray' }}
                        />
                        <Typography.Text>
                          {initialValue?.email_first ?? ''}
                        </Typography.Text>
                      </Flex>
                    </Col>
                    <Col className="gutter-row" span={2}>
                      <CopyButton
                        text={initialValue?.email_first ?? ''}
                        icon={<Icon component={copyIcon} />}
                      />
                    </Col>
                  </Row>
                )}
                {initialValue?.last_email?.map((item: any, index: number) => {
                  return (
                    <Row key={index}>
                      <Col className="gutter-row" span={10}>
                        <Typography.Text>{item.add_type_email}</Typography.Text>
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <Flex gap={10}>
                          <Icon
                            component={EmailIcon}
                            style={{ width: '18px', color: 'gray' }}
                          />
                          <Typography.Text>{item.add_email}</Typography.Text>
                        </Flex>
                      </Col>
                      <Col className="gutter-row" span={2}>
                        <CopyButton
                          text={item.add_email}
                          icon={<Icon component={copyIcon} />}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </Flex>
              <Flex gap={4} vertical>
                {initialValue?.messenger_first && (
                  <Row>
                    <Col className="gutter-row" span={10}>
                      <Typography.Text>
                        {initialValue?.type_messenger_first ?? ''}
                      </Typography.Text>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Flex gap={6}>
                        <Icons.arrIcon
                          type={initialValue?.type_messenger_first ?? ''}
                        />
                        <Typography.Text>
                          {initialValue?.messenger_first}
                        </Typography.Text>
                      </Flex>
                    </Col>
                    <Col className="gutter-row" span={2}>
                      <CopyButton
                        text={initialValue?.messenger_first ?? ''}
                        icon={<Icon component={copyIcon} />}
                      />
                    </Col>
                  </Row>
                )}
                {initialValue?.last_messenger?.map(
                  (item: any, index: number) => {
                    return (
                      <Row key={index}>
                        <Col className="gutter-row" span={10}>
                          <Typography.Text>
                            {item.add_messenger}
                          </Typography.Text>
                        </Col>
                        <Col className="gutter-row" span={12}>
                          <Flex gap={6}>
                            <Icons.arrIcon type={item.add_type_messenger} />
                            <Typography.Text>
                              {item.add_type_messenger}
                            </Typography.Text>
                          </Flex>
                        </Col>
                        <Col className="gutter-row" span={2}>
                          <CopyButton
                            text={item.add_type_messenger}
                            icon={<Icon component={copyIcon} />}
                          />
                        </Col>
                      </Row>
                    );
                  },
                )}
              </Flex>
            </Flex>
          </ViewRow>
          <ViewRow
            title={t('Контакт')}
            subtitle={
              initialValue?.contact
                ? (initialValue?.contact?.last_name ?? '') +
                  ' ' +
                  (initialValue?.contact?.name ?? '') +
                  ' ' +
                 (initialValue?.contact?.first_name ?? '')
                : null
            }
            link={
              initialValue?.contact_id
                ? getSearchLink({
                    key: CONTACT,
                    id: initialValue?.contact_id,
                    noUrl: true,
                  })
                : ''
            }
            isDivider
          >
            <Typography.Text>
              {initialValue?.contact_phone && initialValue?.contact_phone + ' '}
              {initialValue?.contact_email && initialValue?.contact_email}
            </Typography.Text>
          </ViewRow>
          <Flex gap={4} vertical>
            <Flex justify="space-between" style={{marginBottom: '10px'}}>
              <ViewRow title={t('Реквизиты')} />

              <Typography.Link
                disabled={!initialValue?.id}
                onClick={openDrawer}
                style={{ whiteSpace: 'nowrap' }}
              >
                {t('Подробно')}
              </Typography.Link>
            </Flex>
            <ViewRow title={t('Адрес')}>
              <Typography.Text>{address}</Typography.Text>
            </ViewRow>
          </Flex>
          <Divider />
          <Flex gap={6} vertical>
            <Typography.Title level={5} style={{ margin: '10px 0' }}>
              Дополнительно
            </Typography.Title>
            <ViewRow title={t('Ответственный')} />
            <Flex
              justify={'space-between'}
              align={'center'}
              style={{ paddingRight: 7 }}
            >
              {!!initialValue?.users?.fio && (
                <Flex gap={6} align={'center'}>
                  <Avatar size={28} icon={<UserOutlined />} />
                  <Typography.Paragraph
                    style={{ maxWidth: '250px', margin: 0, color: '#0148D7' }}
                  >
                    {initialValue?.users?.id ? (
                      <Link
                        style={{ color: 'currentcolor' }}
                        to={getSearchLink({
                          key: CONTACT,
                          id: initialValue?.users?.id,
                          noUrl: true,
                        })}
                      >
                        {initialValue?.users?.fio ?? ''}
                      </Link>
                    ) : (
                      initialValue?.users?.fio ?? ''
                    )}
                  </Typography.Paragraph>
                </Flex>
              )}
            </Flex>
          </Flex>
          <Flex vertical style={{ margin: '20px 0' }}>
            <Typography.Title level={5} style={{ marginBottom: 6 }}>
              Комментарий
            </Typography.Title>
            <div>{initialValue?.comment ?? ''}</div>
          </Flex>
        </Flex>
      </Flex>
      <Flex gap={8} justify="end" style={{ margin: '32px' }}>
        <Button onClick={onClose} type="primary" style={{ width: '174px' }}>
          {t('Закрыть')}
        </Button>
      </Flex>

      <CustomDrawer
        open={isOpenDrawer}
        title={t('Организация')}
        onClose={closeDrawer}
        isHiddenClose
      >
        <FormRequisites
          isView
          isOpen={isOpenDrawer}
          requisites={initialValue?.requisites?.requisites || defaultRequisites}
          dataBankRequisites={initialValue?.bank || []}
          onClose={closeDrawer}
          title={t('Организация')}
        />
      </CustomDrawer>
    </Flex>
  );
};

export default FormCardContractors;
