import React, { FocusEventHandler, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AutoComplete,
  Avatar,
  Button,
  Col,
  Divider,
  Flex,
  Form,
  FormInstance,
  Input,
  Modal,
  Select,
  Typography,
} from 'antd';

import ru from 'react-phone-input-2/lang/ru.json';
import Icon, {
  DeleteOutlined,
  PlusOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { TOK_EN, Utils } from '../../utils';
import FormRequisites from './formRequisites';
import { COMPANY, messagesModal } from '../../constants';
import { RootState, useStoreDispatch } from '../../store';
import { findContact, findExecutor } from '../../store/contact';
import {
  keyHandlerInputNumber,
  mapperSearchYandexLocation,
} from '../../helpers/input-helpers';
import { getContractorsRequisites } from '../../store/contractors';
import ModalConfirm from '../../components/ui/ModalConfirm/ModalConfirm';
import { SelectSearch } from '../../components/ui/SelectSearch/SelectSearch';

import { useLink } from '../../hooks';
import IconButton from '../../components/ui/IconButton';
import CopyButton from '../../components/ui/CopyButton/CopyButton';
import { CopyBufferIconDefault } from '../../assets/image/svg';
import { IContractors } from '../../types/contractors';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';
import {
  defaultItemLastEmailList,
  defaultItemLastPhoneList,
  defaultItemMessengerList,
  defaultRequisites,
  defaultValueContractor,
} from './constants';
import { getDateString } from '../../helpers/date-helpers';
import PhoneInput from 'react-phone-input-2';

const { title: warningTitle, subtitle } = messagesModal.warning;

interface DataType {
  key: React.Key;
  name: string;
  meaning: string;
}

const { Title, Text } = Typography;

interface IFormEditContractors {
  isOpen: boolean;
  isCopy?: boolean;
  title: string;
  form: FormInstance;
  initialValue: IContractors;
  onFinishEdit?: (data: any) => void;
  onCloseEdit?: () => void;
  onEdit?: () => void;
}

const FormEditContractors: React.FC<IFormEditContractors> = ({
  isOpen,
  form,
  title,
  isCopy,
  onFinishEdit,
  onCloseEdit,
  onEdit,
  initialValue, // нужны дефолтные значения
}) => {
  const link = useLink({ key: COMPANY, id: initialValue?.id as number });
  const { t } = useTranslation();
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const dispatch = useStoreDispatch();
  const [isOpenRequisites, setIsOpenRequisites] = useState(false);
  const [dataContact, setContact] = useState<any>([]);
  const [dataCity, setCity] = useState<any>([]);
  const [finContact, setFinContact] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingRequisites, setIsLoadingRequisites] = useState(false);

  const lang = useSelector((state: RootState) => state.stores.lang);
  const dataSourceContact = useSelector(
    (state: RootState) => state.contact.dataSourceContact,
  );
  const dataTypeCompany = useSelector(
    (state: RootState) => state.types.dataTypeCompany,
  );
  const dataTypePhone = useSelector(
    (state: RootState) => state.types.dataTypePhone,
  );
  const dataTypeEmail = useSelector(
    (state: RootState) => state.types.dataTypeEmail,
  );
  const dataTypeMessenger = useSelector(
    (state: RootState) => state.types.dataTypeMessenger,
  );
  const [typeCompany, setTypeCompany] = useState<any>(
    initialValue?.type_company,
  );
  const [typeSource, setTypeSource] = useState<any>(initialValue?.type_source);

  const lastPhoneList: any[] = Form.useWatch('last_phone', {
    form,
    preserve: true,
  });
  const lastEmailList: any[] = Form.useWatch('last_email', {
    form,
    preserve: true,
  });
  const lastMessengerList: any[] = Form.useWatch('last_messenger', {
    form,
    preserve: true,
  });
  const [dataInn, setDataInn] = useState<any>([]);
  const [requisites, setRequisites] = useState<any[] | null>(
    initialValue?.requisites?.requisites || null,
  );
  const [dataExecutor, setDataExecutor] = useState<[]>([]);
  const [responsibleChange, setResponsibleChange] = useState<any>(() => null);
  const [arrResponsible, setArrResponsible] = useState<any>(null);
  const [responsible, setResponsible] = useState<string>(
    Utils.getUserInfo().fio,
  );
  const [dataBankRequisites, setDataBankRequisites] = useState<[]>([]);
  const [valueContact, setValueContact] = useState<string>('');
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [detailInn, setDetailInn] = useState<boolean>(false);

  useEffect(() => {
    if (initialValue?.id) {
      setDataBankRequisites(initialValue?.requisites?.bank || []);
      setRequisites(
        initialValue?.requisites?.requisites?.length
          ? initialValue.requisites.requisites
          : null,
      );
      setArrResponsible(initialValue?.users?.value ?? null);
      setResponsible(initialValue?.users?.fio ?? '');
      setDetailInn(!!initialValue?.requisites);
      form.setFieldsValue({
        name_company: initialValue?.name_company,
        internal_company: initialValue?.internal_company,
        type_company: initialValue?.type_company,
        type_source: initialValue?.type_source,
        phone_first:
          !Array.isArray(initialValue) && initialValue?.phone_first !== null
            ? initialValue?.phone_first
            : '+7',
        type_phone_first: initialValue?.type_phone_first,
        email_first: initialValue?.email_first,
        type_email_first: initialValue?.type_email_first,
        messenger_first: initialValue?.messenger_first,
        type_messenger_first: initialValue?.type_messenger_first,
        address: initialValue?.address,
        comment: initialValue?.comment,
        last_phone: initialValue?.last_phone,
        last_email: initialValue?.last_email,
        last_messenger: initialValue.last_messenger,
        contact_id: initialValue?.contact
          ? initialValue?.contact?.last_name +
            ' ' +
            initialValue?.contact?.name +
            ' ' +
            initialValue?.contact?.first_name
          : null,
        inn: initialValue?.inn,
      });
    } else {
      form.setFieldsValue(defaultValueContractor);
    }
  }, [form, isOpen]);

  const showDrawerInn = () => {
    if (initialValue?.id) {
      dispatch(getContractorsRequisites(initialValue?.id)).then((res) => {
        if (res?.payload.length) {
          setRequisites(
            res.payload[0]?.requisites?.length
              ? res.payload[0]?.requisites
              : null,
          );
          setDataBankRequisites(res.payload[0]?.bank || []);
        }
      }).catch((e) => {
        console.error(e);
      });
    }
    setIsOpenRequisites(true);
  };

  const onClose = () => {
    setIsOpenRequisites(false);
  };

  const onCloseForm = () => {
    if (onCloseEdit) {
      onCloseEdit();
    }
    setRequisites([]);
  };

  const handleSearchContact = async (payload: any) => {
    if (payload.length > 2) {
      const result: any = await dispatch(findContact(payload));
      const jsResult = JSON.parse(result.payload);
      if (jsResult.success > 0) {
        setContact(jsResult.data);
      }
    }
  };

  const handleSelectContact = (val: string, record: any) => {
    setFinContact([record]);
    setValueContact(record.label);
  };

  const funStorage = async (data: any) => {
    return new Promise(async (resolve, reject) => {
      const arResult: DataType[] = [];
      if (!data?.suggestions?.length) return null;
      data?.suggestions?.forEach((el: any, i: number) => {
        let address = el?.data?.address?.data;
        arResult.push({
          key: `${1}-${i}`,
          name: 'Компания',
          meaning: el?.value ?? '',
        });
        arResult.push({
          key: `${2}-${i}`,
          name: 'Полное наименование организации',
          meaning: el?.data?.name?.full_with_opf ?? '',
        });
        arResult.push({
          key: `${3}-${i}`,
          name: 'ИНН',
          meaning: el?.data?.inn ?? '',
        });
        arResult.push({
          key: `${4}-${i}`,
          name: 'КПП',
          meaning: el?.data?.kpp ?? '',
        });
        arResult.push({
          key: `${5}-${i}`,
          name: 'ОГРН (Дата)',
          meaning:
            el?.data?.ogrn +
            ' (' +
            getDateString({
              date: el?.data?.ogrn_date,
              formatDate: 'DD.MM.YYYY',
            }) +
            ')',
        });
        arResult.push({
          key: `${6}-${i}`,
          name: 'Адрес',
          meaning: el?.data?.address.value ?? '',
        });
        arResult.push({
          key: `${7}-${i}`,
          name: 'Дата государственной регистрации',
          meaning: getDateString({
            date: el?.data?.state?.registration_date,
            formatDate: 'DD.MM.YYYY',
          }),
        });
        arResult.push({
          key: `${8}-${i}`,
          name: 'ОКПО',
          meaning: el?.data?.okpo ?? '',
        });
        arResult.push({
          key: `${9}-${i}`,
          name: 'ОКТМО',
          meaning: el?.data?.oktmo ?? '',
        });
        arResult.push({
          key: `${10}-${i}`,
          name: 'Ген. директор',
          meaning: el?.data?.management?.name ?? '',
        });
        arResult.push({
          key: `${11}-${i}`,
          name: 'Короткий адрес',
          meaning: `${address?.city ? 'г.' + address.city : ''} ${
            address?.street_with_type ? address?.street_with_type : ''
          } ${address?.house ? 'д.' + address?.house : ''}`,
        });
      });
      resolve(arResult);
    });
  };

  const handleSearchInn = (value?: string) => {
    const currentValue = value?.replace(/\s+/gi, '') || '';
    const reg = /^-?\d*(\.\d*)?$/;
    if (currentValue?.length >= 4 && reg.test(currentValue)) {
      Utils.requisites(currentValue).then((res) => {
        const result = Utils.checkIsJson(res);
        if (result?.suggestions?.length) {
          const nameInn = result.suggestions[0]?.value;
          const inn = result.suggestions[0]?.data?.inn;
          const addressInn = result.suggestions[0]?.data?.address?.value;
          setDataInn([
            {
              value: inn,
              label: nameInn + ' ' + addressInn,
              result,
            },
          ]);
        } else {
          setRequisites(null);
          setDataInn([]);
          setDetailInn(false);
        }
      }).catch((e) => {
        console.error(e);
      });
      return;
    }
    setRequisites(null);
    setDataInn([]);
    setDetailInn(false);
  };

  const handleBlurInn = (evt: any) => {
    const input = evt.target as HTMLInputElement;
    const currentValue = input?.value?.replace(/\s+|\D/gi, '') || '';
    form.setFieldValue('inn', currentValue);
  };

  const handleSelectInn = (value: string, opt: any) => {
    const result = opt?.result;
    form.setFieldValue('inn', value);
    if (value) {
      const address = result.suggestions[0]?.data?.address?.unrestricted_value;
      const phone = result.suggestions[0]?.data?.phones?.[0]?.value;
      const email = result.suggestions[0]?.data?.emails?.[0]?.value;
      const name = result.suggestions[0]?.value;
      const full_name = result.suggestions[0]?.unrestricted_value;
      form.setFieldValue('internal_company', name);
      form.setFieldValue('name_company', full_name);
      form.setFieldValue('address', address);
      form.setFieldValue('email_first', email);
      form.setFieldValue('phone_first', phone);
      Utils.requisitesBank(value).then((responseBank) => {
        console.log('responseBank', responseBank);
      });
      funStorage(result).then((res: any) => {
        setRequisites(res);
        setDetailInn(true);
      }).catch((e) => {
        console.error(e);
      });
    }
  };

  const fillRequisitesInn = () => {
    const inn = form.getFieldValue('inn');
    const currentValue = inn?.replace(/\s+/gi, '') || '';
    const reg = /^-?\d*(\.\d*)?$/;
    if (currentValue?.length >= 4 && reg.test(currentValue)) {
      setIsLoadingRequisites(true);
      Utils.requisites(currentValue)
        .then((res) => {
          const result = Utils.checkIsJson(res);
          funStorage(result).then((res: any) => {
            setRequisites(res);
            setDetailInn(true);
          });
        }).catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsLoadingRequisites(false);
        });
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
  };

  const handleSearchCity = async (payload: any) => {
    if (payload !== '') {
      const arResultYandex = await Utils.yandexCity(payload);
      setCity(mapperSearchYandexLocation(arResultYandex));
    }
  };

  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const result: any = await dispatch(
      findExecutor(event.target.value.toLocaleLowerCase()),
    );
    if (result.payload.length > 0) {
      setDataExecutor(result.payload);
    }
  };

  const addFormResponsible = () => {
    setResponsible(responsibleChange.name);
    setIsModalOpen(false);
    setResponsibleChange(null);
    setSearchValue('');
  };

  const handleAddResponsible = (e: any, option: {label: string, value: number, avatar: string}, parent: any) => {

    const resp = {
      avatar: option.avatar,
      name: option.label,
      id: option.value,
    };
    setResponsibleChange(resp);
    setArrResponsible(resp.id);
    setDataExecutor([]);
    setSearchValue(option.label);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (data: any) => {
    Object.assign(data, {
      type_company: typeCompany,
      type_source: typeSource,
      id: initialValue?.id || null,
      user_id: Utils.getUserInfo().id,
      contact_id:
        finContact.length > 0
          ? finContact[0]?.id
          : initialValue?.contact?.value,
      requisites: {
        requisites: requisites?.length ? requisites : null,
        bank: dataBankRequisites?.length ? dataBankRequisites : null,
        contractors_id: initialValue.id,
      },
      requisites_id: initialValue?.requisites?.id,
      responsible_id: arrResponsible ? arrResponsible : Utils.getUserInfo().id,
    });
    if (onFinishEdit) {
      onFinishEdit({ ...initialValue, ...data });
    }
  };

  const addItemList = ({
    name,
    itemDefault,
  }: {
    name: string;
    itemDefault: any;
  }) => {
    const arr: any[] = form.getFieldValue(name);
    if (arr?.length) {
      form.setFieldValue(name, [...arr, itemDefault]);
    } else {
      form.setFieldValue(name, [itemDefault]);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        onFinish={onFinish}
        initialValues={defaultValueContractor}
      >
        <Flex justify={'space-between'} className={'drawer-header'}>
          <Flex gap={20} justify={'center'} align={'center'}>
            <div className={'drawer-title'}>{title}</div>
          </Flex>
          <Flex gap={5} align={'center'}>
            {!!initialValue?.id && (
              <IconButton iType="view-form" onClick={onEdit} />
            )}
            {!!initialValue?.id && isCopy && (
              <CopyButton
                text={link}
                textSuccess={t('Ссылка скопирована')}
                icon={<Icon component={CopyBufferIconDefault} />}
              />
            )}
          </Flex>
        </Flex>
        <Flex className={'content--drawer'} gap={10}>
          <Flex vertical gap={8} style={{ width: '100%' }}>
            <Form.Item
              label={t('О компании')}
              name="name_company"
              rules={[
                {
                  required: true,
                  message: t('Введите название компании'),
                  whitespace: true,
                },
                // {
                //   message: t('Нельзя использовать спец символы и кавычки'),
                //   whitespace: true,
                //   validator: (_, val) => {
                //     if (regNoQuotesSymbol.test(val)) {
                //       return Promise.reject();
                //     } else {
                //       return Promise.resolve();
                //     }
                //   },
                // },
                {
                  message: t('Название компании должно быть с буквами'),
                  whitespace: true,
                  validator: (_, val) => {
                    if (/\D+/.test(val)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject();
                    }
                  },
                },
              ]}
            >
              <Input allowClear placeholder={t('Название компании')} />
            </Form.Item>
            <Form.Item name="inn">
              <AutoComplete
                allowClear
                showSearch
                placeholder={t('Заполнить ИНН')}
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={handleSearchInn}
                onChange={(v) => setSearchValue(v)}
                onBlur={handleBlurInn}
                onSelect={handleSelectInn}
                onKeyDown={keyHandlerInputNumber}
                notFoundContent={null}
                disabled={!!initialValue?.id && initialValue?.inn}
                options={dataInn?.map((d: any) => ({
                  value: d?.value,
                  label: d?.label,
                  ...d,
                }))}
              />
            </Form.Item>
            <Form.Item
              name="internal_company"
              rules={[
                {
                  required: true,
                  message: t('Введите короткое название компании'),
                  whitespace: true,
                },
              ]}
            >
              <Input
                allowClear
                placeholder={t('Внутреннее название компании')}
              />
            </Form.Item>

            <Form.Item
              name="type_company"
              rules={[{ required: true, message: t('Выберите тип компании') }]}
            >
              <Select
                showSearch
                placeholder={t('Тип компании')}
                options={dataTypeCompany}
                onChange={(e) => setTypeCompany(e)}
              />
            </Form.Item>
            <Form.Item
              name="type_source"
              rules={[{ required: true, message: t('Выберите источник') }]}
            >
              <Select
                showSearch
                placeholder={t('Источник')}
                options={dataSourceContact}
                onChange={(e) => setTypeSource(e)}
              />
            </Form.Item>

            {/* Телефон*/}
            <Flex gap={12} flex={1}>
              <Col style={{ width: '70%' }}>
                <Form.Item
                  name="phone_first"
                  rules={[
                    {
                      required: true,
                      message: t('Обязательное поле!'),
                    },
                  ]}
                >
                  <PhoneInput
                    autoFormat={false}
                    defaultMask={'00000000000000'}
                    placeholder={t('Номер телефона')}
                    prefix="+"
                    enableSearch
                    searchPlaceholder={t('Поиск')}
                    searchNotFound={t('ничего не нашлось')}
                    country={lang === 'ru' ? lang : 'us'}
                    localization={lang === 'ru' ? ru : undefined}
                  />
                </Form.Item>
              </Col>
              <Col style={{ width: '30%' }}>
                <Form.Item
                  name="type_phone_first"
                  rules={[
                    {
                      required: true,
                      message: t('Обязательное поле!'),
                    },
                  ]}
                >
                  <Select
                    placeholder={t('Рабочий')}
                    showSearch
                    options={dataTypePhone}
                  />
                </Form.Item>
              </Col>
              <Flex gap={5} style={{ minWidth: '40px' }}>
                <Flex
                  gap={5}
                  align="start"
                  style={{ paddingTop: '8px' }}
                  hidden={!!lastPhoneList?.length}
                >
                  <PlusOutlined
                    style={{
                      fontSize: '16px',
                      color: '#00B288',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      addItemList({
                        name: 'last_phone',
                        itemDefault: defaultItemLastPhoneList,
                      })
                    }
                  />
                </Flex>
              </Flex>
            </Flex>
            <Form.List name="last_phone">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Flex gap={12} key={key}>
                      <Col style={{ width: '70%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_phone']}
                          rules={[
                            {
                              required: true,
                              message: t('Обязательное поле!'),
                            },
                          ]}
                        >
                          <PhoneInput
                            autoFormat={false}
                            defaultMask={'00000000000000'}
                            placeholder={t('Номер телефона')}
                            prefix="+"
                            enableSearch
                            searchPlaceholder={t('Поиск')}
                            searchNotFound={t('ничего не нашлось')}
                            country={lang === 'ru' ? lang : 'us'}
                            localization={lang === 'ru' ? ru : undefined}
                          />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: '30%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_type_phone']}
                          rules={[
                            {
                              required: true,
                              message: t('Обязательное поле!'),
                            },
                          ]}
                        >
                          <Select
                            placeholder={t('Рабочий')}
                            showSearch
                            options={dataTypePhone}
                            optionFilterProp="children"
                          />
                        </Form.Item>
                      </Col>
                      <Flex gap={5} align="start" style={{ paddingTop: '8px' }}>
                        <DeleteOutlined
                          onClick={() => remove(name)}
                          style={{
                            fontSize: '16px',
                            color: '#D91528',
                            cursor: 'pointer',
                          }}
                        />
                        <PlusOutlined
                          style={{
                            fontSize: '16px',
                            color: '#00B288',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            add(defaultItemLastPhoneList, name + 1)
                          }
                        />
                      </Flex>
                    </Flex>
                  ))}
                </>
              )}
            </Form.List>

            {/* email*/}
            <Flex gap={12}>
              <Form.Item
                name="email_first"
                style={{ width: '70%' }}
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: t('Обязательное поле!'),
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="e-mail" />
              </Form.Item>
              <Form.Item
                name="type_email_first"
                style={{ width: '30%' }}
                rules={[{ required: true, message: t('Обязательное поле!') }]}
              >
                <Select
                  placeholder={t('Рабочий')}
                  showSearch
                  options={dataTypeEmail}
                />
              </Form.Item>
              <Flex gap={5} style={{ minWidth: '40px' }}>
                <Flex
                  gap={5}
                  align="start"
                  style={{ paddingTop: '8px' }}
                  hidden={!!lastEmailList?.length}
                >
                  <PlusOutlined
                    style={{
                      fontSize: '16px',
                      color: '#00B288',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      addItemList({
                        name: 'last_email',
                        itemDefault: defaultItemLastEmailList,
                      })
                    }
                  />
                </Flex>
              </Flex>
            </Flex>
            <Form.List name="last_email">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Flex gap={12} key={key}>
                      <Col style={{ width: '70%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_email']}
                          rules={[
                            {
                              type: 'email',
                              required: true,
                              message: t('Обязательное поле!'),
                              whitespace: true,
                            },
                          ]}
                        >
                          <Input placeholder="e-mail" />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: '30%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_type_email']}
                          rules={[
                            {
                              required: true,
                              message: t('Обязательное поле!'),
                            },
                          ]}
                        >
                          <Select
                            placeholder={t('Рабочий')}
                            showSearch
                            options={dataTypeEmail}
                            optionFilterProp="children"
                          />
                        </Form.Item>
                      </Col>
                      <Flex gap={5} align="start" style={{ paddingTop: '8px' }}>
                        <DeleteOutlined
                          onClick={() => remove(name)}
                          style={{
                            fontSize: '16px',
                            color: '#D91528',
                            cursor: 'pointer',
                          }}
                        />
                        <PlusOutlined
                          style={{
                            fontSize: '16px',
                            color: '#00B288',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            add(defaultItemLastEmailList, name + 1)
                          }
                        />
                      </Flex>
                    </Flex>
                  ))}
                </>
              )}
            </Form.List>

            {/* Мессенджер*/}
            <Flex gap={12}>
              <Form.Item name="messenger_first" style={{ width: '70%' }}>
                <Input placeholder={t('Мессенджер')} />
              </Form.Item>
              <Form.Item name="type_messenger_first" style={{ width: '30%' }}>
                <Select
                  placeholder={t('Рабочий')}
                  showSearch
                  options={dataTypeMessenger}
                />
              </Form.Item>
              <Flex gap={5} style={{ minWidth: '40px' }}>
                <Flex
                  gap={5}
                  align="start"
                  style={{ paddingTop: '8px' }}
                  hidden={!!lastMessengerList?.length}
                >
                  <PlusOutlined
                    style={{
                      fontSize: '16px',
                      color: '#00B288',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      addItemList({
                        name: 'last_messenger',
                        itemDefault: defaultItemMessengerList,
                      })
                    }
                  />
                </Flex>
              </Flex>
            </Flex>
            <Form.List name="last_messenger">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Flex gap={12} key={key}>
                      <Col style={{ width: '70%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_messenger']}
                          rules={[
                            {
                              required: true,
                              message: t('Обязательное поле!'),
                            },
                          ]}
                        >
                          <Input placeholder={t('Мессенджер')} />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: '30%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'add_type_messenger']}
                          rules={[
                            {
                              required: true,
                              message: t('Обязательное поле!'),
                            },
                          ]}
                        >
                          <Select
                            placeholder={t('Рабочий')}
                            showSearch
                            options={dataTypeMessenger}
                          />
                        </Form.Item>
                      </Col>
                      <Flex gap={5} align="start" style={{ paddingTop: '8px' }}>
                        <DeleteOutlined
                          onClick={() => remove(name)}
                          style={{
                            fontSize: '16px',
                            color: '#D91528',
                            cursor: 'pointer',
                          }}
                        />
                        <PlusOutlined
                          style={{
                            fontSize: '16px',
                            color: '#00B288',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            add(defaultItemMessengerList, name + 1)
                          }
                        />
                      </Flex>
                    </Flex>
                  ))}
                </>
              )}
            </Form.List>

            <Divider style={{ marginBottom: 6, marginTop: 6 }} />
            <Form.Item label={t('Контакт')} name="contact_id">
              <AutoComplete
                showSearch
                allowClear
                value={valueContact}
                placeholder={t('Поиск(имя, телефон, или e-mail)')}
                onSearch={handleSearchContact}
                onSelect={handleSelectContact}
                options={(dataContact || []).map((d: any) => ({
                  value: d.label,
                  label: d.label,
                  id: d.value,
                  phone: d.contact_phone,
                  email: d.contact_email,
                }))}
              />
            </Form.Item>
            <Divider style={{ marginBottom: 6, marginTop: 6 }} />
            <Form.Item label={t('Реквизиты')} name="address">
              <Select
                allowClear
                showSearch
                placeholder="Адрес"
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={handleSearchCity}
                notFoundContent={null}
                options={(dataCity || []).map((d: any) => ({
                  value: d?.value,
                  label: d?.label,
                }))}
              />
            </Form.Item>
            <Flex vertical align={'flex-end'} style={{ width: '100%' }}>
              <Button
                type="link"
                disabled={!detailInn}
                onClick={showDrawerInn}
                style={
                  detailInn || !!initialValue?.id
                    ? { color: '#05F', cursor: 'pointer', padding: 0 }
                    : { padding: 0 }
                }
              >
                {t('Подробно')}
              </Button>
            </Flex>
            <Divider style={{ marginBottom: 6, marginTop: 6 }} />
            <Flex gap={6} vertical>
              <Title level={4} style={{ marginBottom: 0 }}>
                {t('Дополнительно')}
              </Title>
              {/* Старый блок заменить на общий */}
              <Text style={{ marginTop: 0 }}>{t('Ответственный')}</Text>
              <Flex
                justify={'space-between'}
                align={'center'}
                style={{ paddingRight: 7 }}
              >
                <Flex gap={6} align={'center'}>
                  <Avatar size={28} icon={<UserOutlined />} />
                  <Form.Item name={'user_fio'} noStyle />
                  <Form.Item>
                    {!!responsible && (
                      <Text style={{ marginTop: 0, color: '#05F' }}>
                        {responsible}
                      </Text>
                    )}
                  </Form.Item>
                </Flex>
                <Text
                  style={{ marginTop: 0, color: '#05F', cursor: 'pointer' }}
                  onClick={showModal}
                >
                  {responsible ? t('Сменить') : t('Добавить')}
                </Text>
              </Flex>
            </Flex>
            <Flex vertical style={{ marginTop: 6 }}>
              <Title level={4} style={{ marginBottom: 6 }}>
                {t('Комментарий')}
              </Title>
              <Form.Item name="comment">
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 20 }} />
              </Form.Item>
            </Flex>
            <Flex gap={8} style={{ marginTop: 10 }}>
              <Button
                type={'primary'}
                htmlType="submit"
                style={{ width: '70%' }}
              >
                {t('Сохранить изменения')}
              </Button>
              <Button
                onClick={() => setConfirmation(true)}
                type={'text'}
                style={{ width: '30%', color: '#E14453' }}
              >
                {t('Отменить')}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Form>

      <CustomDrawer
        open={isOpenRequisites}
        title={t('Организация')}
        onClose={onClose}
        isHiddenClose
      >
        <FormRequisites
          isOpen={isOpenRequisites}
          requisites={requisites || defaultRequisites}
          setDataBankRequisites={setDataBankRequisites}
          dataBankRequisites={dataBankRequisites}
          setRequisites={setRequisites}
          onClose={onClose}
          title={t('Организация')}
          fillRequisitesInn={fillRequisitesInn}
          isLoadingRequisites={isLoadingRequisites}
        />
      </CustomDrawer>
      <Modal
        title={t('Сменить ответственного')}
        open={isModalOpen}
        onCancel={handleCancelModal}
        keyboard={false}
        footer={null}
      >
        <Flex vertical style={{ height: '50vh', padding: 20 }}>
          <SelectSearch
            defaults={searchValue}
            dataSource={dataExecutor}
            onChangeSelect={handleAddResponsible}
            dropdownRender={onNameChange}
            style={{ width: '100%' }}
            placeholder={t('Ответственный')}
            hiddenBtn={false}
          />
          <Flex
            gap={8}
            style={{ position: 'absolute', bottom: 28, width: '100%' }}
          >
            <Button
              type={'primary'}
              style={{ width: '56%' }}
              onClick={addFormResponsible}
            >
              {t('Добавить')}
            </Button>
            <Button
              type={'text'}
              style={{ width: '30%', color: '#E14453' }}
              onClick={() => setConfirmation(true)}
            >
              {t('Отменить')}
            </Button>
          </Flex>
        </Flex>
      </Modal>
      <ModalConfirm
        title={warningTitle}
        subtitle={subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={isModalOpen ? handleCancel : onCloseForm}
      />
    </>
  );
};

export default FormEditContractors;
