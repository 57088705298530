import React, { useEffect, useMemo } from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  Select,
  Typography,
} from "antd";

import TabContainers from "./tabs/edit/TabContainers";
import { messagesModal, RENT_DEAL } from "../../../../constants";
import {
  TAB_DEAL,
  TAB_CONTAINERS,
  noStyleField,
  TAB_EXPENSES,
  dealNames,
  expensesNames,
} from "../constants";
import { RootState, useStoreDispatch } from "../../../../store";
import { ITab, ITabs } from "../../../../components/ui/Tabs/Tabs";
import ModalConfirm from "../../../../components/ui/ModalConfirm/ModalConfirm";
import TooltipButton from "../../../../components/ui/TooltipButton/TooltipButton";
import {
  defaultLocationReturn,
  defaultRelease,
  defaultValuesDeal,
  IDeal,
  IFormDeal,
  rentTypeId,
  setDeals,
  updateDeals,
} from "../../../../store/rent";
import TabDeal from "./tabs/edit/TabDeal";
import style from "../style.module.scss";
import { Utils } from "../../../../utils";
import { TabExpenses } from "./tabs/edit/TabExpenses";
import { Rest_files } from "../../../../services/rest_files";
import { ITypeDeal } from "../../../../types";
import { getContainerSubmit } from "../utils";
import { CopyBufferIconDefault } from "../../../../assets/image/svg";
import CopyButton from "../../../../components/ui/CopyButton/CopyButton";
import { useLink } from "../../../../hooks";
import IconButton from "../../../../components/ui/IconButton";
import Icon from "@ant-design/icons";

//для перехода из запроса в сделку
interface LocationState {
  tableName?: string;
  dealId?: string;
}

const FormDeal: React.FC<IFormDeal> = ({
  onRelease,
  onClose,
  onEdit,
  onView,
  isOpen,
  isCopy,
  initialValue,
  disabled,
  title = "Редактировать сделку",
  isFromRequest = false,
}) => {
  const link = useLink({ key: RENT_DEAL, id: initialValue?.id as number });
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useStoreDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [activeKey, setActiveKey] = useState(() => TAB_DEAL);

  const [confirmation, setConfirmation] = React.useState<boolean>(false);

  const [isErrorsDeal, setIsErrorsDeal] = React.useState(false);

  const [isErrorsExpenses, setIsErrorsExpenses] = React.useState(false);

  const isEditDeal = useSelector<RootState>((state) => state.rent.isEditDeal);

  const isEditDealContainers = useSelector<RootState>(
    (state) => state.rent.isEditDealContainers
  ) as boolean;

  const dealType = useSelector<RootState>(
    (state) => state.types.dataTypeDeal
  ) as ITypeDeal[];

  const isDisabled = !!initialValue?.id;

  const formTitle = isFromRequest ? "Создать сделку" : title;

  const formDisabled = isFromRequest ? false : disabled;

  useEffect(() => {
    return () => {
      setIsErrorsDeal(false);
      setIsErrorsExpenses(false);
      form.resetFields();
    };
  }, []);

  useEffect(() => {
    if (isDisabled) {
      const location_return = initialValue?.location_return?.length
        ? initialValue.location_return
        : defaultLocationReturn;
      const releases = initialValue?.releases?.length
        ? initialValue.releases
        : defaultRelease;

      form.setFieldsValue({
        ...defaultValuesDeal,
        ...initialValue,
        location_return,
        releases,
      });
    } else {
      const initUser = {
        avatar: Utils.getUserInfo().avatar,
        name: Utils.getUserInfo().fio,
        id: Utils.getUserInfo().id,
        isResponsible: true,
      };

      const responsibleInfo = {
        responsible_id: initUser.id,
        responsible_name: initUser.name,
      };
      form.setFieldsValue({
        ...defaultValuesDeal,
        ...initialValue, // для создания из другого модуля
        ...responsibleInfo,
      });
    }
  }, [isDisabled, isOpen]);

  // useEffect(() => {
  //   if (initialValue?.type_deal_id) {
  //     form.setFieldsValue({
  //       ...defaultValuesDeal,
  //       ...initialValue,
  //     });
  //   }
  // }, [initialValue, form]);

  // useEffect(() => {
  //   if (isFromRequest && initialValue) {
  //     form.setFieldsValue({
  //       ...defaultValuesDeal,
  //       ...initialValue, // Устанавливаем значения из запроса аренды
  //     });
  //   }
  // }, [isFromRequest, initialValue, form]);

  useEffect(() => {
    if (isFromRequest) {
      const initUser = {
        avatar: Utils.getUserInfo().avatar,
        name: Utils.getUserInfo().fio,
        id: Utils.getUserInfo().id,
        isResponsible: true,
      };

      const responsibleInfo = {
        responsible_id: initUser.id,
        responsible_name: initUser.name,
      };

      form.setFieldsValue({
        ...defaultValuesDeal,
        ...initialValue,
        ...responsibleInfo,
      });
    }
    // else if (initialValue?.id) {
    //   form.setFieldsValue({
    //     ...defaultValuesDeal,
    //     ...initialValue,
    //   });
    // }
  }, [isFromRequest]);

  const tabs: ITab[] = useMemo(
    () => [
      {
        key: TAB_DEAL,
        label: "Сделка",
        forceRender: true,
        isError: isErrorsDeal,
        disabledText: isEditDealContainers ? "Завершите редактирование" : "",
        disabled: isEditDealContainers,
        content: (
          <TabDeal
            initialValue={initialValue}
            isDisabled={disabled}
            isFromRequest={isFromRequest}
          />
        ),
      },
      {
        key: TAB_CONTAINERS,
        label: "Контейнеры",
        forceRender: true,
        content: (
          <TabContainers
            initialValue={initialValue}
            isDisabled={disabled}
            isFromRequest={isFromRequest}
          />
        ),
      },
      {
        key: TAB_EXPENSES,
        label: "Доп.Расходы",
        isError: isErrorsExpenses,
        forceRender: true,
        content: (
          <TabExpenses
            initialValue={initialValue}
            isDisabled={disabled}
            isFromRequest={isFromRequest}
          />
        ),
      },
    ],
    [
      isErrorsDeal,
      isErrorsExpenses,
      form,
      isEditDeal,
      isEditDealContainers,
      disabled,
      isFromRequest,
    ]
  );

  const onSubmit = async (values: IDeal) => {
    try {
      setIsLoading(true);
      // не забыть сохранить новые файлы releases
      values.releases = values?.releases?.length
        ? values?.releases.filter((re) => re?.release_id && re?.id)
        : [];
      values.location_return = values?.location_return?.length
        ? values?.location_return.filter((re) => re?.location)
        : [];
      if (values?.releases?.length) {
        for (const release of values.releases) {
          if (release?.saveFiles?.length) {
            for (const file of release?.saveFiles) {
              const res = await Rest_files.submitFile(file);
              if (res.data) {
                release.ids.push(res.data);
              }
            }
            delete release.saveFiles;
          }
        }
      }

      values.containers = values?.containers?.map(getContainerSubmit);
      const submit = {
        ...initialValue,
        ...values,
      };
      console.log(submit);

      dispatch(initialValue?.id ? updateDeals(submit) : setDeals(submit));

      setIsLoading(false);
      handleClose(true);
      form.resetFields();
    } catch (err) {
      console.error("submit", err);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdate = async (changedValues: IDeal, allValues: IDeal) => {
    // console.log(allValues);

    setIsErrorsDeal(false);
    setIsErrorsExpenses(false);
    return;
  };

  const onFinishError = async ({ values, errorFields, outOfDate }: any) => {
    if (errorFields) {
      for (const errors of errorFields) {
        const title = errors?.name?.[0];

        if (dealNames.includes(title)) {
          setIsErrorsDeal(true);
        }
        if (expensesNames.includes(title)) {
          setIsErrorsExpenses(true);
        }
        if (isErrorsDeal && isErrorsExpenses) {
          break;
        }
      }
    }

    return;
  };

  const handleClose = (isClear?: boolean) => {
    if (onClose) {
      onClose(isClear);
    }
    form.resetFields();
  };

  const changeClickTab = (key: string) => {
    setActiveKey(key);
  };

  const selectedType = (val: string | null, opt: any) => {
    form.setFieldsValue({
      type_deal: opt?.label ?? "",
      type_deal_char: opt?.char || "",
    });
  };

  //дляперехода из запроса аренды
  const filteredOptions = dealType
    ?.filter(({ status_containers_id }) => status_containers_id === rentTypeId)
    ?.map(({ id, value, char }) => ({
      value: id,
      label: value,
      char,
    }));

  return (
    <>
      <Form
        // disabled={disabled}
        disabled={formDisabled}
        className={style["tabs-form"]}
        autoComplete="off"
        noValidate
        layout="vertical"
        form={form}
        initialValues={defaultValuesDeal}
        scrollToFirstError
        onFinish={onSubmit}
        onFinishFailed={onFinishError}
        onValuesChange={onUpdate}
      >
        {noStyleField.map((name) => (
          <Form.Item key={name} name={name} noStyle />
        ))}
        <Flex vertical gap={10}>
          <Flex justify="end">
            <Flex gap={5} align={"center"}>
              <IconButton
                hidden={!onView || disabled || !isDisabled}
                disabled={!isDisabled}
                iType="view-form"
                onClick={onView}
              />
              <IconButton
                hidden={
                  !!initialValue?.delete ||
                  !onEdit ||
                  !initialValue?.edit ||
                  !disabled
                }
                disabled={!isDisabled}
                iType="edit-form"
                onClick={onEdit}
              />
              {isDisabled && isCopy && (
                <CopyButton
                  text={link}
                  textSuccess={t("Ссылка скопирована")}
                  icon={<Icon component={CopyBufferIconDefault} />}
                  disabled={!isDisabled}
                />
              )}
            </Flex>
          </Flex>
          <Flex justify="space-between" align="center">
            <Flex gap={10} align="center">
              <Typography.Text className={style.title}>
                {disabled ? initialValue.id_deal : t(title ?? "")}
              </Typography.Text>
            </Flex>
            <Flex justify="space-between" align="center" gap={10}>
              <Form.Item
                name={"type_deal_id"}
                rules={[{ required: true, message: t("Обязательное поле") }]}
                style={{ margin: 0, width: "130px" }}
              >
                <Select
                  placeholder={t("Тип сделки")}
                  optionLabelProp="label"
                  options={dealType
                    ?.map(({ id, value, char, status_containers_id }) => ({
                      value: id,
                      label: value,
                      char,
                      status_containers_id,
                    }))
                    ?.filter(
                      ({ status_containers_id }) =>
                        status_containers_id === rentTypeId
                    )}
                  disabled={isDisabled}
                  onSelect={selectedType}
                />
              </Form.Item>
              <Button
                type="default"
                onClick={onRelease}
                style={{ margin: 0, width: "130px" }}
              >
                {t("Создать релиз")}
              </Button>
              <Form.Item name={"id_deal"} style={{ margin: 0, width: "130px" }}>
                <Input placeholder={"ID сделки"} allowClear disabled />
              </Form.Item>
            </Flex>
          </Flex>

          <Divider style={{ margin: 0 }} />
        </Flex>
        <ITabs
          className={style["tabs-form__tab"]}
          isErrorTabs
          activeKey={activeKey}
          tabs={tabs}
          onChange={changeClickTab}
        />
        <Form.Item hidden={disabled}>
          <Flex gap={50} justify="end" style={{ margin: "32px 0" }}>
            <TooltipButton
              propsTooltip={{
                title: isEditDealContainers
                  ? "Завершите редактирование"
                  : "Добавить",
              }}
              propsButton={{
                type: "primary",
                htmlType: "submit",
                style: { maxWidth: "290px", minWidth: "290px" },
                disabled: isLoading || isEditDealContainers,
              }}
            >
              {isLoading
                ? "..." + t("Сохранение")
                : initialValue?.id
                ? t("Сохранить изменения")
                : t("Добавить")}
            </TooltipButton>
            <Button
              onClick={() => setConfirmation(true)}
              type={"text"}
              style={{ color: "#E14453", maxWidth: "290px", minWidth: "290px" }}
            >
              Отменить
            </Button>
          </Flex>
        </Form.Item>
        {disabled && (
          <Flex
            gap={8}
            justify="space-between"
            style={{ margin: "32px 32px 32px auto", maxWidth: "500px" }}
          >
            <TooltipButton
              propsButton={{
                onClick: () => {
                  if (onClose) {
                    onClose();
                  }
                },
                type: "text",
                style: {
                  maxWidth: "290px",
                  minWidth: "290px",
                  color: "#E14453",
                },
                disabled: !disabled,
              }}
            >
              Закрыть
            </TooltipButton>
          </Flex>
        )}
      </Form>
      <ModalConfirm
        title={messagesModal.warning.title}
        subtitle={messagesModal.warning.subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={handleClose}
      />
    </>
  );
};

export default FormDeal;
