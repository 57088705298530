import React, { useState, useMemo, useEffect } from "react";
import { Form, Button, Flex, App, message as popMessage } from "antd";
import {
  BASE_TAB,
  APPROVAL_TAB,
  SIGNING_TAB,
  RELEASE_TAB,
  CHAT_TAB,
} from "../constants";
import RequestTab from "./tabs/RequestTab";
import ApprovalTab from "./tabs/ApprovalTab";
import SigningTab from "./tabs/SigningTab";
import ReleaseTab from "./tabs/ReleaseTab";
import ChatTab from "./tabs/ChatTab";
import ModalConfirm from "../../../../components/ui/ModalConfirm/ModalConfirm";
import { messagesModal } from "../../../../constants";
import { ITab, ITabs } from "../../../../components/ui/Tabs/Tabs";
import styles from "./style.module.scss";
import { setOneRequest, findOneRequest } from "../../../../store/rent";
import {
  IRequest,
  IDeal,
  setEditRequest,
  setOneDeal,
  defaultValuesDeal,
} from "../../../../store/rent";
import { Rest_rent } from "../../../../services/rest_rent";
import { useSelector } from "react-redux";
import { RootState, useStoreDispatch } from "../../../../store";
import { useParamsHistory } from "../../../../hooks";
import { CREATE, EDIT } from "../../../../helpers/string-helpers";
import { useTranslation } from "react-i18next";
import { Utils } from "../../../../utils";
import { FormDeal } from "../../deals/components";
import { resetStoreRent } from "../../../../store/rent";
import CustomDrawer from "../../../../components/CustomDrawer/CustomDrawer";
import { RouteNames } from "../../../../router/names";
import { RELEASE } from "../../../../constants";
import { defaultLocationReturn } from "../../../../store/rent";
import { getUserPermissions } from "../../../../store/users";
import dayjs from "dayjs";
import { error } from "console";

const { title: warningTitle, subtitle } = messagesModal.warning;

interface Props {
  onFinishAddTerminal?: (data: any, open: boolean) => void | undefined;
  setIsModalOpenForm?: (
    value?: ((prevState: boolean) => boolean) | boolean
  ) => void;
  form?: any;
  title?: string;
  isOpen?: boolean;
  initialValue: any;
  onClose?: (isClear?: boolean) => void;
  onFinish?: (requestData: any, action: string) => void;
  onEdit?: () => void;
  typeForm?: string;
  disabled?: boolean;
}

const FormModalRentRequest: React.FC<Props> = ({
  form: externalForm,
  initialValue,
  isOpen,
  onClose,
  onFinish,
  onEdit,
  title = "Редактировать запрос",
  typeForm,
  disabled,
}) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const dispatch = useStoreDispatch();

  // Если initialValue.delete === true — «только чтение».
  // В этом режиме вкладки не отключаем, но поля будут недоступны для редактирования.
  const isViewMode = Boolean(initialValue?.delete);

  const [form] = Form.useForm(externalForm);
  const [currentUser, setCurrentUser] = useState(Utils.getUserInfo().id);
  const [isErrorsBase, setIsErrorsBase] = useState<boolean | undefined>(false);
  const [activeKey, setActiveKey] = useState(() => BASE_TAB);
  const [tableName, setTableName] = useState<string>("");
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [data, setData] = useState(null);
  const [openDrawerDeal, setOpenDrawerDeal] = useState<boolean>(false);

  const [selectedRowsPurchase, setSelectedRowsPurchase] = useState<Set<number>>(
    new Set()
  );
  const [selectedRowsSublease, setSelectedRowsSublease] = useState<Set<number>>(
    new Set()
  );
  const [selectedRowsKtk, setSelectedRowsKtk] = useState<Set<number>>(
    new Set()
  );
  const isDisabled = !!initialValue?.id;
  const id = useMemo(() => {
    const pathSegments = window.location.pathname.split("/");
    return pathSegments[pathSegments.length - 1];
  }, [window.location.pathname]);

  const [requestId, setRequestId] = useState<number | null>(null);

  const [isSaved, setIsSaved] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isReleased, setIsReleased] = useState(false);
  const [typeTable, setTypeTable] = useState<boolean | null>(null);
  const [rightsRequestEdit, setRightsRequestEdit] = useState(false);
  const [rightsSupplyEdit, setRightsSupplyEdit] = useState(false);
  const [rightsApprovalTabEdit, setRightsApprovalTabEdit] = useState(false);

  useEffect(() => {
    console.log("currentUser", currentUser);
  }, []);

  useEffect(() => {
    // 1) Теперь, помимо userId, передаем entityId = 9
    //    "9" – это значение из таблицы roles_entities для "Запросы аренды"
    dispatch(
      getUserPermissions({
        userId: currentUser,
        entityId: [10, 11, 12, 13, 14],
      })
    )
      .unwrap()
      // .then((data) => {
      //   console.log("Full permissions =>", data);
      // })
      // .catch((err) => console.log(err));
      .then((data) => {
        console.log("Full permissions rentRequests =>", data);
        // Если пришло поле "rentRequestsApprovalTab.status_edit" и оно равно true,
        // устанавливаем approvalTabEditable в true, иначе - false.

        if (data && data["rentRequestsApprovalTab.status_edit"] === true) {
          setRightsApprovalTabEdit(true);
        } else {
          setRightsApprovalTabEdit(false);
        }

        if (data && data["rentRequestsRequest.status_edit"] === true) {
          setRightsRequestEdit(true);
        } else {
          setRightsRequestEdit(false);
        }

        if (data && data["rentRequestsSupply.status_edit"] === true) {
          setRightsSupplyEdit(true);
        } else {
          setRightsSupplyEdit(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (isOpen && initialValue) {
      setTypeTable(initialValue?.type_table ?? null);
      setIsSaved(!!initialValue.id); // Доступность второй вкладки
      setIsApproved(!!initialValue.approved); // Доступность третьей вкладки
    }
  }, [isOpen, initialValue]);

  const requestOneData = useSelector<RootState>(
    (state) => state.rent.requestOneData
  ) as IRequest;

  const { email } = Utils.getUserInfo();
  const [start] = typeof email === "string" ? email.split("@") : ["SM"];
  const user_release_id = start.toUpperCase();

  const file_ids = Form.useWatch("file_ids", {
    form,
    preserve: true,
  });

  const [isTransferredToSupply, setIsTransferredToSupply] = useState<boolean>(
    initialValue?.isTransferredToSupply || false
  );

  // Функция обновления состояния передачи снабжению
  const handleTransferToSupply = async (transferred: boolean) => {
    try {
      // 1) Проверяем все поля формы на валидность
      await form.validateFields();

      // 2) Если ошибок нет — устанавливаем флаг "передано в снабжение"
      setIsTransferredToSupply(transferred);
      form.setFieldsValue({ is_transferred_to_supply: transferred });
    } catch (error) {
      // 3) Если валидация "упала" (ошибки), здесь ничего не делаем
      //    или выводим предупреждение пользователю
      message.error(
        "Не все обязательные поля заполнены. Исправьте ошибки перед передачей снабжению."
      );
    }
  };

  const defaultValues: IRequest = {
    id: undefined,
    agreement_date: "",
    type_request_char: user_release_id || "",
    name_request: "",
    my_company: "",
    my_company_id: undefined,
    company: "",
    company_id: undefined,
    agreement: "",
    agreement_id: undefined,
    location_release: [],
    location_return: [defaultLocationReturn],
    type_container: "",
    condition: "",
    count_ktk: "",
    bet: "",
    currency: "",
    currency_id: undefined,
    count_day: "",
    snp: "",
    signer: "",
    fine: "",
    contractor_inn: "",
    customer_inn: "",
    date_action_start: undefined,
    date_action_end: undefined,
    id_request: "",
    date_request: "",
    request_author: "",
    supply_department: "",
    director: "",
    operational_department: "",
    purchase_mail_theme: "",
    purchase_release_number: "",
    purchase_fine: "",
    purchase_add_info: "",
    purchase_docs: [],
    sublease_mail_theme: "",
    sublease_release_number: "",
    sublease_fine: "",
    sublease_add_info: "",
    sublease_docs: [],
    ktk_mail_theme: "",
    ktk_release_number: "",
    ktk_fine: "",
    ktk_add_info: "",
    ktk_docs: [],
    return_rent_reason: "",
    approved: false,
    responsible_id: null,
    status: undefined,
    responsible: "",
    purchase_type_id: 6,
    ktk_type_id: 5,
    file_ids: [],
    saveFiles: [],
    is_transferred_to_supply: false,
    type_table: null,
    cancel_reason: null,
    delete: false,
    purchase: [
      {
        company: "1",
        from: "",
        quantity: "",
        type_container: "",
        condition: "",
        price: "",
        payment_option: "",
        is_selected: null,
      },
      {
        company: "2",
        from: "",
        quantity: "",
        type_container: "",
        condition: "",
        price: "",
        payment_option: "",
        is_selected: null,
      },
    ],
    sublease: [
      {
        company: "",
        from: "",
        to: "",
        type_container: "",
        quantity: "",
        condition: "",
        bet: "",
        count_day: "",
        snp: "",
        type_table: null,
        is_selected: null,
      },
      {
        company: "",
        from: "",
        to: "",
        type_container: "",
        quantity: "",
        condition: "",
        bet: "",
        count_day: "",
        snp: "",
        type_table: null,
        is_selected: null,
      },
    ],
    ktk: [
      {
        number: "",
        from: "",
        quantity: "",
        type_container: "",
        condition: "",
        remainingCost: "",
        terminal: "",
        is_selected: null,
      },
    ],
  };

  useEffect(() => {
    if (isOpen && initialValue?.id) {
      setIsTransferredToSupply(initialValue.is_transferred_to_supply || false);
      form.setFieldsValue({
        is_transferred_to_supply:
          initialValue.is_transferred_to_supply || false,
      });

      setRequestId(initialValue.id);
      form.setFieldsValue(initialValue);
    }

    if (requestOneData?.id) {
      setRequestId(requestOneData.id);
    }

    if (typeForm === "edit" && isOpen && id) {
      dispatch(findOneRequest(Number(id)));
    }

    if (isOpen && typeForm === EDIT && requestOneData?.id) {
      form.setFieldsValue(requestOneData);
    }

    if (typeForm === CREATE) {
      form.setFieldsValue({
        ...defaultValues,
        type_request_char: user_release_id,
      });
      // console.log("юзер ид:", user_release_id);
    }

    if (isOpen && initialValue) {
      form.setFieldsValue(initialValue);
    }
    if (isOpen && initialValue) {
      const purchase = ensureMinRows(initialValue.purchase);
      const sublease = ensureMinRows(initialValue.sublease);
      const ktk = ensureMinRows(initialValue.ktk);

      const patchedValues = {
        ...initialValue,
        purchase,
        sublease,
        ktk,
      };

      form.setFieldsValue(patchedValues);
      console.log("Установили значения:", patchedValues);
    }
  }, [
    isOpen,
    initialValue,
    typeForm,
    requestOneData,
    requestId,
    id,
    form,
    dispatch,
    // defaultValues,
    user_release_id,
  ]);

  const setApprovalState = (value: boolean) => {
    setIsAgreed(value);
  };

  const toggleApproval = () => {
    const newIsAgreed = !isAgreed;
    setIsAgreed(newIsAgreed);
    form.setFieldsValue({ approved: newIsAgreed });
  };

  const returnToRevision = () => setIsAgreed(false);

  const handleRowSelectionChange = (
    type: string,
    selectedRows: Set<number>
  ) => {
    if (type === "purchase") setSelectedRowsPurchase(selectedRows);
    if (type === "sublease") setSelectedRowsSublease(selectedRows);
    if (type === "ktk") setSelectedRowsKtk(selectedRows);
  };

  useEffect(() => {
    if (isOpen && initialValue) {
      setIsTransferredToSupply(initialValue.isTransferredToSupply || false);
      form.setFieldsValue({
        ...initialValue,
        isTransferredToSupply: initialValue.isTransferredToSupply || false,
      });
      const approvedValue = initialValue.approved || false;
      setIsAgreed(!!approvedValue);
    }
  }, [isOpen, initialValue, form]);

  useEffect(() => {
    if (isOpen && initialValue) {
      // console.log("Открываем запрос с ID:", initialValue.id);

      // Сбрасываем состояния при каждом новом запросе
      setTableName(initialValue?.type_table ? "Субаренда" : "Эвакуация");
      setTypeTable(initialValue?.type_table ?? null);
      setIsTransferredToSupply(initialValue?.is_transferred_to_supply || false);

      form.setFieldsValue({
        type_table: initialValue?.type_table ?? null,
        is_transferred_to_supply:
          initialValue?.is_transferred_to_supply || false,
        ...initialValue,
      });
    }
  }, [isOpen, initialValue]);

  function filterAndMapRows(rows: any[], selectedIndices: Set<number>) {
    return rows
      .map((row, index) => {
        const { is_selected, id_rent_requests, ...rest } = row;

        // Проверяем, заполнен ли этот ряд (хотя бы одно поле пустое).
        // Считаем поле "пустым", если оно === "", null или undefined
        const isEmptyRow = Object.values(rest).every(
          (val) => val === "" || val == null
        );

        if (isEmptyRow) {
          // Если ряд пуст — возвращаем null,
          // чтобы потом выкинуть его через filter()
          return null;
        }

        return {
          ...rest,
          is_selected: selectedIndices.has(index),
          id_rent_requests: id_rent_requests ?? null,
        };
      })
      .filter((row) => row !== null); // Убираем полностью пустые ряды
  }

  const makeRowsWithIsSelected = (
    rows: any[],
    selectedIndices: Set<number>
  ) => {
    return rows.map((row, index) => {
      // Игнорируем поле id_rent_requests, если оно есть
      const { is_selected, id_rent_requests, ...rest } = row;
      rows.filter((_, index) => selectedIndices.has(index));
      return {
        ...rest,
        // если чекбокс выбран => is_selected === true
        // иначе is_selected === false

        is_selected: selectedIndices.has(index) ? true : false,
      };
    });
  };

  const collectData = async () => {
    const allData = await form.validateFields();
    const { purchase, sublease, ktk, cancel_reason, ...otherData } = allData;

    const filteredPurchase = filterAndMapRows(purchase, selectedRowsPurchase);
    const filteredSublease = filterAndMapRows(sublease, selectedRowsSublease);
    const filteredKtk = filterAndMapRows(ktk, selectedRowsKtk);

    return {
      id: requestId,
      type_request_char: user_release_id,
      type_table: typeTable,
      ...otherData,
      date_action_start: otherData.date_action_start
        ? dayjs(otherData.date_action_start).isValid()
          ? dayjs(otherData.date_action_start).format("YYYY-MM-DD")
          : null
        : null,
      date_action_end: otherData.date_action_end
        ? dayjs(otherData.date_action_end).isValid()
          ? dayjs(otherData.date_action_end).format("YYYY-MM-DD")
          : null
        : null,
      date_request: otherData.date_request
        ? dayjs(otherData.date_request).isValid()
          ? dayjs(otherData.date_request).format("YYYY-MM-DD")
          : null
        : null,

      ...otherData,
      purchase: filteredPurchase,
      sublease: filteredSublease,
      ktk: filteredKtk,
      cancel_reason: cancel_reason || [],
      // Если есть cancel_reason, значит delete === true
      delete: Array.isArray(cancel_reason) && cancel_reason.length > 0,
    };
  };

  useEffect(() => {
    if (isOpen && initialValue) {
      const patchedValues = {
        ...initialValue,
        file_ids: initialValue.file_ids || [],
      };

      form.setFieldsValue(patchedValues);
    }
  }, [isOpen, initialValue, form]);

  const saveAllData = async () => {
    try {
      popMessage.loading("Сохранение запроса...");
      const requestDataToSubmit = await collectData();

      if (
        Array.isArray(requestDataToSubmit.cancel_reason) &&
        requestDataToSubmit.cancel_reason.length > 0
      ) {
        requestDataToSubmit.delete = true;
      } else if (
        Array.isArray(requestDataToSubmit.cancel_reason) &&
        requestDataToSubmit.cancel_reason.length <= 0
      ) {
        requestDataToSubmit.delete = false;
      }

      let response;
      console.log("начало отправки данных:", requestDataToSubmit);

      if (typeForm === "create") {
        response = await Rest_rent.setRentRequests(requestDataToSubmit);
      } else if (typeForm === "edit") {
        response = await Rest_rent.updateRentRequests(requestDataToSubmit);
      }

      if (response) {
        // console.log("Отправка данных:", requestDataToSubmit);
        message.success("Данные успешно сохранены!");
        // console.log("htc", response);
        setIsSaved(true); // Делаем вторую вкладку доступной
        dispatch(setOneRequest(response));
        handleClose();
      }
      // popMessage.loading("Сохранение запроса...");
    } catch (error) {
      console.error("Ошибка сохранения данных:", error);
      message.error("Ошибка сохранения данных. Проверьте форму.");
    }
  };

  const handlerSaveFiles = (files: any[]) => {
    form.setFieldsValue({ file_ids: files });
  };

  const ensureMinRows = (rows = []) => {
    const filledRows = rows.filter((row) =>
      Object.values(row).some((val) => val)
    );
    const emptyRowsCount = Math.max(0, 7 - filledRows.length);
    return [...filledRows, ...Array(emptyRowsCount).fill({})];
  };

  useEffect(() => {
    dispatch(setEditRequest(true));
    return () => {
      dispatch(setEditRequest(false));
      form.resetFields();
    };
  }, [dispatch, form]);

  useEffect(() => {
    if (isOpen && initialValue) {
      const purchase = ensureMinRows(initialValue.purchase);
      const sublease = ensureMinRows(initialValue.sublease);
      const ktk = ensureMinRows(initialValue.ktk);

      const patchedValues = {
        ...initialValue,
        purchase,
        sublease,
        ktk,
      };

      form.setFieldsValue(patchedValues);

      const selectedPurchase = new Set<number>();
      purchase.forEach((row, index) => {
        if (row.is_selected === true) {
          selectedPurchase.add(index);
        }
      });
      setSelectedRowsPurchase(selectedPurchase);

      const selectedSublease = new Set<number>();
      sublease.forEach((row, index) => {
        if (row.is_selected === true) {
          selectedSublease.add(index);
        }
      });
      setSelectedRowsSublease(selectedSublease);

      const selectedKtk = new Set<number>();
      ktk.forEach((row, index) => {
        if (row.is_selected === true) {
          selectedKtk.add(index);
        }
      });
      setSelectedRowsKtk(selectedKtk);
    }
  }, [isOpen, initialValue, form]);

  const handleClose = (isClear?: boolean) => {
    if (onClose) onClose(isClear);
    form.resetFields();
    dispatch(setOneRequest({}));
  };

  const changeClickTab = (key: string) => {
    setActiveKey(key);
  };

  const handleTableNameChange = (name: string) => {
    setTableName(name);
  };

  const [loading, setLoading] = useState<boolean>(false);
  const { location, history } = useParamsHistory();
  const [typeFormDeal, setTypeFormDeal] = useState<string>(CREATE);
  const dealOneData = useSelector<RootState>(
    (state) => state.rent.dealOneData
  ) as IDeal;

  const onCloseFormDeal = (res?: boolean) => {
    dispatch(resetStoreRent());
    setOpenDrawerDeal(false);
  };

  const onRelease = () => {
    history.push(RouteNames.RELEASES, { [RELEASE]: {} });
  };

  // const handleShowDrawerDeal = (tableName: string) => {
  const handleShowDrawerDeal = (tableName: string, dealTypeId: number) => {
    setTypeFormDeal(CREATE);
    console.log(
      "location_release",
      initialValue.location_release?.[0]?.location_release || ""
    );
    console.log(
      "location_return",
      initialValue.request_location?.[0]?.location_return || ""
    );
    console.log(
      "my_company",
      initialValue.my_company,
      initialValue.my_company_id
    );

    const newDealData = {
      ...defaultValuesDeal, // Здесь были дефолты сделки
      type_deal_id: dealTypeId, // Определили выше

      // Основные поля
      name_request: initialValue.name_request || "",
      my_company: initialValue.my_company,
      my_company_id: initialValue.my_company_id,
      company: initialValue.company || "",
      company_id: initialValue.company_id || null,
      agreement: initialValue.agreement || "",
      agreement_id: initialValue.agreement_id || null,
      phone: initialValue.phone || "",
      contact_id: initialValue.contact_id || null,

      // Локации (превращаем массив в строку)
      location_release:
        initialValue.location_release?.[0]?.location_release || "",
      location_return: (initialValue.request_location || []).map(
        (loc: any) => ({
          location: loc.location_return || "",
          bet: loc.bet || "",
        })
      ),

      // Контейнер и его характеристики
      type_container: initialValue.type_container || "",
      condition: initialValue.condition || "",

      // Числовые поля
      bet: initialValue.request_location?.[0]?.bet || 0,
      count_ktk: initialValue.count_ktk,
      count_day: initialValue.count_day,
      snp: initialValue.snp,
      fine: initialValue.fine,

      // Прочие поля заявки, которые могут быть нужны
      currency: initialValue.currency || "",
      message_subject: initialValue.message_subject || "",
      additionally: initialValue.additionally || "",
      signer: initialValue.signer || "",

      // Если нужно забрать даты из запроса (убедитесь, что формат корректен)
      date_action_start: initialValue.date_action_start || null,
      date_action_end: initialValue.date_action_end || null,
      date_request: initialValue.date_request || null,

      // Если хотите передавать "purchase", "sublease", "ktk" и т.п.
      // (Только если в FormDeal реально есть такие поля — иначе не будет смысла):
      purchase: initialValue.purchase || [],
      sublease: initialValue.sublease || [],
      ktk: initialValue.ktk || [],

      // Флаг, что сделка создаётся из заявки
      isFromRequest: true,
    };

    dispatch(setOneDeal({ ...newDealData, isFromRequest: true }));
    setOpenDrawerDeal(true);
  };

  useEffect(() => {
    if (isOpen && initialValue) {
      const purchase = ensureMinRows(initialValue.purchase);
      const sublease = ensureMinRows(initialValue.sublease);
      const ktk = ensureMinRows(initialValue.ktk);

      const location_release = initialValue.request_release?.map(
        (rel: any) => ({
          location_release: rel.location_release || "",
          options: rel.options || [],
        })
      ) || [defaultLocationReturn];

      const location_return = initialValue.request_location?.map(
        (loc: any) => ({
          location: loc.location_return || "",
          bet: loc.bet || "",
          options: loc.options || [],
        })
      ) || [defaultLocationReturn];

      const patchedValues = {
        ...initialValue,
        purchase,
        sublease,
        ktk,
        location_release,
        location_return,
      };

      form.setFieldsValue(patchedValues);
    }
  }, [isOpen, initialValue, form]);

  const tabs: ITab[] = useMemo(
    () => [
      {
        key: BASE_TAB,
        label: "Запрос аренды",
        forceRender: true,
        isError: isErrorsBase,
        disabledText: "",
        // disabled: false,
        // Если delete === true, вкладка НЕ дизейблится (т.к. нужно просматривать).
        // Иначе логика прежняя.
        disabled: !isViewMode && false, // или, если нужна была прежняя логика: disabled: !isViewMode && <что-то ещё>
        content: (
          <RequestTab
            form={form}
            activeKey={activeKey}
            initialValue={initialValue}
            dataRowContainer={{}}
            onFinish={saveAllData}
            isAgreed={isAgreed}
            tableName={tableName}
            onRequestDataSubmit={saveAllData}
            onRowSelectionChange={handleRowSelectionChange}
            selectedRows={{
              purchase: selectedRowsPurchase,
              sublease: selectedRowsSublease,
              ktk: selectedRowsKtk,
            }}
            saveAllData={saveAllData}
            isDisabled={isTransferredToSupply}
            isTransferredToSupply={isTransferredToSupply}
            onTransferToSupply={handleTransferToSupply}
            onTypeTableChange={setTypeTable}
            typeTable={typeTable}
            onTableNameChange={setTableName}
            isViewMode={isViewMode}
            rightsRequestEdit={rightsRequestEdit}
            rightsSupplyEdit={rightsSupplyEdit}
          />
        ),
      },
      {
        key: APPROVAL_TAB,
        label: "Согласование",
        forceRender: true,
        isError: false,
        disabled: !isSaved || !isTransferredToSupply,
        content: (
          <ApprovalTab
            form={form}
            initialValue={initialValue}
            data={data}
            isAgreed={isAgreed}
            toggleApproval={toggleApproval}
            setApprovalState={setApprovalState}
            returnToRevision={returnToRevision}
            tableName={tableName}
            isViewMode={isViewMode}
            rightsApprovalTabEdit={rightsApprovalTabEdit}
          />
        ),
      },
      {
        key: SIGNING_TAB,
        label: "Подписание заявки с клиентом",
        forceRender: true,
        isError: false,
        disabled: !isApproved,
        content: (
          <SigningTab
            form={form}
            isOpen={isOpen}
            initialValue={initialValue}
            isViewMode={isViewMode}
          />
        ),
      },
      {
        key: RELEASE_TAB,
        label: "Релиз",
        forceRender: true,
        isError: false,
        disabled: !isApproved,
        content: (
          <ReleaseTab
            form={form}
            activeKey={activeKey}
            initialValue={initialValue}
            tableName={tableName}
            isOpen={isOpen}
            onCreateDeal={handleShowDrawerDeal}
            isViewMode={isViewMode}
          />
        ),
      },
      {
        key: CHAT_TAB,
        label: "Чат",
        forceRender: true,
        isError: false,
        disabled: !isViewMode && !isSaved,
        content: (
          <ChatTab initialValue={initialValue} isViewMode={isViewMode} />
        ),
      },
    ],
    [
      isErrorsBase,
      isViewMode,
      isAgreed,
      tableName,
      selectedRowsPurchase,
      selectedRowsSublease,
      selectedRowsKtk,
      form,
      defaultValues,
      saveAllData,
      initialValue,
      data,
      handleShowDrawerDeal,
      handleTransferToSupply,
      isApproved,
      isOpen,
      isSaved,
      isTransferredToSupply,
      toggleApproval,
    ]
  );

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Form
        key={initialValue?.id || "new"}
        autoComplete="off"
        form={form}
        layout="vertical"
        className={styles.formModalRequest}
        onFinish={saveAllData}
        scrollToFirstError
        initialValues={
          isDisabled ? { ...defaultValues, ...initialValue } : defaultValues
        }
        disabled={isViewMode}
      >
        <div className={styles.formModalRentRequest}>
          <ITabs
            className={styles["tabs-form__tab"]}
            isErrorTabs
            activeKey={activeKey}
            tabs={tabs}
            onChange={changeClickTab}
          />
        </div>

        {/* {activeKey !== CHAT_TAB && ( */}
        {activeKey !== CHAT_TAB && !isViewMode && (
          <Flex
            gap={8}
            style={{ marginTop: 10 }}
            className={styles.buttonContainer}
          >
            <Form.Item name="id" initialValue={requestId} noStyle />
            <Button
              type="primary"
              style={{ width: "57%", height: "35px" }}
              onClick={saveAllData}
            >
              Сохранить
            </Button>
            <Button
              type={"text"}
              onClick={() => setConfirmation(true)}
              style={{ width: "30%", color: "#E14453" }}
            >
              Отменить
            </Button>
          </Flex>
        )}
      </Form>
      <ModalConfirm
        title={warningTitle}
        subtitle={subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={isModalOpen ? handleCancel : onClose}
      />
      <CustomDrawer
        open={openDrawerDeal && !loading}
        onClose={onCloseFormDeal}
        isHeight
        minWidth={1000}
      >
        <FormDeal
          title={typeForm === CREATE ? "Создать сделку" : "Создать сделку"}
          initialValue={dealOneData}
          isOpen={openDrawerDeal}
          onClose={onCloseFormDeal}
          onRelease={onRelease}
          onEdit={() => setTypeFormDeal(CREATE)}
          isFromRequest={typeFormDeal === CREATE}
        />
      </CustomDrawer>
    </>
  );
};

export default FormModalRentRequest;
