import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState, useStoreDispatch } from "../../../../../store";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Divider,
  Button,
  Checkbox,
  SelectProps,
  Flex,
  Tooltip,
  App,
  AutoComplete,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { purchaseColumns, subleaseColumns, ktkColumns } from "./columns";
import { IContainer } from "../../../../../store/releases";
import { getSizeKtk } from "../../../../releases/components/utils";
import { Utils } from "../../../../../utils";
import { mapperCompany } from "../../../deals/utils";
import { findCompany } from "../../../../../store/contractors";
import { findExecutor } from "../../../../../store/contact";
import { ICurrency } from "../../../../../types";
import { SelectSearch } from "../../../../../components/ui/SelectSearch/SelectSearch";
import { ReloadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from "../style.module.scss";
import CancelReasonModal from "./CancelReasonModal";
import EditableTable from "../table/Table";
import { findAgreementsCompany } from "../../../../../store/sales";
import socket from "../../../../../socket";
import { mapperSearchYandexLocation } from "../../../deals/utils";
import { FormList } from "../../../../../components/ui/Form/FormList/FormList";
import { defaultLocationReturn } from "../../../../../store/rent";
import classnames from "classnames";
import dealStyle from "../../../deals/style.module.scss";
import { delay } from "lodash";
import { disabledDateFromStartToEnd } from "../../../../../helpers/date-helpers";
import { FormInputNumber } from "../../../../../components/ui/Form";
import { findContainers } from "../../../../../store/containers";
import { ITerminal } from "../../../../../types/terminal";
import { nameColumnsKtk } from "../../../deals/constants";

interface RequestTabProps {
  form?: any;
  initialValue: any;
  typeForm?: string;
  dataRowContainer?: any;
  onFinish: (requestData: any, action: string) => void;
  isAgreed: boolean;
  onTableNameChange: (name: string) => void;
  tableName: string;
  onRequestDataSubmit: (data: any) => void;
  onRowSelectionChange: (type: string, selectedRows: Set<number>) => void;
  selectedRows: {
    purchase: Set<number>;
    sublease: Set<number>;
    ktk: Set<number>;
  };
  saveAllData?: () => void;
  isDisabled?: boolean;
  isTransferredToSupply: boolean;
  onTransferToSupply: (transferred: boolean) => void;
  onTypeTableChange: (type: boolean | null) => void;
  typeTable: boolean | null;
  isViewMode: boolean;
  activeKey: string;
  rightsRequestEdit: boolean;
  rightsSupplyEdit: boolean;
}

const RequestTab: React.FC<RequestTabProps> = ({
  typeForm,
  initialValue,
  dataRowContainer,
  onFinish,
  isAgreed,
  form,
  onTableNameChange,
  tableName,
  onRequestDataSubmit,
  onRowSelectionChange,
  selectedRows,
  saveAllData,
  isDisabled,
  isTransferredToSupply,
  onTransferToSupply,
  onTypeTableChange,
  typeTable,
  isViewMode,
  activeKey,
  rightsRequestEdit,
  rightsSupplyEdit,
}) => {
  const { t } = useTranslation();

  const dataTypeContainers = useSelector(
    (state: RootState) => state.types.dataTypeContainers
  );
  const containers = Form.useWatch("containers", {
    form,
    preserve: true,
  }) as IContainer[];
  const dataTypeQuality = useSelector(
    (state: RootState) => state.types.dataTypeQuality
  );
  const dataStatusContainer = useSelector(
    (state: RootState) => state.containers.dataStatusContainer
  );
  const dataCurrency = useSelector(
    (state: RootState) => state.types.dataTypeCurrency
  );
  const { message } = App.useApp();

  const dispatch = useStoreDispatch();

  const [isLeftColumnActive, setIsLeftColumnActive] = useState(
    !isTransferredToSupply // Если заявка передана снабжению, левая колонка заблокирована
  );

  const [returnReason, setReturnReason] = useState("");
  const [selectedCompanies, setSelectedCompanies] = useState<string[] | null>(
    null
  );
  const [companyData, setCompanyData] = useState<SelectProps["options"]>([]);
  const [changeCompany, setChangeCompany] = useState<string[]>([]);
  const [city, setCity] = useState<[]>([]);
  const [cityData, setDataCity] = useState<any>([]);
  const [searchCity, setSearchCity] = useState<string | null>(null);
  const [icons, setIcons] = useState<{ [key: string]: JSX.Element }>({});
  const [responsible, setResponsible] = useState(Utils.getUserInfo().fio);
  const [dataExecutor, setDataExecutor] = useState<[]>([]);
  const [responsibleChange, setResponsibleChange] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const reasonList = Form.useWatch("cancel_reason", form) || [];
  const [columnName, setColumnName] = useState<string>("");
  const [dataAgreements, setDataAgreements] = useState<any>([]);
  const my_company_id = Form.useWatch("my_company_id", {
    form,
    preserve: true,
  });

  const my_company: string = Form.useWatch("my_company", {
    form,
    preserve: true,
  });

  const company_id = Form.useWatch("company_id", { form, preserve: true });

  const [selectedCheckbox, setSelectedCheckbox] = useState<
    boolean | string | null
  >(null);

  const [dataCompany, setDataCompany] = useState<
    { id: number; value: string }[]
  >([]);

  const TypeContainer = initialValue?.type_container;

  const containersData = useSelector(
    (state: RootState) => state.containers.resultContainers
  );
  // терминалы: родитель; children.название терминала
  const cityTerminalsData: any[] = []; // нужно получить терминалы не хранятся

  const dataMyCompany = useSelector(
    (state: RootState) => state.companies.dataMyCompany
  ) as { id: number; value: string; inn: string }[];
  const [arHistory, setArHistory] = useState<any[]>([
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
  ]);

  useEffect(() => {
    dispatch(findContainers({}));
  }, [dispatch]);

  const filteredContainers = containersData.filter(
    (c) => c.type_container === TypeContainer
  );

  // подбор ктк для кнопки "подбор"
  const handlePick = () => {
    if (!containersData.length || !cityTerminalsData.length) {
      message.warning("Данные еще не загружены, попробуйте позже");
      return;
    }

    // Массив локаций релиза из initialValue
    const releaseLocations: { location_release: string }[] =
      initialValue?.location_release || [];

    // Фильтруем контейнеры по типу
    const filteredContainers = containersData.filter((container) => {
      if (container.type_container !== TypeContainer) return false;

      // Вычисляем "from" по данным терминалов
      const foundCityTerm = cityTerminalsData.find(
        (ct) => ct.value === container.terminal_id
      );
      const fromLabel = foundCityTerm?.label || "";

      // Если нет ни одной локации релиза, возвращаем true
      if (releaseLocations.length === 0) return true;

      // Если хотя бы один из объектов location_release содержит подстроку fromLabel, контейнер подходит
      return releaseLocations.some((locObj) =>
        locObj.location_release.toLowerCase().includes(fromLabel.toLowerCase())
      );
    });

    const mappedRows = filteredContainers.map((container) => {
      const foundCityTerm = cityTerminalsData.find(
        (ct) => ct.value === container.terminal_id
      );
      const fromLabel = foundCityTerm?.label || "";
      const foundChild = foundCityTerm?.children?.find(
        (terminal: {id: number, label: string, value: number}) => terminal.id === container.terminal_id
      );
      const terminalName = foundChild?.name || "";

      return {
        number: container.num_container || "",
        from: fromLabel,
        quantity: null,
        type: container.type_container || "",
        condition: container.quality || "",
        remainingCost: "",
        terminal: terminalName,
      };
    });

    form.setFieldsValue({
      ktk: mappedRows,
    });
  };

  useEffect(() => {
    if (cityTerminalsData.length) {
      console.log("cityTerminalsData:", cityTerminalsData);
    }
  }, [cityTerminalsData]);

  useEffect(() => {
    if (typeTable !== null) {
      const newTableName = typeTable ? "Субаренда" : "Эвакуация";
      onTableNameChange(newTableName);
    }
  }, [typeTable]);

  useEffect(() => {
    setIsLeftColumnActive(!isTransferredToSupply);
  }, [isTransferredToSupply]);

  const handleOpenCancelReasonModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleColumnActiveClick = async () => {
    try {
      setIsLeftColumnActive(false);
      onTransferToSupply(true);
    } catch (error) {
      console.error("Ошибка валидации:", error);
    }
  };

  // возврат аренде
  const handleReturnClick = async () => {
    try {
      await form.validateFields(["reason"]);
      setIsLeftColumnActive(true);
      onTransferToSupply(false);
    } catch (error) {
      console.error("Ошибка валидации:", error);
    }
  };

  const selectedType = (type?: string) => {
    const result = (containers || [])
      .filter((cont) => cont?.type_container_id === type)
      .map((item) => ({
        ...item,
        size: getSizeKtk(type),
        type_container: type,
        type_container_id: type,
      }));
    form.setFieldsValue({
      containers: result,
    });
  };

  const selectQualityContainer = (val: string) => {
    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) => item["condition"] === val
      );
      if (!isFind) {
        arHistory[2].map((item: any, index: number) => {
          if (index === 0) {
            item.meaning = dataRowContainer.condition;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Состояние"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning = val;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Состояние"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[2].map((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const handleCurrency = (val: string, record: any) => {
    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) => item["currency"] === val
      );
      if (!isFind) {
        arHistory[6].map((item: any, index: number) => {
          if (index === 0) {
            item.meaning = dataRowContainer.currency;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Валюта"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning = val;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Валюта"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[6].map((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const changePerson = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const result: any = await dispatch(findExecutor(event.target.value));
    if (result.payload.length > 0) {
      setDataExecutor(result.payload);
    }
  };

  const handleSearchCompany = (text: string) => {
    if (text?.length >= 2) {
      delay(() => {
        dispatch(findCompany(text.toLocaleLowerCase()))
          .then((res) => {
            setDataCompany(mapperCompany(res.payload));
          })
          .catch(console.error);
      }, 1000);
    }
  };

  const handleSelectCompany = useCallback((id: string, record: any) => {
    const fields = form.getFieldsValue();
    form.setFieldsValue({
      ...fields,
      phone: record?.phone,
      company: record?.value,
      company_id: record?.id,
      contact_id: record?.contact_id,
      customer_inn: record?.inn,
    });
  }, []);

  const handleSelectMyCompany = useCallback((id: string, record: any) => {
    form.setFieldValue("account_num", record?.score);
    form.setFieldValue("my_company", record?.value);
    form.setFieldValue("my_company_id", record?.id);
    form.setFieldValue("company_id", record?.id);
    console.log("recordMyCompany", record);
    const fields = form.getFieldsValue();
    form.setFieldsValue({
      ...fields,
      contractor_inn: record?.inn,
    });
  }, []);

  const onChangeReleaseLocation = (e: number, option: any) => {
    const value = option?.children || option?.label;
    form.setFieldsValue({ location_release: value });
  };

  const onChangeReturnLocation = (e: number, option: any) => {
    const value = option?.children || option?.label;
    form.setFieldsValue({ location_return: value });
  };

  const onChangeRequestAuthor = (e: number, option: {label: string, value: number, avatar: string}) => {
    form.setFieldsValue({ request_author: option?.label ?? '' });
  };

  const onChangeSupplyDepartment = (e: number, option: {label: string, value: number, avatar: string}) => {
    form.setFieldsValue({ supply_department:  option?.label ?? '' });
  };

  const onChangeDirector = (e: number, option: {label: string, value: number, avatar: string}) => {
    form.setFieldsValue({ director: option?.label ?? '' });
  };

  const onChangeOperationalDepartment = (e: number, option: {label: string, value: number, avatar: string}) => {
    form.setFieldsValue({ operational_department: option?.label ?? ''  });
  };

  const handleCheckboxChange = (type: string) => {
    const newTypeTable = type === "Аренда" ? true : false;
    onTypeTableChange(newTypeTable);
    form.setFieldsValue({ type_table: newTypeTable });

    onTableNameChange(type === "Аренда" ? "Субаренда" : "Эвакуация");
  };

  useEffect(() => {
    if (typeTable !== null) {
      const type = typeTable ? "Субаренда" : "Передислокация";
      setSelectedCheckbox(type);
    } else if (typeTable == null) {
      const type = "";
      setSelectedCheckbox(type);
    }
  }, [typeTable]);

  useEffect(() => {
    const transferred = form.getFieldValue("is_transferred_to_supply") || false;
    setIsLeftColumnActive(!transferred);

    if (responsible) {
      form.setFieldsValue({ request_author: responsible });
    }
  }, [responsible, form]);

  const handleRowSelect = (
    type: "purchase" | "sublease" | "ktk",
    index: number,
    selected: boolean
  ) => {
    const updatedSet = new Set(selectedRows[type]);
    if (selected) updatedSet.add(index);
    else updatedSet.delete(index);
    onRowSelectionChange(type, updatedSet);
  };

  const handleSomeButtonClick = () => {
    if (saveAllData) {
      saveAllData();
    }
  };

  useEffect(() => {
    if (initialValue?.sublease) {
      const firstSublease = initialValue.sublease[0] || {};
      const newType = firstSublease.type_table
        ? "Аренда"
        : firstSublease.type_table === false
        ? "Передислокация"
        : null;

      setSelectedCheckbox(newType);
      onTableNameChange(
        newType === "Аренда"
          ? "Субаренда"
          : newType === "Передислокация"
          ? "Эвакуация"
          : ""
      );
    }
  }, [initialValue]);

  const getAgreements = ({
    client,
    company,
  }: {
    client: number;
    company: number;
  }) => {
    if (client && company) {
      const payload = { executor_id: company, client_id: client };
      dispatch(findAgreementsCompany(payload))
        .then((res) => {
          setDataAgreements(res.payload);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          socket.off("find-agreements-company");
        });
    } else {
      form.setFieldsValue({
        agreement: "",
        agreement_id: null,
      });
      setDataAgreements([]);
    }
  };

  const handleSearchBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const client = dataCompany.find(
      (comp: any) => comp?.value === evt.target.value
    );
    if (client) {
      getAgreements({ company: my_company_id, client: client?.id });
      return;
    }
    if (!client) {
      form.setFieldValue("company", "");
      form.setFieldValue("company_id", null);
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValue}
      className={styles.requestTab}
      autoComplete="off"
      style={{
        pointerEvents: isAgreed || isViewMode ? "none" : "auto",
      }}
    >
      {!isAgreed && !isViewMode && (
        <Button
          type="primary"
          className={styles.sendButton}
          onClick={handleSomeButtonClick}
        >
          Отправить на согласование
        </Button>
      )}
      <Form.Item
        name="name_request"
        label="Название"
        rules={[{ required: false }]}
      >
        <Input
          placeholder="Введите название"
          disabled={isAgreed || isViewMode}
        />
      </Form.Item>
      <Row gutter={24}>
        <Col span={9}>
          <Form.Item
            label={t("Наше юридическое лицо")}
            name={"my_company"}
            className={classnames(dealStyle.label, {
              [dealStyle.label__disabled]: !!form.getFieldValue("id"),
            })}
            rules={[{ required: true, message: t("Обязательное поле") }]}
          >
            <AutoComplete
              popupClassName="certain-category-search-dropdown"
              style={{ width: "100%" }}
              allowClear
              placeholder={t("Выберите компанию")}
              options={dataMyCompany}
              onSelect={handleSelectMyCompany}
              // onBlur={handleSearchBlurMyCompany}
              disabled={isTransferredToSupply || isViewMode}
            />
          </Form.Item>
          <Form.Item name="contractor_inn" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="my_company_id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="company_id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="agreement_id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="currency_id" hidden>
            <Input />
          </Form.Item>

          <Form.Item
            label={t("Клиент")}
            name={"company"}
            className={classnames(dealStyle.label, {
              [dealStyle.label__disabled]: !!form.getFieldValue("id"),
            })}
            rules={[{ required: true, message: t("Обязательное поле") }]}
          >
            <AutoComplete
              popupClassName="certain-category-search-dropdown"
              style={{ width: "100%" }}
              allowClear
              placeholder={t("Выберите клиента")}
              options={dataCompany}
              onSelect={handleSelectCompany}
              onSearch={handleSearchCompany}
              onBlur={handleSearchBlur}
              disabled={isTransferredToSupply || isViewMode}
            />
          </Form.Item>

          <Form.Item name="customer_inn" hidden>
            <Input />
          </Form.Item>

          <Form.Item label={t("Договор №")} name={"agreement"}>
            <Select
              options={dataAgreements}
              onSelect={(_, opt) => {
                form.setFieldValue("agreement_id", opt?.id);
                form.setFieldValue("agreement_date", opt?.date_string);
              }}
              disabled={
                !dataAgreements?.length ||
                isTransferredToSupply ||
                isDisabled ||
                isViewMode
              }
            />
          </Form.Item>
          <Form.Item name="agreement_date" hidden>
            <Input />
          </Form.Item>

          <Row
            gutter={16}
            align="middle"
            className={styles.locationRelease}
            style={{
              pointerEvents: isAgreed ? "none" : "auto",
            }}
          >
            <Form.Item name="request_release">
              <FormList
                nameList="location_release" // Массив в форме, где будут храниться все локации
                title="Локация релиза"
                titleName="location_release" // Ключ поля (в каждом элементе массива)
                type="search"
                typeOption={{
                  style: { maxWidth: "135px" },
                  // allowClear: true,
                  showSearch: true,
                  placeholder: "Поиск",
                  suffixIcon: null,
                  filterOption: false,
                  notFoundContent: null,
                  api: Utils.yandexCity,
                  mapResponse: mapperSearchYandexLocation,
                }}
                defaultItem={{
                  release: "", // Начальное значение поля
                }}
                noScroll={false}
                disabled={isTransferredToSupply || isViewMode}
              />
            </Form.Item>
          </Row>

          <Row
            gutter={16}
            align="middle"
            className={styles.locationReturn}
            style={{
              pointerEvents: isAgreed ? "none" : "auto",
            }}
          >
            <Form.Item name="request_location">
              <FormList
                nameList="location_return"
                title="Локация возврата"
                titleName="location"
                type="search"
                typeOption={{
                  style: { maxWidth: "135px" },
                  // allowClear: true,
                  showSearch: true,
                  placeholder: "Поиск",
                  suffixIcon: null,
                  filterOption: false,
                  notFoundContent: null,
                  api: Utils.yandexCity,
                  mapResponse: mapperSearchYandexLocation,
                }}
                labelList=""
                defaultItem={{
                  location: defaultLocationReturn,
                  bet: "",
                }}
                additionalNumericInput={{
                  name: "bet",
                  label: "Ставка",
                  rules: [
                    {
                      required: false,
                      message: "Введите число",
                    },
                  ],
                  typeOption: {
                    placeholder: "Введите ставку",
                  },
                }}
                noScroll={false}
                disabled={isTransferredToSupply || isViewMode}
              />
            </Form.Item>
            <Col span={8} className={styles.checkboxesWrapper}>
              <Checkbox
                checked={typeTable === true}
                onChange={() => handleCheckboxChange("Аренда")}
                disabled={isTransferredToSupply || isViewMode}
              >
                Аренда
              </Checkbox>
              <Checkbox
                checked={typeTable === false}
                onChange={() => handleCheckboxChange("Передислокация")}
                disabled={isTransferredToSupply || isViewMode}
              >
                Передислокация
              </Checkbox>
            </Col>
          </Row>

          <Form.Item
            label={t("Тип КТК")}
            name="type_container"
            style={{
              width: "100%",
              pointerEvents: isAgreed ? "none" : "auto",
            }}
          >
            <Select
              allowClear
              placeholder={t("Выберите тип")}
              style={{ width: "100%" }}
              options={dataTypeContainers}
              onSelect={selectedType}
              disabled={isTransferredToSupply || isViewMode}
            />
          </Form.Item>

          <Form.Item
            name={"condition"}
            label="Состояние"
            style={{
              pointerEvents: isAgreed ? "none" : "auto",
            }}
          >
            <Select
              allowClear
              options={dataTypeQuality}
              onSelect={selectQualityContainer}
              placeholder={"Состояние"}
              style={{ width: "100%" }}
              disabled={isTransferredToSupply || isViewMode}
            />
          </Form.Item>

          <Form.Item
            name="count_ktk"
            label="Количество КТК"
            style={{
              pointerEvents: isAgreed ? "none" : "auto",
            }}
          >
            <FormInputNumber
              // min={0}
              allowClear
              placeholder="Введите количество КТК"
              className="FormInputNumber"
              disabled={isTransferredToSupply || isViewMode}
            />
          </Form.Item>
          <Form.Item
            name={"currency"}
            label="Валюта"
            style={{
              pointerEvents: isAgreed ? "none" : "auto",
            }}
          >
            <Select
              style={{ width: "100%" }}
              onChange={handleCurrency}
              placeholder={"Валюта"}
              allowClear
              disabled={isTransferredToSupply || isViewMode}
            >
              {dataCurrency?.map((item: ICurrency) => (
                <Select.Option key={item.id} value={item.symbol}>
                  <Flex align={"center"} gap={5}>
                    {icons[item.id]}
                    {item.symbol} {item.value}
                  </Flex>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t("Кол-во дней")}
            rules={[{ required: true, message: t("Обязательное поле") }]}
            name={"count_day"}
            style={{
              pointerEvents: isAgreed ? "none" : "auto",
            }}
          >
            <FormInputNumber
              min={0}
              allowClear
              placeholder="Введите количество дней"
              className="FormInputNumber"
              disabled={isTransferredToSupply || isViewMode}
            />
          </Form.Item>

          <Form.Item
            label={t("СНП")}
            name={"snp"}
            style={{
              pointerEvents: isAgreed ? "none" : "auto",
            }}
          >
            <FormInputNumber
              min={0}
              allowClear
              placeholder="Введите снп"
              className="FormInputNumber"
              disabled={isTransferredToSupply || isViewMode}
            />
          </Form.Item>

          <Form.Item
            name="request_author"
            label="Автор заявки"
            style={{
              pointerEvents: isAgreed ? "none" : "auto",
            }}
          >
            <SelectSearch
              defaults={responsible}
              notFoundContent="Пользователь не найден."
              dataSource={dataExecutor}
              onChangeSelect={onChangeRequestAuthor}
              dropdownRender={changePerson}
              style={{ width: "100%" }}
              placeholder="Ответственный"
              disabled={isTransferredToSupply || isViewMode}
            />
          </Form.Item>

          <Form.Item
            name="supply_department"
            label="Сотрудник отдела снабжения"
            style={{
              pointerEvents: isAgreed ? "none" : "auto",
            }}
          >
            <SelectSearch
              defaults={
                responsibleChange || form.getFieldValue("supply_department")
              }
              notFoundContent={"Пользователь не найден."}
              dataSource={dataExecutor}
              onChangeSelect={onChangeSupplyDepartment}
              dropdownRender={changePerson}
              style={{ width: "100%" }}
              placeholder={""}
              disabled={isTransferredToSupply || isViewMode}
            />
          </Form.Item>

          <Form.Item
            name="director"
            label="Директор для согласования"
            style={{
              pointerEvents: isAgreed ? "none" : "auto",
            }}
          >
            <SelectSearch
              defaults={responsibleChange || form.getFieldValue("director")}
              notFoundContent={"Пользователь не найден."}
              dataSource={dataExecutor}
              onChangeSelect={onChangeDirector}
              dropdownRender={changePerson}
              style={{ width: "100%" }}
              placeholder={""}
              disabled={isTransferredToSupply || isViewMode}
            />
          </Form.Item>

          <Form.Item
            name="operational_department"
            label="Сотрудник оперативного отдела"
            style={{
              pointerEvents: isAgreed ? "none" : "auto",
            }}
          >
            <SelectSearch
              defaults={
                responsibleChange ||
                form.getFieldValue("operational_department")
              }
              notFoundContent={"Пользователь не найден."}
              dataSource={dataExecutor}
              onChangeSelect={onChangeOperationalDepartment}
              dropdownRender={changePerson}
              style={{ width: "100%" }}
              placeholder={""}
              disabled={isTransferredToSupply || isViewMode}
            />
          </Form.Item>

          <Row justify="space-between">
            <Col style={{ display: "flex" }}>
              <Form.Item name="is_transferred_to_supply" hidden>
                <Input />
              </Form.Item>
              <Button
                type="default"
                onClick={handleColumnActiveClick}
                style={{ height: "36px" }}
                disabled={isTransferredToSupply || isViewMode}
              >
                Передать снабжению
              </Button>
              <Button
                type="link"
                danger
                onClick={handleOpenCancelReasonModal}
                style={{ color: isAgreed || isViewMode ? "#8a8a8a" : "" }}
              >
                Отменить запрос
              </Button>
              {/* Если reasons есть, показываем иконку с tooltip */}
              {reasonList.length > 0 && (
                <Tooltip
                  title={
                    <div>
                      {reasonList.map((reason: any, idx: any) => (
                        <div key={idx}>{reason}</div>
                      ))}
                    </div>
                  }
                >
                  <QuestionCircleOutlined
                    style={{ marginLeft: 8, color: "#1890ff" }}
                  />
                </Tooltip>
              )}

              {/* Само модальное окно. Передаём в него:
                1) reasonList для отмеченных причин
                2) onUpdateReasons, чтобы сразу менять поле формы */}
              <CancelReasonModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                reasonList={reasonList}
                onUpdateReasons={(newList) =>
                  form.setFieldValue("cancel_reason", newList)
                }
              />
              <Form.Item name="cancel_reason" hidden>
                <Input type="hidden" />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col
          span={15}
          style={{
            pointerEvents:
              isAgreed || !isTransferredToSupply || isViewMode
                ? "none"
                : "auto",
            display: "flex",
            flexDirection: "column",
            opacity: isAgreed || isViewMode ? 0.6 : 1,
          }}
        >
          <Form.Item
            name="purchase_type_id"
            style={{ minHeight: "10px", margin: 0, alignItems: "flex-end" }}
          >
            <Divider className={styles.RequestTablesDivider}>Закуп</Divider>
          </Form.Item>
          <Form.List name="purchase" initialValue={initialValue}>
            {(fields, operation) => (
              <EditableTable
                fields={fields}
                columns={purchaseColumns}
                type="purchase"
                rowCount={fields.length}
                onAddRow={() => operation.add({})}
                columnWidths="15% 11% 9% 13% 15% 17% 20%"
                selectedRows={selectedRows.purchase}
                onRowSelect={(index, selected) =>
                  handleRowSelect("purchase", index, selected)
                }
              />
            )}
          </Form.List>

          <Divider className={styles.RequestTablesDivider}>{tableName}</Divider>
          <Form.List
            name="sublease"
            initialValue={
              initialValue?.sublease?.length
                ? [
                    ...initialValue.sublease,
                    ...Array(
                      Math.max(0, 7 - initialValue.sublease.length)
                    ).fill({}),
                  ]
                : Array(7).fill({})
            }
          >
            {(fields, operation) => (
              <EditableTable
                fields={fields}
                type="sublease"
                columns={subleaseColumns}
                rowCount={fields.length}
                onAddRow={() => operation.add({})}
                columnWidths="15% 11% 8% 13% 11% 16% 11% 7% 8%"
                selectedRows={selectedRows.sublease}
                onRowSelect={(index, selected) =>
                  handleRowSelect("sublease", index, selected)
                }
              />
            )}
          </Form.List>

          <Form.Item
            name="ktk_type_id"
            style={{ minHeight: "10px", margin: 0, alignItems: "flex-end" }}
          >
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Divider
                className={styles.RequestTablesDivider}
                style={{
                  width: "fit-content",
                  maxWidth: "fit-content",
                  minWidth: "fit-content",
                }}
              >
                КТК МК
              </Divider>
              <Button type="link" onClick={handlePick}>
                Подбор
              </Button>
            </div>
          </Form.Item>
          <Form.List
            name="ktk"
            initialValue={
              initialValue?.ktk?.length
                ? [
                    ...initialValue.ktk,
                    ...Array(Math.max(0, 7 - initialValue.ktk.length)).fill({}),
                  ]
                : Array(7).fill({})
            }
          >
            {(fields, operation) => (
              <EditableTable
                fields={fields}
                type="ktk"
                columns={ktkColumns}
                rowCount={fields.length}
                onAddRow={() => operation.add({})}
                columnWidths="14% 11% 11% 13% 15% 19% 17%"
                selectedRows={selectedRows.ktk}
                onRowSelect={(index, selected) =>
                  handleRowSelect("ktk", index, selected)
                }
              />
            )}
          </Form.List>

          <div className={styles.Return}>
            <div className="returnContainer">
              <p>Вернуть аренде</p>
              <Button
                className="reset"
                icon={<ReloadOutlined />}
                style={{ marginRight: "16px" }}
                onClick={handleReturnClick}
              ></Button>
            </div>
            <Form.Item name="return_rent_reason" label="Причина">
              <Input.TextArea
                placeholder="Введите причину"
                onChange={(e) => setReturnReason(e.target.value)}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default RequestTab;
