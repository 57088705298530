import React, { useEffect, useState } from 'react';

import get from 'lodash.get';
import { Table, TableProps } from 'antd';

import { ColumnType } from 'antd/es/table';

import { getColumns } from './columns';
import style from './style.module.scss';
import { IProfile } from '../../../../types/users';
import useModalMessage from '../../../../hooks/useModalMessage';
import { getDateString } from '../../../../helpers/date-helpers';
import { Rest_filters } from '../../../../services/rest_filters';
import {
  AGREEMENT,
  COMPANY,
  CONTACT,
  CONTAINER,
  RELEASE,
  RENT_DEAL,
  RENT_REQUEST,
  SALES_DEAL,
  TERMINAL,
} from '../../../../constants';

interface ITask {
  initialValue?: IProfile;
  width?: number;
  table: string;
  activeTab: string;
}

interface IItemsData {
  data: { [key: string]: (string | number)[] }[];
  current: number;
  pageSize: number;
  order?: string;
  table: string;
  responsible: string;
  keysData: IKeysData;
  options?: { [key: string]: any };
}
interface IFethData extends IItemsData {
  index: number;
}

interface IKeysData {
  name: string;
  lastActive: string;
  timeline: string;
}

const format = 'DD.MM.YYYY HH:mm';

const keysContact: IKeysData = {
  name: 'name',
  lastActive: 'updatedAt',
  timeline: '',
};
const keysCompany: IKeysData = {
  name: 'name_company',
  lastActive: 'updatedAt',
  timeline: '',
};
const keysContainer: IKeysData = {
  name: 'num_container',
  lastActive: 'updatedAt',
  timeline: '',
};
const keysAgreement: IKeysData = {
  name: 'agreement_id',
  lastActive: 'updatedAt',
  timeline: 'date_signing',
};
const keysRelease: IKeysData = {
  name: 'release_id',
  lastActive: 'createdAt',
  timeline: 'date_action_end',
};
const keysSalesDeal: IKeysData = {
  name: 'id_deal',
  lastActive: 'updatedAt',
  timeline: '',
};
const keysRentDeal: IKeysData = {
  name: 'id_deal',
  lastActive: 'updatedAt',
  timeline: '',
};
const keysRentRequest: IKeysData = {
  name: 'name_request',
  lastActive: 'updatedAt',
  timeline: 'date_action_end',
};
const keysTerminal: IKeysData = {
  name: 'name',
  lastActive: 'updatedAt',
  timeline: '',
};

const mapperData = ({
  data,
  keysData,
  responsible,
  index,
}: {
  data: any[];
  index: number;
  responsible: string;
  keysData: IKeysData;
}) => {
  return data?.length
    ? data.map((el, i) => {
        const name = get(el, keysData.name, '');
        const lastActive = getDateString({
          date: el?.[keysData.lastActive],
          format,
        });
        const timeline = getDateString({
          date: el?.[keysData.timeline],
        });
        return {
          key: `${i}-${i}`,
          index: `${i + 1 + index}`,
          name,
          lastActive,
          timeline,
          responsible,
        };
      })
    : [];
};

const pageSize = 10;

export const ProfileTask: React.FC<ITask> = ({
  initialValue,
  width,
  table,
  activeTab,
}) => {
  const userId = initialValue?.id;
  const customMessage = useModalMessage(true);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(1);
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState<any[]>([]);

  const getTask = ({
    data,
    current,
    pageSize,
    table,
    keysData,
    responsible,
    order = 'DESC',
  }: IFethData) => {
    setLoading(true);
    Rest_filters.fetchFilters({
      order,
      data,
      current: current >= 2 ? current : 0,
      pageSize,
      table,
    })
      .then((res) => {
        const response = res.data;
        const rows = mapperData({
          data: response?.rows,
          keysData,
          responsible,
          index: current <= 1 ? 0 : (current - 1) * pageSize,
        });
        setTask(rows);
        setTotal(response?.count ?? 0);
      })
      .catch((err) => {
        if (err?.message) {
          customMessage.error(err.message);
          return;
        }
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const items: { [key: string]: any } | null = initialValue?.id
    ? {
        [CONTACT]: {
          data: [{ 'users.fio': [initialValue.fio] }],
          current,
          pageSize,
          table: CONTACT,
          responsible: initialValue.fio,
          keysData: keysContact,
        },
        [COMPANY]: {
          data: [{ 'users.fio': [initialValue.fio] }],
          current,
          pageSize,
          table: COMPANY,
          responsible: initialValue.fio,
          keysData: keysCompany,
        },
        [CONTAINER]: {
          data: [{ responsible_id: [initialValue.id] }],
          current,
          pageSize,
          table: CONTAINER,
          responsible: initialValue.fio,
          keysData: keysContainer,
        },
        [TERMINAL]: {
          data: [{ responsible_id: [initialValue.id] }], // нет
          current,
          pageSize,
          table: TERMINAL,
          responsible: initialValue.fio,
          keysData: keysTerminal,
        },
        [AGREEMENT]: {
          data: [{ responsible_id: [initialValue.id] }],
          current,
          pageSize,
          table: AGREEMENT,
          responsible: initialValue.fio,
          keysData: keysAgreement,
        },
        [RELEASE]: {
          data: [{ responsible_id: [initialValue.id] }],
          current,
          pageSize,
          table: RELEASE,
          responsible: initialValue.fio,
          keysData: keysRelease,
        },
        [SALES_DEAL]: {
          data: [{ responsible_name: [initialValue.fio] }],
          current,
          pageSize,
          table: SALES_DEAL,
          responsible: initialValue.fio,
          keysData: keysSalesDeal,
        },
        [RENT_DEAL]: {
          data: [{ 'users.fio': [initialValue.fio] }],
          current,
          pageSize,
          table: RENT_DEAL,
          responsible: initialValue.fio,
          keysData: keysRentDeal,
        },
        [RENT_REQUEST]: {
          data: [{ request_author: [initialValue.fio] }],
          current,
          pageSize,
          table: RENT_REQUEST,
          responsible: initialValue.fio,
          keysData: keysRentRequest,
        },
      }
    : null;

  useEffect(() => {
    if (table !== activeTab) return;

    if (userId && items?.[table] && !loading) {
      setLoading(true);
      getTask({ ...items[table] });
    }
  }, [activeTab, table, userId]);

  const columns: ColumnType[] = getColumns({ table });

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    if (pagination.pageSize !== pageSize || !userId) {
      setTask([]);
      return;
    }

    const sortOrder = Array.isArray(sorter) ? 'descend' : sorter.order;

    const order = sortOrder === 'ascend' ? 'ASC' : 'DESC';

    if (items?.[table]) {
      setCurrent(pagination.current ? pagination.current : 1);
      getTask({
        ...items[table],
        order,
        pageSize: pagination.pageSize,
        current: pagination.current ? pagination.current : 1,
      });
    }
  };
  return (
    <div
      style={{
        maxWidth: width ? width - 50 + 'px' : '1150px',
        padding: '0 10px',
      }}
    >
      <Table
        className={style['table']}
        dataSource={task}
        scroll={task?.length > 20 ? { x: 1120, y: 55 * 13 } : { x: 1100 }}
        tableLayout="fixed"
        loading={loading}
        columns={columns}
        pagination={{ pageSize, total }}
        onChange={handleTableChange}
      />
    </div>
  );
};
