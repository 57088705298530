import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  IAddComment,
  IContainers,
  IContainersPhoto,
  IContainersStatus,
} from '../types/containers';
import { Rest_containers } from '../services/rest_containers';

interface IContainersState {
  resultContainers: Array<IContainers>;
  resultContainersPhoto: Array<IContainersPhoto>;
  dataStatusContainer: Array<IContainersStatus>;
  totalData: number;
  dataComment: any[];
  dataHistory: any[];
  dataCompany: any[];
  dataStock: any[];
}

const initialState: IContainersState = {
  resultContainers: [],
  resultContainersPhoto: [],
  dataStatusContainer: [],
  totalData: 0,
  dataComment: [],
  dataHistory: [],
  dataCompany: [],
  dataStock: [],
};

export interface IAllUpdate {
  id: number; // айди контейнера
  status_id: number | null; // Текущий статус ктк
  num_container?: string; //  num_container
  terminalStock?: any; //  данные стока
  terminal?: string; //  имя
  stock?: string; //  Сток
  stock_id?: number | null; //  Сток
  booking_before?: null | Date | string; //  Бронь До
  repair?: boolean; //   "В ремонте"
  owner_id?: null | number; // Собственник
  owner?: null | string; // Собственник
  quality?: null | number; // Состояние
  currency_price?: null | string;
}

export const updateSelectedKtk = createAsyncThunk(
  'updateSelectedKtk',
  async (payload: IAllUpdate[]) => {
    return await Rest_containers.updateSelectedKtk(payload);
  },
);

export const updateDateBooking = createAsyncThunk(
  'updateDateBooking',
  async (payload: any) => {
    return await Rest_containers.updateDateBooking(payload);
  },
);

export const validCompanyContainer = createAsyncThunk(
  'validCompanyContainer',
  async (payload: {}) => {
    return await Rest_containers.validCompanyContainer(payload);
  },
);
export const setContainer = createAsyncThunk(
  'setContainer',
  async (payload: {}) => {
    return await Rest_containers.setContainer(payload);
  },
);
export const setContainerImport = createAsyncThunk(
  'setContainerImport',
  async (payload: {}) => {
    return await Rest_containers.setContainerImport(payload);
  },
);
export const findContainers = createAsyncThunk(
  'findContainers',
  async (payload: string | any) => {
    return await Rest_containers.findContainers(payload);
  },
);
export const findPhotoContainers = createAsyncThunk(
  'findPhotoContainers',
  async (payload: number) => {
    return await Rest_containers.findContainersPhoto(payload);
  },
);

export const loadPhotoContainers = createAsyncThunk(
  'loadPhotoContainers',
  async (payload: any) => {
    return await Rest_containers.loadPhotoContainers(payload);
  },
);
export const getStatusContainer = createAsyncThunk(
  'getStatusContainer',
  async () => {
    return await Rest_containers.getStatusContainer();
  },
);
export const updateNewBooking = createAsyncThunk(
  'updateNewBooking',
  async (payload: IContainersStatus) => {
    return await Rest_containers.updateNewBooking(payload);
  },
);
export const addCommentContainer = createAsyncThunk(
  'addCommentContainer',
  async (payload: IAddComment) => {
    return await Rest_containers.addCommentContainer(payload);
  },
);
export const deleteCommentContainer = createAsyncThunk(
  'deleteCommentContainer',
  async (payload: IAddComment) => {
    return await Rest_containers.deleteCommentContainer(payload);
  },
);
export const getCommentContainer = createAsyncThunk(
  'getCommentContainer',
  async (id: number) => {
    return await Rest_containers.getCommentContainer(id);
  },
);
export const getActivityContainer = createAsyncThunk(
  'getActivityContainer',
  async (payload: {}) => {
    return await Rest_containers.getActivityContainer(payload);
  },
);
export const updateRepair = createAsyncThunk(
  'updateRepair',
  async (payload: {}) => {
    return await Rest_containers.updateRepair(payload);
  },
);
export const searchContainer = createAsyncThunk(
  'searchContainer',
  async (payload: {}) => {
    return await Rest_containers.searchContainer(payload);
  },
);

export const getFiltersContainers = createAsyncThunk(
  'getFiltersContainers',
  async (payload: {}) => {
    return await Rest_containers.getFiltersContainers(payload);
  },
);
export const getHistoryContainer = createAsyncThunk(
  'getHistoryContainer',
  async (id: number) => {
    return await Rest_containers.getHistoryContainer(id);
  },
);
export const getEdi = createAsyncThunk('getEdi', async () => {
  return await Rest_containers.getEdi();
});
export const getResources = createAsyncThunk('getResources', async () => {
  return await Rest_containers.getResources();
});

export const deleteIds = createAsyncThunk('deleteIds', async (payload: any) => {
  return await Rest_containers.deleteIds(payload);
});

const containersSlice = createSlice({
  name: 'container',
  initialState,
  reducers: {
    resetStoreContainer(state) {
      state.resultContainers = initialState.resultContainers;
      state.resultContainersPhoto = initialState.resultContainersPhoto;
      state.dataStatusContainer = initialState.dataStatusContainer;
      state.totalData = initialState.totalData;
      state.dataComment = initialState.dataComment;
      state.dataHistory = initialState.dataHistory;
      state.dataCompany = initialState.dataCompany;
      state.dataStock = initialState.dataStock;
    },
    setStatusContainer(state, action) {
      state.dataStatusContainer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setContainer.fulfilled, (state, action) => {
      state.resultContainers = action.payload;
    });
    builder.addCase(findContainers.fulfilled, (state, action) => {
      state.resultContainers = action.payload;
    });
    builder.addCase(findPhotoContainers.fulfilled, (state, action) => {
      state.resultContainersPhoto = action.payload;
    });
    builder.addCase(getStatusContainer.fulfilled, (state, action) => {
      state.dataStatusContainer = action.payload;
    });
    builder.addCase(getCommentContainer.fulfilled, (state, action) => {
      state.dataComment = action.payload;
    });
    builder.addCase(getHistoryContainer.fulfilled, (state, action) => {
      state.dataHistory = action.payload;
    });
  },
});
export default containersSlice.reducer;
export const { setStatusContainer, resetStoreContainer } = containersSlice.actions;
